export const videoArray = [
  {
    seen: true,
    titulo: 'Aula 3 Violação de dados',
    videoUrl:
      'https://www.youtube.com/embed/DingOhq03eA?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'DingOhq03eA',
    id: 'video0',
  },
  {
    seen: false,
    titulo: 'LGPD no dia a dia do contador',
    videoUrl:
      'https://www.youtube.com/embed/8n17jenbGCs?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: '8n17jenbGCs',
    id: 'video1',
  },
  {
    seen: false,
    titulo: 'Como adequar a serventia notarial à LGPD',
    videoUrl:
      'https://www.youtube.com/embed/kjMEQWn7f8Q?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'kjMEQWn7f8Q',
    id: 'video2',
  },
  {
    seen: false,
    titulo: 'Como responder aos direitos dos titulares',
    videoUrl:
      'https://www.youtube.com/embed/jELhtVppO0g?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'jELhtVppO0g',
    id: 'video3',
  },

  {
    seen: false,
    titulo: 'Quais cuidados devem ser tomados ao coletar dados pessoais',
    videoUrl:
      'https://www.youtube.com/embed/yX7Sp-54_BU?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'yX7Sp-54_BU',
    id: 'video4',
  },
  {
    seen: false,
    titulo: 'Relatório de impacto cartório',
    videoUrl:
      'https://www.youtube.com/embed/A6j-HIuDO6M?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'A6j-HIuDO6M',
    id: 'video5',
  },
  {
    seen: false,
    titulo: 'Do mapeamento de dados',
    videoUrl:
      'https://www.youtube.com/embed/fBBUo4nYuso?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'fBBUo4nYuso',
    id: 'video6',
  },
  {
    seen: false,
    titulo: 'Da governança do tratamento de dados pessoais',
    videoUrl:
      'https://www.youtube.com/embed/hkoZnkinHpI?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'hkoZnkinHpI',
    id: 'video7',
  },
  {
    seen: false,
    titulo: 'Gestão de Incidentes de Segurança da Informação',
    videoUrl:
      'https://www.youtube.com/embed/hHvDFRso3VY?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'hHvDFRso3VY',
    id: 'video8',
  },
  {
    seen: false,
    titulo: 'Tabela de Temporalidade e Descarte',
    videoUrl:
      'https://www.youtube.com/embed/3j19OvFz0UE?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: '3j19OvFz0UE',
    id: 'video9',
  },
  {
    seen: false,
    titulo: 'Bases legais',
    videoUrl:
      'https://www.youtube.com/embed/VbooIoYcjLA?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'VbooIoYcjLA',
    id: 'video10',
  },
  {
    seen: false,
    titulo: 'Gestão de risco',
    videoUrl:
      'https://www.youtube.com/embed/iar6eHTNhug?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'iar6eHTNhug',
    id: 'video11',
  },
  {
    seen: false,
    titulo: 'ANPD',
    videoUrl:
      'https://www.youtube.com/embed/W_ILhee5pfM?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'W_ILhee5pfM',
    id: 'video12',
  },
  {
    seen: false,
    titulo: 'Ameaça e risco',
    videoUrl:
      'https://www.youtube.com/embed/9nkvhObZxaA?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: '9nkvhObZxaA',
    id: 'video13',
  },
  {
    seen: false,
    titulo: 'Aspectos da confiabilidade',
    videoUrl:
      'https://www.youtube.com/embed/SdoLJAP2cvo?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'SdoLJAP2cvo',
    id: 'video14',
  },
  {
    seen: false,
    titulo: 'Estrutura da LGPD e alguns conceitos na prática',
    videoUrl:
      'https://www.youtube.com/embed/rft-ewLsvZY?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'rft-ewLsvZY',
    id: 'video15',
  },
  {
    seen: false,
    titulo: 'Incidente de segurança',
    videoUrl:
      'https://www.youtube.com/embed/tkrgwRYuZQw?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'tkrgwRYuZQw',
    id: 'video16',
  },
  {
    seen: false,
    titulo: 'Valor dos dados para as empresas',
    videoUrl:
      'https://www.youtube.com/embed/08RwJVTMUaM?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: '08RwJVTMUaM',
    id: 'video17',
  },
  {
    seen: false,
    titulo: 'Mapeamento de dados',
    videoUrl:
      'https://www.youtube.com/embed/9bnP6hvuOKo?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: '9bnP6hvuOKo',
    id: 'video18',
  },
  {
    seen: false,
    titulo: 'Principais conceitos LGPD',
    videoUrl:
      'https://www.youtube.com/embed/-UjJegxxmVI?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: '-UjJegxxmVI',
    id: 'video19',
  },
  {
    seen: false,
    titulo: 'Conscientização LGPD',
    videoUrl:
      'https://www.youtube.com/embed/WXwzFWZXofM?list=PLQIMuXhVr0MhdP048UWFQkz98hR2bX_vf',
    description: '',
    videoId: 'WXwzFWZXofM',
    id: 'video20',
  },
];
