import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import { auth } from "../Firebase/firebase";

import Login from "./Login";
import Navbar from "../Componentes/Navbar/Navbar";

const Home = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(getAuth()?.currentUser?.email);

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {});
  };

  return (
    <div>
      {/* <Navbar /> */}
      {/* <Login /> */}
      Home
      <button onClick={() => navigate("/login")}>Login</button>
      <button onClick={logout}>Logout</button>
    </div>
  );
};

export default Home;
