export const PerguntasAditivoDeContratoControlador = [
  {
    id: 0,
    codigo: 'ADCTR0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta:
      'Qual o tipo de contrato que irá adicionar o aditivo? Exemplo: contrato de prestação de serviço.',
  },
  {
    id: 1,
    codigo: 'ADCTR1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta: 'Nome da sua empresa (Controladora de dados):',
  },
  {
    id: 2,
    codigo: 'ADCTR2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta: 'CNPJ da sua empresa (Controladora de dados):',
  },
  {
    id: 3,
    codigo: 'ADCTR3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta: 'Cargo de quem vai assinar este aditivo: (Exemplo: sócio).',
  },
  {
    id: 4,
    codigo: 'ADCTR4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta: 'Nome de quem vai assinar pela empresa controladora (a sua):',
  },
  {
    id: 5,
    codigo: 'ADCTR5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta: 'CPF de quem vai assinar pela sua empresa:',
  },
  {
    id: 6,
    codigo: 'ADCTR6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta: 'Nome da empresa operadora:',
  },
  {
    id: 7,
    codigo: 'ADCTR7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta: 'CNPJ da empresa operadora:',
  },
  {
    id: 8,
    codigo: 'ADCTR8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta: 'Cargo de quem vai representar a empresa operadora:',
  },
  {
    id: 9,
    codigo: 'ADCTR9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta: 'Nome de quem vai representar a empresa operadora:',
  },
  {
    id: 10,
    codigo: 'ADCTR10',
    name: 'pergunta10',
    value: 'pergunta10',
    pergunta: 'CPF de quem vai representar a empresa operadora:',
  },
  {
    id: 11,
    codigo: 'ADCTR11',
    name: 'pergunta11',
    value: 'pergunta11',
    pergunta: 'Cidade onde o contrato vai ser assinado:',
  },
  {
    id: 12,
    codigo: 'ADCTR12',
    name: 'pergunta12',
    value: 'pergunta12',
    pergunta: 'Data da assinatura do contrato:',
  },
];
