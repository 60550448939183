import React from "react";
import perigo from "../imgs/perigo.png";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 8px 0;
  background-color: #150442;
  border-radius: 20px;
`;
const Imagem = styled.img`
  width: 50px;
`;
const Text = styled.span`
  color: white;
  margin-left: 10px;
`;
const Perigo = (props) => {
  return (
    <Container>
      <Imagem src={perigo} alt="" />
      <Text>{props.text}</Text>
    </Container>
  );
};

export default Perigo;
