import React, { useState } from "react";
import { Button } from "./Button";
import logo from "./BRANCO 2.png";
import { Link, Navigate } from "react-router-dom";
import "./Navbar.css";
import Dropdown from "./Dropdown";
import Dropdown2 from "./Dropdown2";
import Dropdown3 from "./Dropdown3";
import { signOut } from "firebase/auth";
import { auth } from "../../Firebase/firebase";

function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = (dropdownId) => {
    if (window.innerWidth < 960) {
      if (dropdownId === "Segurança da informação") {
        setDropdown(false);
      } else if (dropdownId === "Jurídico") {
        setDropdown2(false);
      } else if (dropdownId === "Adequação do site") {
        setDropdown3(false);
      }
    } else {
      if (dropdownId === "Segurança da informação") {
        setDropdown(true);
      } else if (dropdownId === "Jurídico") {
        setDropdown2(true);
      } else if (dropdownId === "Adequação do site") {
        setDropdown3(true);
      }
    }
  };

  const onMouseLeave = (dropdownId) => {
    if (window.innerWidth < 960) {
      if (dropdownId === "Segurança da informação") {
        setDropdown(false);
      } else if (dropdownId === "Jurídico") {
        setDropdown2(false);
      } else if (dropdownId === "Adequação do site") {
        setDropdown3(false);
      }
    } else {
      if (dropdownId === "Segurança da informação") {
        setDropdown(false);
      } else if (dropdownId === "Jurídico") {
        setDropdown2(false);
      } else if (dropdownId === "Adequação do site") {
        setDropdown3(false);
      }
    }
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        Navigate("/");
      })
      .catch((error) => {});
  };

  return (
    <>
      <nav className="navbar">
        <Link to="/painel" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="" />{" "}
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/painel" className="nav-links" onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li
            className="nav-item"
            onMouseEnter={() => {
              onMouseEnter("Segurança da informação");
            }}
            onMouseLeave={() => {
              onMouseLeave("Segurança da informação");
            }}
          >
            <Link
              // to="/services"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              Segurança da informação <i className="fas fa-caret-down" />
            </Link>
            {dropdown && <Dropdown />}
          </li>

          <li
            className="nav-item"
            onMouseEnter={() => {
              onMouseEnter("Jurídico");
            }}
            onMouseLeave={() => {
              onMouseLeave("Jurídico");
            }}
          >
            <Link
              // to="/services"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              Jurídico <i className="fas fa-caret-down" />
            </Link>
            {dropdown2 && <Dropdown2 />}
          </li>
          <li
            className="nav-item"
            onMouseEnter={() => {
              onMouseEnter("Adequação do site");
            }}
            onMouseLeave={() => {
              onMouseLeave("Adequação do site");
            }}
          >
            <Link
              // to="/services"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              Adequação do site <i className="fas fa-caret-down" />
            </Link>
            {dropdown3 && <Dropdown3 />}
          </li>
          <li className="nav-item">
            <Link
              to="/treinamentos"
              className="nav-links"
              // onClick={closeMobileMenu}
            >
              Treinamentos
            </Link>
            <Link to="/" className="nav-links" onClick={logout}>
              Sair
            </Link>
          </li>
        </ul>
        {/* <Button /> */}
      </nav>
    </>
  );
}

export default Navbar;
