import Bolha from "../../imgs/Bolha.png";
import "./SobreNos.css"; /*lembrar de sempre importar o css*/
function SobreNos(props) {
  const title = "SOBRE NÓS";
  return (
    <div ref={props.reference} className="sobre">
      <div>
        <h2 className="subtitulo-sobrenos">{title}</h2>

        <h1 className="titulo-sobrenos">
          A solução mais fácil e prática para adequar sua empresa à LGPD{" "}
        </h1>
        <p>
          Nosso software é uma solução fácil de usar que auxilia pequenas e
          médias empresas na conformidade com a LGPD, mesmo sem conhecimento
          técnico. Ele simplifica a gestão de dados pessoais, permitindo que as
          empresas coletem, armazenem e processem informações de acordo com a
          lei. Com uma interface amigável, nosso software orienta os usuários
          por meio de etapas simples para garantir a proteção de dados. Além
          disso, oferecemos preços acessíveis, tornando a conformidade possível
          para empresas de todos os tamanhos. Ajude sua empresa a cumprir a LGPD
          de forma tranquila e econômica com nossa solução intuitiva.
        </p>
      </div>

      <img className="img-sobrenos" src={Bolha} alt="bolha" />
    </div>
  );
}

export default SobreNos;
