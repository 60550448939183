import React, { useState } from "react";
import Navbar from "../../Componentes/Navbar/Navbar";
import styled from "styled-components";
import CampoTexto from "../../Componentes/CampoTexto";

const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;
const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;
const CodeContainer = styled.div`
  border: 1px solid #be00ff;
  border-radius: 25px;
  padding: 1%;
`;
const CodeSection = styled.code`
  background-color: #eeeeee;
  color: #5b0390;
`;

const BarraDeCookies = () => {
  const [link, setLink] = useState("");
  const [email, setEmail] = useState("");

  const neumorphismContainer = {
    borderRadius: "18px",
    background: "#ffffff",
    margin: "2% 18%",
    padding: "1%",
    boxShadow: "20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff",
  };

  return (
    <div>
      <Navbar />
      <Title>Barra de Cookies</Title>
      <div style={neumorphismContainer}>
        <SectionTitle>Informação</SectionTitle>
        <p>
          Para gerar o script que você irá adicionar no seu site, preencha os
          campos abaixo e clique em gerar script
        </p>
        <p></p>

        <CampoTexto
          required={true}
          label={"Link da política de privacidade no seu site:"}
          placeholder={"Cole o link aqui: "}
          onChange={(e) => setLink(e.target.value)}
        />
        <CampoTexto
          required={true}
          label={"E-mail de contato da empresa:"}
          placeholder={"Digite o e-mail"}
          type={"email"}
          onChange={(e) => setEmail(e.target.value)}
        />

        <SectionSubTitle>{`Copie e cole este código em todas as páginas do seu site, antes da tag </body>`}</SectionSubTitle>

        <div>
          <p>
            Este site utiliza cookies. Ao continuar, você concorda com nossa
            política de privacidade.
          </p>
        </div>

        <CodeContainer>
          <CodeSection>{`<style>
              #popupazak {
                z-index: 1000;
                display: none;
                flex-direction: column;
                position: fixed;
                bottom: 10px;
                right: 10px;
                padding: 10px 15px;
                font-family: sans-serif;
                background-color: #5b0390;
                background: linear-gradient(
          0deg,
          rgba(21, 4, 66, 1) 0%,
          rgb(101, 0, 134) 100%
                );
                border: 1px solid #ccc;
                border-radius: 25px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                width: 800px;
                left: 50%;
                margin-left: -400px;
                color: white;
              }
              .btnazak {
                display: block;
          
                background: none;
                color: white;
                width: 200px;
                height: 50px;
                font-size: 16px;
                border-radius: 10px;
                border: 3px solid #ffffff;
                transition: 0.2s ease;
              }
              .btnazak:hover {
                cursor: pointer;
                scale: 1.1;
              }
              .linkazak {
                display: inline-block;
                margin: 7px 3px;
                color: white;
                text-decoration: none;
              }
            </style>
            <div id="popupazak">
              <p>
                Este site utiliza cookies. Ao continuar, você concorda com nossa política
                de privacidade.
              </p>
              <div
                style="display: flex; align-items: center; justify-content: space-between"
              >
                <button class="btnazak" onclick="aceitarPolitica()">Aceitar</button>
          
                <a
          class="linkazak"
          href="${link}"
          target="_blank"
          ><button class="btnazak">Política de privacidade</button></a
                >
                <a
          class="linkazak"
          href="https://geradordelgpd.com.br/seusdireitos.php?email=contato@olamidia.com.br"
          target="_blank"
          ><button class="btnazak">Seus direitos</button></a
                >
              </div>
              <div style="display: flex; justify-content: right; align-items: end">
                <div style="width: 40%">
          <img
            src="https://ik.imagekit.io/azakv/cookie.png?updatedAt=1705915890119"
            alt="Cookie icon"
            style="width: 70px; margin-left: 30px"
          />
                </div>
                <div>
          <img
            style="width: 150px"
            src="https://ik.imagekit.io/azakv/powered%20by.png?updatedAt=1705916476909"
            alt="powered by lgpd lock"
          />
                </div>
              </div>
            </div>
            <script>
              function exibirPopup() {
                var check = localStorage.getItem("avisoCookies");
                if (!check) {
          document.getElementById("popupazak").style.display = "flex";
                }
              }
              function aceitarPolitica() {
                localStorage.setItem("avisoCookies", "accept");
                fecharPopup();
              }
              function fecharPopup() {
                document.getElementById("popupazak").style.display = "none";
              }
              window.onload = function () {
                setTimeout(exibirPopup, 3000);
              };
            </script>
          `}</CodeSection>
        </CodeContainer>
      </div>
    </div>
  );
};

export default BarraDeCookies;
