export const PerguntasPoliticaDePrivacidade = [
  {
    id: 0,
    codigo: 'PPR0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta:
      'No site da sua empresa tem alguma forma de captura de dados? (Cadastro, mensagem, newsletter).',
  },
  {
    id: 1,
    codigo: 'PPR1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta: 'Sua empresa tem Redes Sociais.',
  },
  {
    id: 2,
    codigo: 'PPR2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta: 'Sua empresa utiliza WhatsApp para conversar com clientes.      ',
  },
  {
    id: 3,
    codigo: 'PPR3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta: 'Sua empresa utiliza SMS para conversar com clientes.',
  },
  {
    id: 4,
    codigo: 'PPR4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta:
      'Sua empresa possui aplicativo próprio ou utiliza aplicativo de terceiros (para fazer gestão financeira, controle de dados ou outras funções).',
  },
  {
    id: 5,
    codigo: 'PPR5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta:
      'Sua empresa se comunica com os clientes através de centrais de atendimento e vendas.',
  },
  {
    id: 6,
    codigo: 'PPR6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta:
      'Você faz algum tipo de interação (anúncio, propaganda, formulário) online.',
  },
  {
    id: 7,
    codigo: 'PPR7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta:
      'Existem cadastros feitos e preenchidos, por clientes, em eventos ou ocasiões presenciais.',
  },
  {
    id: 8,
    codigo: 'PPR8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta:
      'Existem dados recebidos de terceiros? Exemplos: (redes sociais e sites parceiros, sites vindos de fontes públicas).',
  },
  {
    id: 9,
    codigo: 'PPR9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta: 'Sua empresa coleta informações de contato.',
  },
  {
    id: 88,
    codigo: 'PPR88',
    name: 'pergunta88',
    value: 'pergunta88',
    pergunta: 'Seu site possui área interna ou solicita cadastro dos usuários?',
  },
  {
    id: 10,
    codigo: 'PPR10',
    name: 'pergunta10',
    value: 'pergunta10',
    pergunta:
      'Sua empresa faz perguntas sobre os hábitos e informações demográficas dos seus clientes.',
  },
  {
    id: 11,
    codigo: 'PPR11',
    name: 'pergunta11',
    value: 'pergunta11',
    pergunta:
      'Sua empresa coleta dados técnicos do computador ou celular dos clientes.',
  },
  {
    id: 12,
    codigo: 'PPR12',
    name: 'pergunta12',
    value: 'pergunta12',
    pergunta: 'Utiliza coleta automática de dados (cookies).',
  },
  {
    id: 13,
    codigo: 'PPR13',
    name: 'pergunta13',
    value: 'pergunta13',
    pergunta: 'Você faz pesquisa de satisfação com os clientes.',
  },
  {
    id: 14,
    codigo: 'PPR14',
    name: 'pergunta14',
    value: 'pergunta14',
    pergunta: 'A empresa faz sorteio utilizando dados dos clientes.',
  },
  {
    id: 15,
    codigo: 'PPR15',
    name: 'pergunta15',
    value: 'pergunta15',
    pergunta: 'A empresa realiza vendas online.',
  },
  {
    id: 16,
    codigo: 'PPR16',
    name: 'pergunta16',
    value: 'pergunta16',
    pergunta: 'As conversas com a central de atendimento são gravadas.',
  },
  {
    id: 17,
    codigo: 'PPR17',
    name: 'pergunta17',
    value: 'pergunta17',
    pergunta:
      'Sua empresa coleta dados referentes à origem racial ou étnica (de clientes ou funcionários).',
  },
  {
    id: 18,
    codigo: 'PPR18',
    name: 'pergunta18',
    value: 'pergunta18',
    pergunta:
      'Sua empresa coleta dados referentes à convicção religiosa (de clientes ou funcionários).',
  },
  {
    id: 19,
    codigo: 'PPR19',
    name: 'pergunta19',
    value: 'pergunta19',
    pergunta:
      'Sua empresa coleta dados referentes à opinião política (de clientes ou funcionários).',
  },

  {
    id: 20,
    codigo: 'PPR20',
    name: 'pergunta20',
    value: 'pergunta20',
    pergunta:
      'Sua empresa coleta dados referentes à filiação a sindicato ou a organização de caráter religioso (de clientes ou funcionários).',
  },
  {
    id: 21,
    codigo: 'PPR21',
    name: 'pergunta21',
    value: 'pergunta21',
    pergunta:
      'Sua empresa coleta dados filosóficos ou políticos (de clientes ou funcionários).',
  },

  {
    id: 22,
    codigo: 'PPR22',
    name: 'pergunta22',
    value: 'pergunta22',
    pergunta:
      'Sua empresa coleta dados referentes à saúde ou à vida sexual (de clientes ou funcionários).',
  },
  {
    id: 23,
    codigo: 'PPR23',
    name: 'pergunta23',
    value: 'pergunta23',
    pergunta:
      'Sua empresa coleta dados genéticos ou biométricos de pessoas naturais (clientes ou funcionários).',
  },

  {
    id: 24,
    codigo: 'PPR24',
    name: 'pergunta24',
    value: 'pergunta24',
    pergunta: 'Sua empresa coleta dados de crianças e adolescentes.',
  },
  {
    id: 25,
    codigo: 'PPR25',
    name: 'pergunta25',
    value: 'pergunta25',
    pergunta: 'Sua empresa utiliza cookies de sessão.',
  },
  {
    id: 26,
    codigo: 'PPR26',
    name: 'pergunta26',
    value: 'pergunta26',
    pergunta: 'Sua empresa utiliza cookies persistentes.',
  },
  {
    id: 27,
    codigo: 'PPR27',
    name: 'pergunta27',
    value: 'pergunta27',
    pergunta: 'Sua empresa utiliza cookies necessários.    ',
  },
  {
    id: 28,
    codigo: 'PPR28',
    name: 'pergunta28',
    value: 'pergunta28',
    pergunta: 'Sua empresa utiliza cookies em propagandas.',
  },
  {
    id: 29,
    codigo: 'PPR29',
    name: 'pergunta29',
    value: 'pergunta29',
    pergunta: 'Sua empresa utiliza cookies de terceiros.',
  },
  {
    id: 30,
    codigo: 'PPR30',
    name: 'pergunta30',
    value: 'pergunta30',
    pergunta: 'Sua empresa utiliza alguma espécie de provedor de serviços.',
  },
  {
    id: 31,
    codigo: 'PPR31',
    name: 'pergunta31',
    value: 'pergunta31',
    pergunta:
      'Sua empresa utiliza o serviço de agências de análise de crédito.',
  },
  {
    id: 32,
    codigo: 'PPR32',
    name: 'pergunta32',
    value: 'pergunta32',
    pergunta:
      'Sua empresa utiliza o serviço de empresas de cobrança de dívidas.',
  },
  {
    id: 33,
    codigo: 'PPR33',
    name: 'pergunta33',
    value: 'pergunta33',
    pergunta: 'Sua empresa tem tabela de descarte de dados.',
  },
  {
    id: 34,
    codigo: 'PPR34',
    name: 'pergunta34',
    value: 'pergunta34',
    pergunta:
      'Sua empresa adota protocolo para manter os dados pessoais (de clientes e funcionários) seguros.',
  },
  {
    id: 35,
    codigo: 'PPR35',
    name: 'pergunta35',
    value: 'pergunta35',
    pergunta:
      'Sua empresa utiliza algum serviço de servidor que faça transferência dos dados pessoais dos clientes para fora do país.',
  },
  {
    id: 36,
    codigo: 'PPR36',
    name: 'pergunta36',
    value: 'pergunta36',
    pergunta: 'Nome do encarregado pelo tratamento de dados dessoais (DPO):',
  },
  {
    id: 37,
    codigo: 'PPR37',
    name: 'pergunta37',
    value: 'pergunta37',
    pergunta: 'E-mail (DPO):',
  },
  {
    id: 38,
    codigo: 'PPR38',
    name: 'pergunta38',
    value: 'pergunta38',
    pergunta: 'Telefone (DPO):',
  },
];
