export const cardArray = [
  {
    id: 0,
    imgUrl: 'mapeamento-de-dados',
    lastReview: '',
    title: 'Mapeamento de dados',
    finished: false,
    link: '/documento-mapeamento-dados',
  },
  {
    id: 1,
    imgUrl: 'matriz-de-risco',
    lastReview: '',
    title: 'Matriz de risco',
    finished: false,
    link: '/documento-matriz-risco',
  },
  {
    id: 2,
    imgUrl: 'aditivo-de-contrato-funcionario',
    lastReview: '',
    title: 'Aditivo de contrato - Funcionario',
    finished: false,
    link: '/documento-aditivo-clt',
  },
  {
    id: 3,
    imgUrl: 'tabela-de-descartes',
    lastReview: '',
    title: 'Tabela de descartes',
    finished: false,
    link: '/painel',
  },
  {
    id: 4,
    imgUrl: 'relatorio-de-impactos',
    lastReview: '',
    title: 'Relatório de impactos',
    finished: false,
    link: '/documento-relatorio-impacto',
  },
  {
    id: 5,
    imgUrl: 'aditivo-de-contrato-operador',
    lastReview: '',
    title: 'Aditivo de contrato - Operador',
    finished: false,
    link: '/documento-aditivo-controlador',
  },
  {
    id: 6,
    imgUrl: 'politica-de-seguranca-da-informacao',
    lastReview: '',
    title: 'Política de Segurança da informação',
    finished: false,
    link: '/documento-politica-seguranca',

  },
  {
    id: 7,
    imgUrl: 'politica-de-privacidade',
    lastReview: '',
    title: 'Política de privacidade',
    finished: false,
    link: '/documento-politica-privacidade',
  },
];
