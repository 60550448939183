import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Formulario from './Pages/Formulario';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Painel from './Pages/Painel';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './Firebase/firebase';
import MapeamentoDeDados from './Pages/SegurancaDaInformacao/MapeamentoDeDados';
import MatrizDeRisco from './Pages/SegurancaDaInformacao/MatrizDeRisco';
import TabelaDeDescarte from './Pages/SegurancaDaInformacao/TabelaDeDescarte';
import ControlesTecnicos from './Pages/SegurancaDaInformacao/ControlesTecnicos';
import IncidenteDeSeguranca from './Pages/SegurancaDaInformacao/IncidenteDeSeguranca';
import RelatorioDeImpacto from './Pages/ParteJuridica/RelatorioDeImpacto';
import PoliticaDeSeguranca from './Pages/ParteJuridica/PoliticaDeSeguranca';
import AditivoDeContratoClt from './Pages/ParteJuridica/AditivoDeContratoClt';
import AditivoDeContratoControlador from './Pages/ParteJuridica/AditivoDeContratoControlador';
import PoliticaDePrivacidade from './Pages/AdequacaoDoSite/PoliticaDePrivacidade';
import BarraDeCookies from './Pages/AdequacaoDoSite/BarraDeCookies';
import Treinamentos from './Pages/Treinamentos';
import { createTheme, ThemeProvider } from '@mui/material';
import DocumentoPoliticaDeSeguranca from './Pages/ParteJuridica/DocumentoPoliticaDeSeguranca';
import DocumentoRelatorioDeImpacto from './Pages/ParteJuridica/DocumentoRelatorioDeImpacto';
import DocumentoPoliticaDePrivacidade from './Pages/AdequacaoDoSite/DocumentoPoliticaDePrivacidade';
import DocumentoAditivoClt from './Pages/ParteJuridica/DocumentoAditivoClt';
import DocumentoMapeamentoDeDados from './Pages/SegurancaDaInformacao/DocumentoMapeamentoDeDados';
import DocumentoAditivoControlador from './Pages/ParteJuridica/DocumentoAditivoControlador';
import DocumentoMatrizDeRisco from './Pages/SegurancaDaInformacao/DocumentoMatrizDeRisco';
import DocumentoTabelaDeDescarte from './Pages/SegurancaDaInformacao/DocumentoTabelaDeDescarte';
import LandingPage from './Pages/LandingPage/LandingPage';
import NotFound from './Pages/NotFound';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BE00FF',
      main: '#BE00FF',
    },
    secondary: {
      main: '#150442',
      main: '#150442',
    },
    primaryLight: {
      main: '#dbece2',
      contrastText: '#616161',
      main: '#dbece2',
      contrastText: '#616161',
    },
  },
});

function App() {
  const [user, setUser] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(true);
      } else {
        setUser(false);
      }
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Router>
          <Routes>
            <Route path='/' element={user ? <Painel /> : <LandingPage />} />
            <Route
              path='/documento-relatorio-impacto'
              element={user ? <DocumentoRelatorioDeImpacto /> : <Login />}
            />
            <Route
              path='/documento-tabela-descarte'
              element={user ? <DocumentoTabelaDeDescarte /> : <Login />}
            />
            <Route
              path='/documento-matriz-risco'
              element={user ? <DocumentoMatrizDeRisco /> : <Login />}
            />
            <Route
              path='/documento-mapeamento-dados'
              element={user ? <DocumentoMapeamentoDeDados /> : <Login />}
            />
            <Route
              path='/documento-aditivo-clt'
              element={user ? <DocumentoAditivoClt /> : <Login />}
            />
            <Route
              path='/documento-aditivo-controlador'
              element={user ? <DocumentoAditivoControlador /> : <Login />}
            />
            <Route
              path='/documento-politica-seguranca'
              element={user ? <DocumentoPoliticaDeSeguranca /> : <Login />}
            />
            <Route
              path='/documento-politica-privacidade'
              element={user ? <DocumentoPoliticaDePrivacidade /> : <Login />}
            />
            <Route
              path='/formulario'
              element={user ? <Formulario /> : <Home />}
            />
            <Route path='/login' element={user ? <Painel /> : <Login />} />

            <Route path='/painel' element={user ? <Painel /> : <Home />} />
            <Route
              path='/mapeamento-de-dados'
              element={user ? <MapeamentoDeDados /> : <Home />}
            />
            <Route
              path='/mapeamento-de-dados'
              element={user ? <MapeamentoDeDados /> : <Home />}
            />
            <Route
              path='/matriz-de-risco'
              element={user ? <MatrizDeRisco /> : <Home />}
            />
            <Route
              path='/tabela-de-descarte'
              element={user ? <TabelaDeDescarte /> : <Home />}
            />
            <Route
              path='/controles-técnicos'
              element={user ? <ControlesTecnicos /> : <Home />}
            />
            <Route
              path='/incidente-de-segurança'
              element={user ? <IncidenteDeSeguranca /> : <Home />}
            />
            <Route
              path='/relatorio-de-impacto'
              element={user ? <RelatorioDeImpacto /> : <Home />}
            />
            <Route
              path='/politica-de-segurança'
              element={user ? <PoliticaDeSeguranca /> : <Home />}
            />
            <Route
              path='/aditivos-funcionario'
              element={user ? <AditivoDeContratoClt /> : <Home />}
            />
            <Route
              path='/aditivos-controlador'
              element={user ? <AditivoDeContratoControlador /> : <Home />}
            />
            <Route
              path='/política-de-privacidade'
              element={user ? <PoliticaDePrivacidade /> : <Home />}
            />
            <Route
              path='/barra-de-cookies'
              element={user ? <BarraDeCookies /> : <Home />}
            />
            <Route
              path='/treinamentos'
              element={user ? <Treinamentos /> : <Home />}
            />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
