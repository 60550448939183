import React, { useEffect, useState } from 'react';
import { videoArray } from '../Pages/videoArray';
import olho from '../imgs/olho.png';
import '../Pages/Treinamento.css';
import confirmacao from '../imgs/confirmacao.png';

const ListaDeVideos = (props) => {
  console.log(props.dados);
  return (
    <>
      {props.dataSource.map((item, index) => {
        return (
          <div key={index} className='class-container'>
            {props.dados[item.id] === true ? () => props.contaView : null}
            <div>
              <img
                className='view-image'
                alt='confirmado'
                src={props.dados[item.id] === true ? confirmacao : olho}
              ></img>
            </div>
            <p className='clickableClass' onClick={() => props.fn(index)}>
              {item.titulo}
            </p>
          </div>
        );
      })}
    </>
  );
};

export default ListaDeVideos;
