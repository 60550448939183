import React from "react";
import { PerguntasAditivoDeContratoClt as perguntas } from "../Dados/PerguntasAditivoDeContratoClt";
import styled from "styled-components";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import sourceArial from "../Fonts/arial.ttf";
import sourceArialBold from "../Fonts/arialbd.ttf";

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 50px;
  text-align: center;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-top: 100px;
`;

const Texto = styled.p`
  font-size: 30px;
`;

const Li = styled.li`
  font-size: 30px;
`;

const styles = StyleSheet.create({
  page: {
    fontFamily: "Arial",
    width: "100%",
  },
  titulo: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: "18px",
    textAlign: "center",
    paddingLeft: 30,
    paddingRight: 30,
    // fontWeight: '900',
  },
  section: {
    fontFamily: "Arial",
    fontSize: 12,
    marginBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: "justify",
  },
  p: {
    marginTop: 5,
    marginBottom: 5,
    textIndent: 30,
  },
  topico: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "800",
    fontSize: 14,
  },
  lista: {
    fontWeight: "800",
    textAlign: "center",
  },
  recuo: {
    marginLeft: 30,
    fontWeight: "800",
  },
  recuoItens: {
    marginLeft: 50,
  },
});

const ResultadoAditivoClt = (props) => {
  Font.register({
    family: "Arial",
    fonts: [
      {
        src: sourceArial,
      },
      {
        src: sourceArialBold,
        fontWeight: "bold",
      },
    ],
  });

  const MyDocument = () => (
    <Document style={{ width: "100%" }}>
      <Page size="A4" style={styles.page}>
        <View wrap={false}>
          <Text style={styles.titulo}>
            Termo de Consentimento para Tratamento de Dados Pessoais {"\n"} Lei
            Geral de Proteção de Dados Pessoais – LGPD
          </Text>
        </View>
        <View wrap={false} style={styles.section}>
          <Text style={styles.p}>
            {`Através do presente instrumento, eu ${props.dados.ADCLT0}, inscrito (a) no CPF sob n° ${props.dados.ADCLT1}, aqui denominado (a) como TITULAR, venho por meio deste, autorizar que a empresa ${props.dados.ADCLT2}, aqui denominada como CONTROLADORA, inscrita no CNPJ sob n° ${props.dados.ADCLT3}, em razão do contrato de trabalho, disponha dos meus dados pessoais e dados pessoais sensíveis, de acordo com os artigos 7° e 11 da Lei n° 13.709/2018, conforme disposto neste termo:`}
          </Text>
        </View>
        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA PRIMEIRA</Text>
          <Text style={styles.p}>
            O Titular autoriza a Controladora a realizar o tratamento, ou seja,
            a utilizar os seguintes dados pessoais, para os fins que serão
            relacionados na cláusula segunda:
          </Text>

          {props.dados.ADCLT5 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[5].pergunta}</Text>
          )}
          {props.dados.ADCLT6 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[6].pergunta}</Text>
          )}
          {props.dados.ADCLT7 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[7].pergunta}</Text>
          )}
          {props.dados.ADCLT8 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[8].pergunta}</Text>
          )}
          {props.dados.ADCLT9 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[9].pergunta}</Text>
          )}
          {props.dados.ADCLT10 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[10].pergunta}</Text>
          )}
          {props.dados.ADCLT11 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[11].pergunta}</Text>
          )}
          {props.dados.ADCLT12 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[12].pergunta}</Text>
          )}
          {props.dados.ADCLT13 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[13].pergunta}</Text>
          )}
          {props.dados.ADCLT14 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[14].pergunta}</Text>
          )}
          {props.dados.ADCLT15 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[15].pergunta}</Text>
          )}
          {props.dados.ADCLT16 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[16].pergunta}</Text>
          )}
          {props.dados.ADCLT17 === "Sim" && (
            <Text style={styles.recuoItens}>• {perguntas[17].pergunta}</Text>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA SEGUNDA</Text>
          <Text style={styles.lista}>Finalidade do Tratamento dos Dados</Text>
          <Text style={styles.p}>
            O Titular autoriza que a Controladora utilize os dados pessoais e
            dados pessoais sensíveis listados neste termo para as seguintes
            finalidades:
          </Text>
          <Text style={styles.recuoItens}>
            • Permitir que a Controladora identifique e entre em contato com o
            titular, em razão do contrato de trabalho;
          </Text>
          <Text style={styles.recuoItens}>
            • Para cumprimento de obrigações decorrentes da legislação,
            principalmente trabalhista e previdenciária, incluindo o disposto em
            Acordo ou Convenção Coletiva da categoria da Controladora;
          </Text>
          <Text style={styles.recuoItens}>
            • Para procedimentos de admissão e execução do contrato de trabalho,
            inclusive após seu término;
          </Text>
          <Text style={styles.recuoItens}>
            • Para cumprimento, pela Controladora, de obrigações impostas por
            órgãos de fiscalização;
          </Text>
          <Text style={styles.recuoItens}>
            • Quando necessário para a executar um contrato, no qual seja parte
            o titular;
          </Text>
          <Text style={styles.recuoItens}>
            • A pedido do titular dos dados;
          </Text>
          <Text style={styles.recuoItens}>
            • Para o exercício regular de direitos em processo judicial,
            administrativo ou arbitral;
          </Text>
          <Text style={styles.recuoItens}>
            • Para a proteção da vida ou da incolumidade física do titular ou de
            terceiros;
          </Text>
          <Text style={styles.recuoItens}>
            • Para a tutela da saúde, exclusivamente, em procedimento realizado
            por profissionais de saúde, serviços de saúde ou autoridade
            sanitária;
          </Text>
          <Text style={styles.recuoItens}>
            • Quando necessário para atender aos interesses legítimos do
            controlador ou de terceiros, exceto no caso de prevalecerem direitos
            e liberdades fundamentais do titular que exijam a proteção dos dados
            pessoais;
          </Text>
          <Text style={styles.recuoItens}>
            • Permitir que a Controladora utilize esses dados para a contratação
            e prestação de serviços diversos dos inicialmente ajustados, desde
            que o Titular também demonstre interesse em contratar novos
            serviços.
          </Text>
          <Text style={styles.p}>
            Parágrafo Primeiro: Caso seja necessário o compartilhamento de dados
            com terceiros que não tenham sido relacionados nesse termo ou
            qualquer alteração contratual posterior, será ajustado novo termo de
            consentimento para este fim (§ 6° do artigo 8° e § 2° do artigo 9°
            da Lei n° 13.709/2018).
          </Text>
          <Text style={styles.p}>
            Parágrafo Segundo: Em caso de alteração na finalidade, que esteja em
            desacordo com o consentimento original, a Controladora deverá
            comunicar o Titular, que poderá revogar o consentimento, conforme
            previsto na cláusula sexta.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA TERCEIRA</Text>
          <Text style={styles.lista}>Compartilhamento de Dados</Text>
          <Text style={styles.p}>
            A Controladora fica autorizada a compartilhar os dados pessoais do
            Titular com outros agentes de tratamento de dados, caso seja
            necessário para as finalidades listadas neste instrumento, desde
            que, sejam respeitados os princípios da boa-fé, finalidade,
            adequação, necessidade, livre acesso, qualidade dos dados,
            transparência, segurança, prevenção, não discriminação e
            responsabilização e prestação de contas.
          </Text>
        </View>
        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA QUARTA</Text>
          <Text style={styles.lista}>
            Responsabilidade pela Segurança dos Dados
          </Text>
          <Text style={styles.p}>
            A Controladora se responsabiliza por manter medidas de segurança,
            técnicas e administrativas suficientes a proteger os dados pessoais
            do Titular e à Autoridade Nacional de Proteção de Dados (ANPD),
            comunicando ao Titular, caso ocorra algum incidente de segurança que
            possa acarretar risco ou dano relevante, conforme artigo 48 da Lei
            n° 13.709/2020.
          </Text>
        </View>
        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA QUINTA</Text>
          <Text style={styles.lista}>Término do Tratamento dos Dados</Text>
          <Text style={styles.p}>
            À Controladora, é permitido manter e utilizar os dados pessoais do
            Titular durante todo o período contratualmente firmado para as
            finalidades relacionadas nesse termo e ainda após o término da
            contratação para cumprimento de obrigação legal ou impostas por
            órgãos de fiscalização, nos termos do artigo 16 da Lei n°
            13.709/2018.
          </Text>
        </View>
        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA SEXTA</Text>
          <Text style={styles.lista}>
            Direito de Revogação do Consentimento
          </Text>
          <Text style={styles.p}>
            O Titular poderá revogar seu consentimento, a qualquer tempo, por
            e-mail ou por carta escrita, conforme o artigo 8°, § 5°, da Lei n°
            13.709/2020.
          </Text>
          <Text style={styles.p}>
            O Titular fica ciente de que a Controladora poderá permanecer
            utilizando os dados para as seguintes finalidades:
          </Text>
          <Text style={styles.recuoItens}>
            • Para cumprimento de obrigações decorrentes da legislação
            trabalhista e previdenciária, incluindo o disposto em Acordo ou
            Convenção Coletiva da categoria da Controladora;
          </Text>
          <Text style={styles.recuoItens}>
            • Para procedimentos de admissão e execução do contrato de trabalho,
            inclusive após seu término;
          </Text>
          <Text style={styles.recuoItens}>
            • Para cumprimento, pela Controladora, de obrigações impostas por
            órgãos de fiscalização;
          </Text>
          <Text style={styles.recuoItens}>
            • Para o exercício regular de direitos em processo judicial,
            administrativo ou arbitral;
          </Text>
          <Text style={styles.recuoItens}>
            • Para a proteção da vida ou da incolumidade física do titular ou de
            terceiros;
          </Text>
          <Text style={styles.recuoItens}>
            • Para a tutela da saúde, exclusivamente, em procedimento realizado
            por profissionais de saúde, serviços de saúde ou autoridade
            sanitária;
          </Text>
          <Text style={styles.recuoItens}>
            • Quando necessário para atender aos interesses legítimos do
            controlador ou de terceiros, exceto no caso de prevalecerem direitos
            e liberdades fundamentais do titular que exijam a proteção dos dados
            pessoais.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA SÉTIMA</Text>
          <Text style={styles.lista}>
            Tempo de Permanência dos Dados Recolhidos
          </Text>
          <Text style={styles.p}>
            O titular fica ciente de que a Controladora deverá permanecer com os
            seus dados pelo período mínimo de guarda de documentos trabalhistas,
            previdenciários, bem como os relacionados à segurança e saúde no
            trabalho, mesmo após o encerramento do vínculo empregatício.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          {props.dados.ADCLT18 === "Sim" && (
            <>
              <Text style={styles.titulo}>CLÁUSULA OITAVA</Text>
              <Text style={styles.lista}>
                Com relação às Câmeras de Segurança:
              </Text>
              <Text style={styles.p}>
                O titular fica ciente que a empresa utiliza sistema de
                monitoramento interno e externo com filmagem e gravação de
                imagens por câmeras de segurança nas dependências da empresa.
              </Text>
              <Text style={styles.p}>
                Declara ainda que tem conhecimento da localização das câmeras e
                que autoriza a utilização das imagens para fins lícitos de
                segurança da empresa e dos funcionários.
              </Text>
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>CLÁUSULA NONA</Text>
          <Text style={styles.lista}>Autorização de Imagem e Som</Text>
          <Text style={styles.p}>
            O titular autoriza o uso de sua imagem e voz, em todo e qualquer
            material entre fotos, documentos e outros meios de comunicação, para
            campanhas promocionais e institucionais do CAU/MT, sejam essas
            destinadas à divulgação ao público em geral e/ou apenas para uso do
            Conselho, e desde que não haja desvirtuamento da sua finalidade.
          </Text>
          <Text style={styles.p}>
            A presente autorização é concedida a título gratuito, abrangendo o
            uso da imagem acima mencionada em todo território nacional e no
            exterior, sob qualquer forma e meios, ou sejam, em destaque: (I)
            out-door; (II) bus-door; folhetos em geral (encartes, mala direta,
            catálogo, etc.); (III) folder de apresentação; (IV) anúncios em
            revistas e jornais em geral; (V) home page; (VI) cartazes; (VII)
            back-light; (VIII) mídia eletrônica (painéis, vídeo-tapes,
            televisão, cinema, programa para rádio, entre outros).
          </Text>
          <Text style={styles.p}>
            Por esta ser a expressão da vontade DO(a) AUTORIZADOR(A), este
            autoriza o uso acima descrito sem que nada haja a ser reclamado a
            título de direitos conexos ao seu direito de imagem ou a qualquer
            outro.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>DÉCIMA CLÁUSULA</Text>
          <Text style={styles.lista}>
            Responsabilidade com bens e equipamentos
          </Text>
          <Text style={styles.p}>
            A utilização de equipamentos e instalações da empresa para qualquer
            finalidade que não seja a serviço da própria deve ser autorizada
            pela chefia imediata. Bens como computadores e celulares colocados à
            disposição dos profissionais para o trabalho devem ser usados de
            modo responsável e consciente, prioritariamente para atividades
            profissionais e jamais em conflito com os objetivos da empresa ou
            com as orientações deste Termo. Zelar pelo bom estado e uso dos
            equipamentos é uma obrigação de todos e por isso não é permitido:
          </Text>
          <Text style={styles.recuoItens}>
            • Usar o acesso à internet, e-mail e outros equipamentos em
            desacordo com a política de segurança da informação e orientações de
            uso das redes sociais;
          </Text>
          <Text style={styles.recuoItens}>
            • Usar relatórios internos ou informações da empresa em benefício
            próprio ou para favorecer terceiros;
          </Text>
          <Text style={styles.recuoItens}>
            • Usar bens da empresa em benefício próprio ou para favorecer
            terceiros, exceto quando se tratar de benefício regularmente
            concedido em função da relação de trabalho;
          </Text>
          <Text style={styles.recuoItens}>
            • Usar suas ferramentas de trabalho, e-mail corporativo, marcas e
            outros símbolos da empresa para obter vantagem pessoal, patrimonial
            ou de outra natureza;
          </Text>
        </View>
        <View wrap={false} style={styles.section}>
          <Text style={styles.titulo}>DÉCIMA PRIMEIRA CLÁUSULA</Text>
          <Text style={styles.lista}>
            Responsabilidade com bens e equipamentos
          </Text>
          <Text style={styles.p}>
            As partes poderão entrar em acordo, quanto aos eventuais danos
            causados, caso exista o vazamento de dados pessoais ou acessos não
            autorizados, e caso não haja acordo, a Controladora tem ciência que
            estará sujeita às penalidades previstas no artigo 52 da Lei n°
            13.709/2018:
          </Text>

          <Text
            style={([styles.p], { textAlign: "center", marginTop: 10 })}
          >{`${props.dados.ADCLT4}, ____ de ________________ de _________. `}</Text>
          <Text style={([styles.p], { textAlign: "center", marginTop: 10 })}>
            _____________________________
          </Text>
          <Text style={([styles.p], { textAlign: "center" })}>
            {props.dados.ADCLT0}
          </Text>
          <Text style={([styles.p], { textAlign: "center", marginTop: 10 })}>
            {" "}
            _____________________________{" "}
          </Text>
          <Text style={([styles.p], { textAlign: "center" })}>
            {props.dados.ADCLT2}{" "}
          </Text>
          <Text style={([styles.p], { textAlign: "center" })}>
            {props.dados.ADCLT3}
          </Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Container>
      <PDFViewer style={{ width: "100%", height: "100%" }}>
        <MyDocument />
      </PDFViewer>
    </Container>
  );
};

export default ResultadoAditivoClt;
