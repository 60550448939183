import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { perguntas } from '../Dados/Perguntas';

const Formulario = () => {
  const navigate = useNavigate();
  const [checkedState, setCheckedState] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const handleOnChange = (position) => {
    //Percorre o array e se a posição passada for igual à posição do array, inverte seu estado
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
  };

  const enviaRespostas = () => {
    navigate('/documento', { state: checkedState });
  };

  return (
    <>
      <div>Formulario</div>
      <ul>
        {perguntas.map((item) => (
          <li key={item.id}>
            <input
              type='checkbox'
              id={item.id}
              name={item.name}
              value={item.value}
              checked={checkedState[item.id]}
              onChange={() => handleOnChange(item.id)}
            />
            <label htmlFor={'opcao1'}>{item.pergunta}</label>
          </li>
        ))}
      </ul>
      <button onClick={enviaRespostas}>Enviar</button>
    </>
  );
};

export default Formulario;
