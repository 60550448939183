import { useEffect, useRef, useState } from "react";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import generatePDF from "react-to-pdf";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Navbar from "../../Componentes/Navbar/Navbar";
import ResultadoMapeamentoDeDados from "../../Componentes/ResultadoMapeamentoDeDados";
import seta from "../../imgs/Seta.png";
import { perguntasMapeamentoDeDados as perguntas } from "../../Dados/PerguntasMapeamentoDeDados";

const Container = styled.div`
  margin: 20px auto;
  /* max-width: 800px; */
  background-color: var(--branco);
  /* height: 100vh; */
`;

const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const DocumentoMapeamentoDeDados = () => {
  let arrPerguntasRespostas = Array(perguntas.length).fill(0);
  const { state } = useLocation();
  const [usuario, setUsuario] = useState(getAuth()?.currentUser?.uid);
  const [dados, setDados] = useState(null);
  const [dadosSeparados, setDadosSeparados] = useState(null);
  const targetRef = useRef();
  // let userId = getAuth()?.currentUser?.uid;

  onAuthStateChanged(getAuth(), (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      setUsuario(user.uid);
    } else {
      // User is signed out
      // ...
    }
  });

  const getDados = async () => {
    try {
      const docRef = doc(db, "usuarios", usuario);
      const docSnap = await getDoc(docRef);
      setDados(docSnap.data());
      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data().MPR5);
        setDados(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDados();
  }, [usuario]);

  function verificarAgente(primeiraPergunta, ultimaPergunta, obj) {
    //transformar o obj em array so com MP
    //ordenar esse array
    //iterar main array
    //acahar o indice da 1 pergunta
    //achar o indice da  2 pergunta
    //iterar da 1 ate a segunda somando em uma var auxiliar os sim's,
    //se a var aux > 6 retorna corretamente

    //pegar numero da pergunta
    let respArr = [];
    let sims = 0;
    let nPergunta1 = parseInt(primeiraPergunta.substring(3, 5));
    let nPergunta2 = parseInt(ultimaPergunta.substring(3, 5));

    // console.log(nPergunta1);
    // console.log(nPergunta2);

    for (let i = nPergunta1; i <= nPergunta2; i++) {
      respArr.push(obj[`MPR${i}`]);
    }
    // console.log(obj);
    // console.log(respArr);

    for (let item of respArr) {
      if (item === "Sim") {
        sims++;
      }
    }

    // console.log(sims);

    if (sims >= 6) {
      return "Controlador de dados";
    } else {
      return "Não controla dados";
    }
  }

  function renderValues(primeiraPergunta, ultimaPergunta, obj) {
    let respArr = [];
    let sims = 0;
    let nPergunta1 = parseInt(primeiraPergunta.substring(3, 5));
    let nPergunta2 = parseInt(ultimaPergunta.substring(3, 5));

    // console.log(nPergunta1);
    // console.log(nPergunta2);

    for (let i = nPergunta1; i <= nPergunta2; i++) {
      respArr.push(obj[`MPR${i}`]);
    }

    return respArr;
  }

  function renderKeys(primeiraPergunta, ultimaPergunta, obj) {
    let respArr = [];
    let sims = 0;
    let nPergunta1 = parseInt(primeiraPergunta.substring(3, 5));
    let nPergunta2 = parseInt(ultimaPergunta.substring(3, 5));

    console.log(nPergunta1);
    console.log(nPergunta2);

    for (let i = nPergunta1; i <= nPergunta2; i++) {
      respArr.push(`MPR${i}`);
    }

    return respArr;
  }
  return (
    <div>
      <Navbar />
      <div ref={targetRef}>
        <Container>
          {dados && <ResultadoMapeamentoDeDados dados={dados} />}
        </Container>
      </div>
      <ButtonContainer>
        <ButtonPattern2
          onClick={() =>
            generatePDF(targetRef, {
              filename: "documentoLGPD.pdf,",
              method: "open",
              page: {
                margin: 20, // margin is in MM
                format: "A4",
                orientation: "portrait",
              },
            })
          }
        >
          Gerar PDF
        </ButtonPattern2>
      </ButtonContainer>
    </div>
  );
};

export default DocumentoMapeamentoDeDados;
