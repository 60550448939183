import React, { useEffect, useState } from 'react';
import Navbar from '../Componentes/Navbar/Navbar';
import CardDocumento from '../Componentes/CardDocumento/CardDocumento';
import CardDownloadAll from '../Componentes/CardDocumento/CardDownloadAll';
import imagem from '../imgs/idv images/teste.png';
import Termometro from '../Componentes/Termometro';
import './Painel.css';
import { cardArray } from './cardArray';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../Firebase/firebase';
import { createGlobalStyle } from 'styled-components';

const Painel = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [usuario, setUsuario] = useState(getAuth()?.currentUser?.uid);
  const [dados, setDados] = useState(null);
  const [finished, setFinished] = useState([]);

  const getDados = async () => {
    try {
      const docRef = doc(db, 'usuarios', usuario);
      const docSnap = await getDoc(docRef);
      setDados(docSnap.data());
      if (docSnap.exists()) {
        setDados(docSnap.data());
        docSnap.data().MPR0
          ? (cardArray[0].finished = true)
          : (cardArray[0].finished = false);

        docSnap.data().MR0
          ? (cardArray[1].finished = true)
          : (cardArray[1].finished = false);
        docSnap.data().ADCLT0
          ? (cardArray[2].finished = true)
          : (cardArray[2].finished = false);
        docSnap.data().TD0
          ? (cardArray[3].finished = true)
          : (cardArray[3].finished = false);
        docSnap.data().RI0
          ? (cardArray[4].finished = true)
          : (cardArray[4].finished = false);
        docSnap.data().ADCTR0
          ? (cardArray[5].finished = true)
          : (cardArray[5].finished = false);
        docSnap.data().PSI0
          ? (cardArray[6].finished = true)
          : (cardArray[6].finished = false);
        docSnap.data().PPR0
          ? (cardArray[7].finished = true)
          : (cardArray[7].finished = false);
      } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDados();
  }, [usuario]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='pannel-main-container'>
      <Navbar></Navbar>
      {isMobile ? (
        <div className='pannel-container'>
          <div className='swiper-container'>
            <Swiper
              className='swiper'
              spaceBetween={200}
              slidesPerView={3}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {cardArray.map((item, index) => {
                return (
                  <SwiperSlide
                    onClick={() => (item.finished ? navigate(item.link) : '')}
                    key={item.id}
                    className='grid-item'
                  >
                    <CardDocumento
                      src={require(`../imgs/Imagens painel/${item.imgUrl}.png`)}
                      date={item.lastReview}
                      title={item.title}
                      // finished={item.finished}
                    ></CardDocumento>
                  </SwiperSlide>
                );
              })}
              <SwiperSlide className='grid-item'>
                <CardDownloadAll />
              </SwiperSlide>
            </Swiper>{' '}
          </div>
          <div className='termometer-container'>
            <p className='termometer-title'>Termômetro</p>
            <p className='termometer-info' style={{ textAlign: 'center' }}>
              O objetivo do termômetro da adequação é mostrar para você o
              caminho da adequação completa da sua empresa. Assim que você
              percorrer todos os pontos (e preencher todos os documentos) da
              Segurança da Informação, da parte jurídica, da adequação do site e
              dos treinamentos seu termômetro marcará os 100%. Lembrando que a
              jornada de adequação não termina por aqui. Você precisa manter os
              documentos atualizados e repassar os treinamentos para seus
              funcionários ao longo do tempo.
            </p>
            <Termometro></Termometro>
          </div>
        </div>
      ) : (
        <div className='pannel-container'>
          <div className='card-grid-container'>
            {cardArray.map((item, index) => {
              return (
                <div
                  onClick={() => (item.finished ? navigate(item.link) : '')}
                  key={item.id}
                  className='grid-item'
                >
                  <CardDocumento
                    src={
                      item.finished
                        ? require(`../imgs/Imagens painel/${item.imgUrl}.png`)
                        : require(`../imgs/fechadura.png`)
                    }
                    date={item.lastReview}
                    title={item.title}
                    finished={item.finished}
                  ></CardDocumento>
                </div>
              );
            })}

            <div className='grid-item'>
              <CardDownloadAll />
            </div>
          </div>
          <div className='termometer-container'>
            <p className='termometer-title'>Termômetro</p>
            <p className='termometer-info' style={{ textAlign: 'center' }}>
              O objetivo do termômetro da adequação é mostrar para você o
              caminho da adequação completa da sua empresa. Assim que você
              percorrer todos os pontos (e preencher todos os documentos) da
              Segurança da Informação, da parte jurídica, da adequação do site e
              dos treinamentos seu termômetro marcará os 100%. Lembrando que a
              jornada de adequação não termina por aqui. Você precisa manter os
              documentos atualizados e repassar os treinamentos para seus
              funcionários ao longo do tempo.
            </p>
            <Termometro></Termometro>
          </div>
        </div>
      )}

      {/* <CardDownloadAll></CardDownloadAll> */}
      {/* <Termometro></Termometro> */}
    </div>
  );
};

export default Painel;
