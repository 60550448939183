import React from "react";
import imagem from "../../imgs/idv images/download-all.png";
import "./CardDownloadAll.css";

const CardDownloadAll = (props) => {
  return (
    <div className="main-download-card">
      <img className="document-download-card-image" src={imagem} alt="img" />
      <p className="document-download-card-title">Baixar todos</p>
    </div>
  );
};

export default CardDownloadAll;
