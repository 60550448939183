import { Button, TextField, createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  min-width: 400px;
  border: 0.5px solid var(--borda-texto);
  padding: 9px 5px;
  margin: 10px;
  border-radius: 5px;
  /* @media (max-width: 600px) {
    width: 100vw;
    flex-wrap: wrap;
    margin: 15px 10px;
    font-size: 18px;
    min-width: 100px;
  } */
`;

const Rotulo = styled.label`
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  color: var(--azul-escuro-titulo);
  margin-bottom: 18px;
`;

const InputContainer = styled.div`
  /* width: 100%; */
  border: 0.5px;
  width: 50%;
`;

const textFieldStyle = {
  width: '100%',
};

const CampoTexto = (props) => {
  return (
    <Container>
      <Rotulo htmlFor={props.Rótulo}>{props.label}</Rotulo>
      {/* <Input
        defaultValue={props?.defaultValue}
        required={props.required}
        placeholder={props.placeholder}
        type={props.type || "text"}
        maxLength={props.max}
        id={props.label}
        name={props.label}
        onChange={(e) => props.onChange(e)}
        value={props.value}
        disabled={props.disabled}
      /> */}
      <InputContainer>
        <TextField
          style={textFieldStyle}
          defaultValue={props?.defaultValue}
          required={props.required}
          placeholder={props.placeholder}
          type={props.type || 'text'}
          maxLength={props.max}
          idlabel={props.label}
          name={props.label}
          onChange={(e) => props.onChange(e)}
          value={props.value}
          disabled={props.disabled}
          id='outlined-basic'
          color='primary'
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#150442',
            },
          }}
          label={props.type == 'date' ? '' : 'Escreva'}
          variant='outlined'
        />
      </InputContainer>
    </Container>
  );
};

export default CampoTexto;
