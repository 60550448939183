import React from "react";
import { perguntasMatrizDeRiscos as perguntas } from "../Dados/PerguntasMatrizDeRisco";
import styled from "styled-components";

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const MatrizContainer = styled.div`
  margin: 0 auto;
`;
const MatrizContentContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background: #fafafa;
  box-shadow: 20px 20px 60px #1504423e, -20px -20px 60px #efbeff3d;
  margin: 2% 0;
  border-radius: 25px;
  padding: 2%;
`;
const MatrizContent1 = styled.span`
  min-width: 50%;
  text-align: center;
  color: #be00ff;
  font-weight: 600;
  font-size: 25px;
`;
const MatrizContent2 = styled.span`
  font-weight: 600;
  color: #150442;
  border-left: 7px solid #be00ff;
  padding-left: 10px;
`;

const ResultadoMatrizDeRisco = (props) => {
  console.log("sasuke");
  console.log(props.dados);

  const riscos = [
    {
      risco: "Vazamento de dados da empresa",
      media: 0,
      texto:
        "<ul><li>Controlar os acessos(ver treinamento)</li> <li>Implantar firewall e antivírus</li> <li>Senhas fortes (ver treinamento)</li><li> Importante que os funcionários assistam ao treinamento: Como evitar vazamento de dados?</li><ul>",
    },
    {
      risco: "Vazamento de dados dos clientes",
      media: 0,
      texto:
        " <ul><li>Controlar os acessos</li> <li>Implantar firewall e antivírus</li> <li>Senhas fortes</li><li> Importante que os funcionários assistam ao treinamento: Como evitar vazamento de dados?</li><ul>",
    },
    {
      risco: "Vazamento de dados dos funcionários",
      media: 0,
      texto:
        " <ul><li>Controlar os acessos</li> <li>Implantar firewall e antivírus</li> <li>Senhas fortes</li><li> Importante que os funcionários assistam ao treinamento: Como evitar vazamento de dados?</li><ul>",
    },
    {
      risco: "Extravio de equipamento eletrônico",
      media: 0,
      texto:
        "<ul><li>Utilize senhas complexas.</li><li>Utilize ( se possível) criptografia do disco, para evitar que removam o disco rígido do equipamento, pluguem em outro e tenham acesso aos dados.<ul><li>            <strong>Autenticação para acesso corporativo: </strong>Além da autenticação do equipamento mencionada acima, tenha outro processo de validação para o acesso aos dados corporativos. Se possível, até tenha esse processo com dupla autenticação (uso de MFA).</li><li><strong>Restrição do acesso: </strong>Utilize programas originais e os mantenha sempre com a última versão aplicada. Uma das funções das atualizações é aplicar correções para falhas de segurança.</li><li><strong>Tenha backup: </strong>Mesmo com toda a proteção e adoção de           boas práticas, sempre tenha uma programação de backup automática ativa</li><li><strong>Conscientização: </strong>Forneça materiais educativos para a equipe usar com a adoção de boas práticas o equipamento.          </li></ul></li></ul>",
    },
    {
      risco: "Invasão de equipamento",
      media: 0,
      texto:
        "<ul><li>Tenha um bom antivírus;</li> <li>Tenha um bom antimalware;</li> <li>Mantenha os softwares atualizados;</li><li>Utilize VPN</li><ul>",
    },
    {
      risco: "Perda de documento por ausência de backup",
      media: 0,
      texto:
        "<ul><li> Instalar um programa de recuperação de dados;</li> <li>Fazer regularmente cópias de segurança (Backup);/li> <li>Escreva um plano de emergência</li><ul>",
    },
  ];

  riscos[0].media =
    (parseFloat(props.dados.MR0) + parseFloat(props.dados.MR1)) / 2;
  riscos[1].media =
    (parseFloat(props.dados.MR2) + parseFloat(props.dados.MR3)) / 2;
  riscos[2].media =
    (parseFloat(props.dados.MR4) + parseFloat(props.dados.MR5)) / 2;
  riscos[3].media =
    (parseFloat(props.dados.MR6) + parseFloat(props.dados.MR7)) / 2;
  riscos[4].media =
    (parseFloat(props.dados.MR8) + parseFloat(props.dados.MR9)) / 2;
  riscos[5].media =
    (parseFloat(props.dados.MR10) + parseFloat(props.dados.MR11)) / 2;
  const ordenado = riscos.sort((b, a) => a.media - b.media);
  console.log(riscos);
  console.log(ordenado);
  return (
    <div style={{ padding: "0 150px" }}>
      <Title>MATRIZ DE RISCO</Title>
      <MatrizContainer>
        {ordenado.map((item) => (
          <MatrizContentContainer>
            <MatrizContent1
              dangerouslySetInnerHTML={{ __html: item.risco }}
            ></MatrizContent1>
            <MatrizContent2
              dangerouslySetInnerHTML={{ __html: item.texto }}
            ></MatrizContent2>
          </MatrizContentContainer>
        ))}
      </MatrizContainer>
    </div>
  );
};

export default ResultadoMatrizDeRisco;
