import React from "react";
import styled from "styled-components";
import FeatureImg from "../../imgs/confirm.png";
import bgImg from "../../imgs/BackgroundPlanos.png";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 100px 0; */
  justify-content: center;
  /* background-image: url(${bgImg}); */
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 768px) {
    background-image: none;
  }
`;
const MainTitle = styled.h1`
  color: #5b0390;
  margin-bottom: 3%;
  text-align: center;
`;

const PlanContainer = styled.div`
  border: 2px solid #5b0390;
  border-radius: 15px;
  padding: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #5b0390;
`;
const Title = styled.h2`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 4px solid #be00ff;
  padding-bottom: 10px;
  font-size: 35px;
  width: 60%;
`;
const Subtitle = styled.h3`
  font-size: 12px;
  font-weight: 500;
  margin: 10px 0;
  margin-top: 0;
`;
const Price = styled.span``;
const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const Divisor = styled.div`
  width: 100%;
  height: 2px;
  background-color: #d6d6d6;
  margin: 25px 0;
`;

const FeaturesContainer = styled.div`
  display: flex;
`;

const FeatureCard = styled.div`
  width: 50%;
  margin: 0 10px;
`;
const FeatureTitle = styled.h1`
  font-size: 16px;
`;
const Feature = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 10px;
  img {
    width: 20px;
    margin-right: 10px;
  }
`;

const Planos = (props) => {
  return (
    <Container ref={props.reference}>
      <MainTitle>
        ESCOLHA SEU PLANO E COMEÇE A SE <br /> ADEQUAR AGORA
      </MainTitle>
      <PlanContainer>
        <Title>PLANO LOCK</Title>
        <Subtitle>Praticidade, tecnologia e preço justo.</Subtitle>
        <Price>
          <span style={{}}>R$ </span>
          <span style={{ fontSize: "35px", fontWeight: "600" }}>979,90</span>
          <span style={{}}> /mês</span>
        </Price>
        <Description>
          <p>(assinatura anual)</p>
          <p>
            Até <strong>5 usuários</strong>
          </p>
        </Description>
        <Divisor />
        <FeaturesContainer>
          <FeatureCard style={{ borderRight: "2px solid #5B0390" }}>
            <FeatureTitle>Segurança da Informação</FeatureTitle>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Mapeamento de dados</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Matriz de Risco</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Tabela de descarte</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Controles técnicos</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Incidente de segurança</span>
            </Feature>

            <FeatureTitle>Jurídico</FeatureTitle>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Relatório de impacto</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Gerador de política de segurança.</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>
                Gerador de aditivos contratuais <br />
                <strong>(Funcionário e Operador de dados)</strong>
              </span>
            </Feature>
          </FeatureCard>
          <FeatureCard>
            <FeatureTitle>Adequação do site</FeatureTitle>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span>Barra de cookies</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span> Gerador de política de privacidade</span>
            </Feature>
            <FeatureTitle>Treinamentos</FeatureTitle>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span> Como reconhecer um incidente de segurança</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span> Conscientização à LGPD</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span> Conceitos da Lei</span>
            </Feature>
            <Feature>
              <img src={FeatureImg} alt="" />
              <span> Atendimento aos direitos dos titulares</span>
            </Feature>
          </FeatureCard>
        </FeaturesContainer>
      </PlanContainer>
    </Container>
  );
};

export default Planos;
