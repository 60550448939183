export const MenuItems3 = [
  {
    title: "Política de privacidade",
    path: "/política-de-privacidade",
    cName: "dropdown-link",
  },
  {
    title: "Barra de cookies",
    path: "/barra-de-cookies",
    cName: "dropdown-link",
  },
];
