import React from "react";
import { perguntasMapeamentoDeDados as perguntas } from "../Dados/PerguntasMapeamentoDeDados";
import styled, { keyframes } from "styled-components";
import seta from "../imgs/Seta.png";

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
  margin-bottom: 5%;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-top: 100px;
`;

const Text = styled.p`
  font-size: 30px;
`;

const Li = styled.li`
  font-size: 30px;
`;

const BallContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  border: 2px solid red;
`;

const Ball = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px; //temporary
  height: 300px; //temporary
  padding: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  background: linear-gradient(
    90deg,
    ${(props) => props.color1} 0%,
    ${(props) => props.color2} 100%
  );
`;

const BallText = styled.span`
  font-size: 15px;
  text-align: center;
`;

const SetaContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
const Seta = styled.img`
  width: 200px;
  height: 250px;
  margin: 10 auto;
  /* border: 2px solid red; */
  rotate: ${(props) => props.degree}deg;
  transform: ${(props) => {
    if (props.mirror) {
      return "scaleX(-1)";
    } else {
      return 0;
    }
  }};
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const flipVerticalRight = keyframes`
0% {
  -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);

  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
`;

const slideTop = keyframes`
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
`;

const Card = styled.div`
  z-index: -1;
  /* background-color: #e2e2e2; */
  width: 33%;
  padding: 4%;
  border-radius: 25px;
  /* border-radius: 50px; */
  margin: 10px 50px;
  background: #fafafa;
  box-shadow: 20px 20px 60px #1504429e, -20px -20px 60px #efbeff92;

  animation: ${flipVerticalRight} 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
`;

const DataCard = styled.div`
  z-index: 6;
  width: 33%;
  padding: 4%;
  border-radius: 25px;
  /* border-radius: 50px; */
  margin: 40px auto;
  background: #fafafa;
  box-shadow: 20px 20px 60px #1504429e, -20px -20px 60px #efbeff92;
  animation: ${slideTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;

const DataCardContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const DataCardContent1 = styled.div`
  font-weight: 600;
  color: #150442;
  width: 85%;
`;
const DataCardContent2 = styled.div`
  font-weight: 600;
  color: #be00ff;
`;

const CardTitle = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 4px;
  width: fit-content;
  font-size: 20px;
  margin-bottom: 10%;
`;

const ResultadoMapeamentoDeDados = (props) => {
  function verificarAgente(primeiraPergunta, ultimaPergunta, obj) {
    let respArr = [];
    let sims = 0;
    let nPergunta1 = parseInt(primeiraPergunta.substring(3, 5));
    let nPergunta2 = parseInt(ultimaPergunta.substring(3, 5));

    for (let i = nPergunta1; i <= nPergunta2; i++) {
      respArr.push(obj[`MPR${i}`]);
    }

    for (let item of respArr) {
      if (item === "Sim") {
        sims++;
      }
    }

    if (sims >= 6) {
      return "Controlador de dados";
    } else {
      return "Não controla dados";
    }
  }

  let arrSoCodigos = [];

  //Filtra o array que vem do documento e gera so as perguntas MP
  function objFilter() {
    let xobj = {};
    for (let keys in props.dados) {
      if (keys.substring(0, 3) === "MPR") {
        arrSoCodigos.push(keys);
      }
    }
    arrSoCodigos.sort((a, b) => {
      if (parseInt(a.substring(3, 5)) > parseInt(b.substring(3, 5))) {
        return 1;
      } else return -1;
    });
    // console.log(arrSoCodigos);

    // console.log(props.dados[arrSoCodigos[0]]);
    for (const itens of arrSoCodigos) {
      xobj[itens] = props.dados[itens];
    }
    console.log(props.dados);
    console.log(xobj);
    return xobj;
  }

  return (
    <div style={{ padding: "0 150px" }}>
      <Title>MAPEAMENTO DE DADOS</Title>

      <CardContainer>
        {/* Ciclo dos dados */}
        <Card>
          <CardTitle>Ciclo dos dados</CardTitle>
          <DataCardContentContainer>
            <DataCardContent1>
              Voce coleta Nome, CPF, E-mail, Telefone... ?
            </DataCardContent1>
            <DataCardContent2>{props.dados.MPR2}</DataCardContent2>
          </DataCardContentContainer>
          <hr />
          <DataCardContentContainer>
            <DataCardContent1>
              Os dados ficam armazenados com a sua empresa?
            </DataCardContent1>
            <DataCardContent2>{props.dados.MPR3}</DataCardContent2>
          </DataCardContentContainer>
          <hr />

          <DataCardContentContainer>
            <DataCardContent1>
              Estes dados são compartilhados com:
            </DataCardContent1>
            <DataCardContent2>{props.dados.MPR4}</DataCardContent2>
          </DataCardContentContainer>
        </Card>

        {/* Agentes de tratamento */}
        <Card>
          <CardTitle>Agentes de tratamento</CardTitle>
          <div>
            <DataCardContentContainer
              style={{ textAlign: "center", flexDirection: "column" }}
            >
              <DataCardContent1 style={{ width: "100%", marginBottom: "8px" }}>
                Minha empresa → Cliente{" "}
              </DataCardContent1>
              <DataCardContent2>
                {verificarAgente("MPR5", "MPR11", objFilter())}
              </DataCardContent2>
            </DataCardContentContainer>
            <hr />

            <DataCardContentContainer
              style={{ textAlign: "center", flexDirection: "column" }}
            >
              <DataCardContent1 style={{ width: "100%", marginBottom: "8px" }}>
                Minha empresa → Fornecedor de serviço{" "}
              </DataCardContent1>
              <DataCardContent2>
                {verificarAgente("MPR12", "MPR18", objFilter())}
              </DataCardContent2>
            </DataCardContentContainer>
            <hr />

            <DataCardContentContainer
              style={{ textAlign: "center", flexDirection: "column" }}
            >
              <DataCardContent1 style={{ width: "100%", marginBottom: "8px" }}>
                Minha empresa → Funcionário
              </DataCardContent1>
              <DataCardContent2>
                {verificarAgente("MPR19", "MPR25", objFilter())}
              </DataCardContent2>
            </DataCardContentContainer>
            <hr />

            <DataCardContentContainer
              style={{ textAlign: "center", flexDirection: "column" }}
            >
              <DataCardContent1 style={{ width: "100%", marginBottom: "8px" }}>
                Minha empresa → Outra empresa fornecedora{" "}
              </DataCardContent1>
              <DataCardContent2>
                {verificarAgente("MPR26", "MPR32", objFilter())}
              </DataCardContent2>
            </DataCardContentContainer>
          </div>
        </Card>

        {/* Base legal */}
        <Card>
          <CardTitle>Base legal - Em Produção</CardTitle>
          <div>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde
            tempora pariatur labore saepe maxime nesciunt, quis suscipit tempore
            ex, incidunt odio corporis ipsa, cumque excepturi harum autem omnis
            itaque iure.
          </div>
        </Card>
      </CardContainer>
      <DataCard>
        <CardTitle>Dados coletados</CardTitle>
        <div>
          {perguntas.map((item, index) => {
            let respArr = Object.values(objFilter()); //range do render
            if (index >= 33 && index <= 57) {
              return (
                <div>
                  <DataCardContentContainer>
                    <DataCardContent1>{item.pergunta}</DataCardContent1>
                    <DataCardContent2>{respArr[index]}</DataCardContent2>
                  </DataCardContentContainer>
                  <hr />
                </div>
              );
            }
          })}
        </div>
      </DataCard>
    </div>
  );
};

export default ResultadoMapeamentoDeDados;
