export const perguntasMatrizDeRiscos = [
  {
    id: 0,
    codigo: 'MR0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta: 'Vazamento de dados da empresa - Consequencias',
  },

  {
    id: 1,
    codigo: 'MR1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta: 'Vazamento de dados da empresa  - Chances',
  },

  {
    id: 2,
    codigo: 'MR2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta: 'Vazamento de dados dos clientes- Consequencias',
  },

  {
    id: 3,
    codigo: 'MR3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta: 'Vazamento de dados dos clientes - Chances',
  },

  {
    id: 4,
    codigo: 'MR4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta: 'Vazamento de dados dos funcionários- Consequencias',
  },

  {
    id: 5,
    codigo: 'MR5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta: 'Vazamento de dados dos funcionários - Chances',
  },

  {
    id: 6,
    codigo: 'MR6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta:
      'Extravio de equipamento eletrônico(Em caso de home office)- Consequencias',
  },

  {
    id: 7,
    codigo: 'MR7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta:
      'Extravio de equipamento eletrônico(Em caso de home office) - Chances',
  },

  {
    id: 8,
    codigo: 'MR8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta: 'Invasão de equipamento(Por vírus ou malware)- Consequencias',
  },

  {
    id: 9,
    codigo: 'MR9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta: 'Invasão de equipamento(Por vírus ou malware)- Chances',
  },

  {
    id: 10,
    codigo: 'MR10',
    name: 'pergunta10',
    value: 'pergunta10',
    pergunta: 'Perda de documento por ausência de backup',
  },

  {
    id: 11,
    codigo: 'MR11',
    name: 'pergunta11',
    value: 'pergunta11',
    pergunta: 'Perda de documento por ausência de backup',
  },
];
