import React, { useState } from 'react';
import Navbar from '../../Componentes/Navbar/Navbar';
import styled from 'styled-components';
import CampoCheckBox from '../../Componentes/CampoCheckBox';
import { PerguntasAditivoDeContratoControlador as perguntas } from '../../Dados/PerguntasAditivoDeContratoOperador';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../Firebase/firebase';
import { useNavigate } from 'react-router-dom';
import CampoTexto from '../../Componentes/CampoTexto';

const neumorphismContainer = {
  borderRadius: '18px',
  background: '#ffffff',
  margin: '2% 18%',
  padding: '1%',
  boxShadow: '20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff',
};

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const SectionTitle = styled.h2`
  color: #5b0390;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;

const AditivoDeContratoControlador = () => {
  const navigate = useNavigate();
  const docRef = collection(db, 'usuarios');
  const [pergunta0, setPergunta0] = useState();
  const [pergunta1, setPergunta1] = useState();
  const [pergunta2, setPergunta2] = useState();
  const [pergunta3, setPergunta3] = useState();
  const [pergunta4, setPergunta4] = useState();
  const [pergunta5, setPergunta5] = useState();
  const [pergunta6, setPergunta6] = useState();
  const [pergunta7, setPergunta7] = useState();
  const [pergunta8, setPergunta8] = useState();
  const [pergunta9, setPergunta9] = useState();
  const [pergunta10, setPergunta10] = useState();
  const [pergunta11, setPergunta11] = useState();
  const [pergunta12, setPergunta12] = useState();

  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: pergunta0,
          [perguntas[1].codigo]: pergunta1,
          [perguntas[2].codigo]: pergunta2,
          [perguntas[3].codigo]: pergunta3,
          [perguntas[4].codigo]: pergunta4,
          [perguntas[5].codigo]: pergunta5,
          [perguntas[6].codigo]: pergunta6,
          [perguntas[7].codigo]: pergunta7,
          [perguntas[8].codigo]: pergunta8,
          [perguntas[9].codigo]: pergunta9,
          [perguntas[10].codigo]: pergunta10,
          [perguntas[11].codigo]: pergunta11,
          [perguntas[12].codigo]: pergunta12,

          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      navigate('/documento-aditivo-controlador', {
        state: getAuth().currentUser.uid,
      });
      // navigate('/documento');
    } catch (error) {
      console.log(error);
    }
  };

  const locale = 'pt-br';
  const option = {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'long' || 'short' || 'numeric',

    day: 'numeric',
  };
  return (
    <div>
      <Navbar />
      <Title>Aditivo de Contrato - Controlador para Operador</Title>
      <form onSubmit={cadastrar}>
        <div style={neumorphismContainer}>
          <SectionTitle>Preenchimento do contrato</SectionTitle>
          <p>
            Informações que você precisa fornecer para gerar o aditivo
            contratual da empresa controladora de dados (sua) para a empresa
            operadora de dados.
          </p>
          <CampoTexto
            required={true}
            label={perguntas[0].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta0(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[1].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta1(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[2].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta2(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[3].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta3(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[4].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta4(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[5].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta5(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[6].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta6(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[7].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta7(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[8].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta8(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[9].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta9(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[10].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta10(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[11].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta11(e.target.value)}
          />
          <CampoTexto
            type={'date'}
            required={true}
            label={perguntas[12].pergunta}
            // placeholder={   }
            onChange={(e) =>
              setPergunta12(
                new Date(e.target.value).toLocaleDateString(locale, option)
              )
            }
          />
        </div>
        <ButtonContainer>
          <ButtonPattern>Enviar</ButtonPattern>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default AditivoDeContratoControlador;
