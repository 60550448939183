import React from "react";
import Navbar from "../../Componentes/Navbar/Navbar";
import styled from "styled-components";

const neumorphismContainer = {
  borderRadius: "18px",
  background: "#ffffff",
  margin: "2% 18%",
  padding: "1%",
  boxShadow: "20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff",
};

const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
`;

const NeumorphismContainer2 = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 5%;
  padding: 3%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;
const SectionTitle = styled.h2`
  color: #5b0390;
  margin-bottom: 10px;
  margin-top: 0;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
  margin-top: 30px;
  margin-bottom: 5px;
`;

const Text = styled.p`
  margin: 0;
`;

const ControlesTecnicos = () => {
  return (
    <div>
      <Navbar />
      <Title>Controles Técnicos</Title>
      <NeumorphismContainer>
        <p style={{ textAlign: "center" }}>
          As informações abaixo são sugestões de ação visando a segurança dos
          dados geridos pela empresa, solicite ao departamento de T.I
          (Tecnologia da Informação) a implementação das ações abaixo, caso se
          apliquem ao seu negócio.
        </p>
        <NeumorphismContainer2>
          <SectionTitle>Software</SectionTitle>
          <Text>Utilize apenas softwares, devidamente licenciados.</Text>
        </NeumorphismContainer2>

        <NeumorphismContainer2>
          <SectionTitle>Sistema</SectionTitle>
          <Text>
            Configure o bloqueio automático de sessão nos ativos corporativos
            após um período definido de inatividade. Para sistemas operacionais
            de uso geral, o período não deve exceder 15 minutos. Para
            dispositivos móveis de usuário final, o período não deve exceder 2
            minutos.
          </Text>
          <hr />
          <Text>
            Exclua ou desabilite quaisquer contas inativas após um período de 45
            dias de inatividade, onde for suportado.
          </Text>
        </NeumorphismContainer2>

        <NeumorphismContainer2>
          <SectionTitle>Firewall</SectionTitle>
          <Text>
            Implemente e gerencie um firewall nos servidores, onde houver
            viabilidade. Exemplos de implementações incluem um firewall virtual,
            firewall do sistema operacional ou um agente de firewall de
            terceiros.
          </Text>
          <hr />
          Implemente e gerencie um firewall nos equipamentos dos usuários, ou
          uma ferramenta de filtragem de porta nos dispositivos de usuário
          final, com uma regra de negação padrão que bloqueia todo o tráfego,
          exceto os serviços e portas que são explicitamente permitidos.
        </NeumorphismContainer2>

        <NeumorphismContainer2>
          <SectionTitle>Anti-malware</SectionTitle>
          <Text>
            Instale e mantenha um software anti-malware em todos os ativos
            corporativos.
          </Text>
          <hr />
          <Text>
            Configure atualizações automáticas para arquivos de assinatura
            anti-malware em todos os ativos corporativos.
          </Text>
        </NeumorphismContainer2>

        <NeumorphismContainer2>
          <SectionTitle>Backup</SectionTitle>
          <Text>
            Execute backups automatizados de ativos corporativos dentro do
            escopo. Execute backups semanalmente ou com mais frequência, com
            base na sensibilidade dos dados.
          </Text>
          <hr />
          <Text>
            Utilize VPN - A utilização de VPN permite que seus colaboradores
            naveguem pela internet de forma anônima, camuflando o tráfego de
            dados, ou seja, é uma forma de evitar que outras pessoas interceptem
            a navegação e roubem informações da empresa.
          </Text>
        </NeumorphismContainer2>

        <NeumorphismContainer2>
          <SectionTitle>Recuperação de dados</SectionTitle>
          <Text>
            Instale um programa de recuperação de dados, segue abaixo alguns dos
            programas para a recuperação de dados mais utilizados:
          </Text>
          <hr />
          <Text>
            <SectionSubTitle>Get Data Back</SectionSubTitle> É um software
            avançado para recuperação de dados, auxiliando na tarefa de
            restaurar tabelas de partição de discos, registros de boot , master
            file table ou diretórios root corrompidos ou perdidos devido ao
            ataque de um vírus, arquivos deletados, formatações ou falta de
            energia.
          </Text>
          <Text>
            <SectionSubTitle>Handy Recovery</SectionSubTitle>É um software capaz
            de restaurar arquivos com a maior praticidade que se conhece no
            mercado. Com uma interface bastante prática e simplificada, o
            programa é capaz de recuperar arquivos de pendrives, discos rígidos,
            cartões de memória e outras formas de armazenamento de documentos.
          </Text>
          <Text>
            {" "}
            <SectionSubTitle>Remo Recover</SectionSubTitle>É uma ferramenta que
            ajuda você a recuperar arquivos perdidos em seu computador. É
            possível vasculhar cada pasta e subpasta dos seus discos rígidos e
            conferir os rastros de documentos apagados, mas que ainda estão
            podem ser acessados de alguma forma. Assim, você inicia o
            escaneamento e, em alguns minutos, todos os arquivos apagados que
            ainda podem ser recuperados aparecerão organizados em seus devidos
            diretórios.
          </Text>
          <Text>
            <SectionSubTitle>Ontrack Easy Recovery</SectionSubTitle>É a solução
            completa para recuperação de dados, reparo de arquivos e diagnóstico
            de necessidades do disco.
          </Text>
        </NeumorphismContainer2>

        <NeumorphismContainer2>
          <SectionTitle>Plano de emergência</SectionTitle>
          <Text>
            Desenvolva um plano de emergência e pratique restaurar os seus dados
            em caso de problemas. O plano de emergência deve incluir, pelo
            menos:
            <br />
            Localizar todos os backups existentes, incluindo datas e tipos de
            backup.
          </Text>
          <hr />
          <Text>
            Listar e localizar todos os pacotes de software originais,
            detalhando as atualizações desde a instalação original.
          </Text>
          <Text>
            Localizar e deixar à disposição um computador alternativo.
          </Text>
        </NeumorphismContainer2>
      </NeumorphismContainer>
    </div>
  );
};

export default ControlesTecnicos;
