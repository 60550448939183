import React from "react";
import { PerguntasAditivoDeContratoControlador as perguntas } from "../Dados/PerguntasAditivoDeContratoOperador";
import styled from "styled-components";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import sourceArial from "../Fonts/arial.ttf";
import sourceArialBold from "../Fonts/arialbd.ttf";

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 50px;
  text-align: center;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-top: 100px;
`;

const Li = styled.li`
  font-size: 30px;
`;
const styles = StyleSheet.create({
  page: {
    marginTop: 20,
    fontFamily: "Arial",
    width: "100%",
  },
  titulo: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: "18px",
    textAlign: "center",
    paddingLeft: 30,
    paddingRight: 30,
    // fontWeight: '900',
  },
  section: {
    fontFamily: "Arial",
    fontSize: 12,
    marginBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: "justify",
  },
  p: {
    marginTop: 5,
    marginBottom: 5,
    textIndent: 30,
  },
  topico: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
    fontWeight: "800",
    fontSize: 14,
  },
  lista: {
    fontWeight: "800",
    textAlign: "center",
  },
  recuo: {
    marginLeft: 30,
    fontWeight: "800",
  },
  recuoItens: {
    marginLeft: 50,
  },
});
const ResultadoAditivoControlador = (props) => {
  Font.register({
    family: "Arial",
    fonts: [
      {
        src: sourceArial,
      },
      {
        src: sourceArialBold,
        fontWeight: "bold",
      },
    ],
  });

  const MyDocument = () => (
    <Document style={{ width: "100%" }}>
      <Page size="A4" style={styles.page}>
        <View wrap={false}>
          <Text style={styles.titulo}>
            Termo de Consentimento para Tratamento de Dados Pessoais {"\n"} Lei
            Geral de Proteção de Dados Pessoais – LGPD
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.p}>
            {`TERMO ADITIVO AO CONTRATO DE ${props.dados.ADCTR0} QUE ENTRE SI CELEBRAM ENTRE SI: A EMPRESA (controladora dos dados)${props.dados.ADCTR1}, sob CNPJ nº ${props.dados.ADCTR2}, aqui representada por${props.dados.ADCTR3} , nome ${props.dados.ADCTR4}, sob CPF nº ${props.dados.ADCTR5} e a empresa (operadora de dados), ${props.dados.ADCTR6}, sob CNPJ nº ${props.dados.ADCTR7} , aqui representada por ${props.dados.ADCTR8}, nome ${props.dados.ADCTR9} , sob CPF nº${props.dados.ADCTR10}, mediante as cláusulas a seguir: `}
          </Text>
          <Text style={styles.p}>
            As partes se comprometem a resguardar as liberdades fundamentais
            relativas ao tratamento de dados pessoais e ao livre desenvolvimento
            da personalidade do indivíduo inclusive aos portadores de dados
            digitais, nos termos da lei Geral de Proteção de Dados - LGPD (lei
            nº 13.709 de agosto de 2018).
          </Text>
          <Text style={styles.p}>
            PARÁGRAFO ÚNICO: O tratamento de dados pessoais será realizado de
            acordo com as bases legais previstas nos casos dos artigos 7º, 11
            e/ou 14 da lei 13.709/2018 a que estarão sujeitos os serviços, e
            para fins legítimos e específicos, intentos explícitos e relatados
            ao proprietário.
          </Text>
          <Text style={styles.p}>
            A Contratada deverá assegurar a proteção confidencialidade e
            confidencialidade de todas as informações dados pessoais e bancos de
            dados acessados ​​dentro das disposições, alterações e
            regulamentações ulteriores da LGPD durante a execução das
            finalidades especificadas nos documentos contratuais.
          </Text>
          <Text style={styles.p}>
            PARÁGRAFO PRIMEIRO: A operadora não poderá utilizar as informações
            dados pessoais ou bancos de dados a que tenham acesso, para
            finalidade diversa da prestação dos serviços especificados no
            instrumento contratual.
          </Text>
          <Text style={styles.p}>
            PARÁGRAFO SEGUNDO: havendo a necessidade de coleta de dados pessoais
            dos titulares mediante consentimento, indispensáveis para a
            prestação do serviço propriamente dito, esta será realizada mediante
            prévia anuência do controlador, sendo responsável para obtenção e
            gerenciamento, você está proibido de compartilhar esses dados com
            terceiros;
          </Text>
          <Text style={styles.p}>
            O operador obriga-se a implementar medidas técnicas e
            administrativas que possam promover a segurança, proteção,
            confidencialidade e sigilo de toda a informação, dados pessoais e/ou
            bases de dados de que disponha, para prevenir acessos não
            autorizados, acidentes não intencionais ou ilegais. vazamentos que
            resultem em destruição, perda, alteração, transferência ou outro
            processamento inadequado ou ilegal; o objetivo de tudo isso é
            reduzir o risco a que está exposto o objeto do contrato.
          </Text>
          <Text style={styles.p}>
            PARÁGRAFO ÚNICO: A critério da empresa controladora, a operadora
            poderá ser convidada a participar da elaboração de relatório de
            impacto de acordo com a sensibilidade e risco inerente aos dados
            pessoais do serviço objeto deste contrato.
          </Text>
          <Text style={styles.p}>
            O operador deve sempre manter registros do processamento de dados
            pessoais e do processamento de dados pessoais compartilhados,
            juntamente com condições rastreáveis ​​e evidências eletrônicas.
          </Text>
          <Text style={styles.p}>
            O operador é responsável por garantir que todos os seus
            funcionários, consultores e/ou prestadores de serviços que tenham
            acesso e/ou tomem conhecimento de dados e/ou dados pessoais durante
            suas atividades cumpram suas obrigações de proteção,
            confidencialidade e sigilo, que devem assumir compromisso formal de
            manter a confidencialidade e segurança de tais informações.
          </Text>
          <Text style={styles.p}>
            Sem autorização por escrito, não é permitido ao operador ceder a
            terceiros informações, dados pessoais ou base de dados a que tenha
            acesso para a execução do objeto do presente contrato.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.p}>
            O operador deve elaborar planos de resposta a falhas de segurança de
            dados que possam ocorrer durante o tratamento dos dados recolhidos
            para efeitos do presente acordo, e dispor de mecanismos que permitam
            a sua correção para evitar ou minimizar danos aos dados.
          </Text>
          <Text style={styles.p}>
            O operador deve notificar formal e imediatamente o controlador de
            todos os riscos, ameaças ou incidentes de segurança que possam
            causar situações perigosas ou danos potenciais ou reais ao titular
            dos dados pessoais, evitando atrasos decorrentes de controles ou
            inspeções.
          </Text>
          <Text style={styles.p}>
            Quando o contrato expirar ou quando a finalidade pretendida for
            cumprida, o operador deixa de processar os dados pessoais fornecidos
            pelo processador responsável e no prazo máximo de 30 dias de acordo
            com as instruções e na medida especificada. eliminará completamente
            os Dados Pessoais e todas as cópias existentes (seja em formato
            digital ou físico), a menos que o operador seja obrigado a manter os
            dados para cumprir a sua obrigação legal.
          </Text>
          <Text style={styles.p}>
            O operador obriga-se a assumir total responsabilidade e a compensar
            todos os danos e/ou prejuízos, incluindo sanções aplicadas pela
            autoridade nacional, resultantes do tratamento insuficiente dos
            dados pessoais partilhados pelo responsável pelo tratamento para as
            finalidades especificadas no presente acordo.
          </Text>

          <Text
            style={([styles.p], { textAlign: "center", marginTop: 10 })}
          >{`${props.dados.ADCTR11}, ${props.dados.ADCTR12}. `}</Text>
          <Text style={([styles.p], { textAlign: "center", marginTop: 10 })}>
            {` PELA CONTROLADORA DE DADOS: _________________
              CNPJ Nº: ${props.dados.ADCTR2}
            `}
          </Text>

          <Text style={([styles.p], { textAlign: "center", marginTop: 10 })}>
            PELA OPERADORA DE DADOS: {props.dados.ADCTR7}{" "}
          </Text>
        </View>
      </Page>
    </Document>
  );
  return (
    <Container>
      <PDFViewer style={{ width: "100%", height: "100%" }}>
        <MyDocument />
      </PDFViewer>
    </Container>
  );
};

export default ResultadoAditivoControlador;
