import React from "react";
import imagem from "../../imgs/idv images/teste.png";
import "./CardDocumento.css";
import { blue } from "@mui/material/colors";

const CardDocumento = (props) => {
  return (
    <div
      className="main-document-card"
      style={{
        backgroundColor: props.finished ? "" : "#E6E0F6",
      }}
    >
      <img className="document-card-image" src={props.src} alt="img" />
      {/* <h3 className="document-card-review">Ultima Revisão</h3> */}
      {/* <h4 className="document-card-review-date">{props.date}</h4> */}
      <p className="document-card-title">{props.title}</p>
    </div>
  );
};

export default CardDocumento;
