import React from "react";
import Navbar from "../../Componentes/Navbar/Navbar";
import styled from "styled-components";

const neumorphismContainer = {
  borderRadius: "18px",
  background: "#ffffff",
  margin: "2% 18%",
  padding: "1%",
  boxShadow: "20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff",
};

const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
`;

const ButtonPattern = styled.button`
  width: 250px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
  font-family: "Montserrat", "sans-serif";
  font-size: 15px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    scale: 1.05;
  }
`;
const ButtonPattern2 = styled.button`
  width: 250px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  font-family: "Montserrat", "sans-serif";
  font-size: 15px;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    scale: 1.05;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 70%;
  z-index: -10;
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;
const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const IncidenteDeSeguranca = () => {
  return (
    <div>
      <Navbar />
      <Title>Incidente de Seguranca</Title>
      <NeumorphismContainer>
        <SectionTitle>Notificar sobre o vazamento dos dados</SectionTitle>
        <p>
          Precisa ou não notificar a ANPD (Autoridade Nacional de Proteção de
          Dados) e os titulares dos dados? Assista o vídeo e aprenda como
          avaliar a situação.
        </p>
        <iframe
          width="100%"
          height="550"
          src="https://www.youtube.com/embed/tkrgwRYuZQw"
          title="Incidente de segurança"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <ButtonContainer>
          <a download href={require("./formulario-anpd.docx")}>
            <ButtonPattern>Formulário de notificação ANPD</ButtonPattern>
          </a>
          <a download href={require("./formulario-aviso-titulares.docx")}>
            <ButtonPattern2>
              Formulário de notificação dos titulares
            </ButtonPattern2>
          </a>
        </ButtonContainer>
      </NeumorphismContainer>
    </div>
  );
};

export default IncidenteDeSeguranca;
