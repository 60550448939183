import React, { useEffect, useState } from 'react';
import './Termometro.css';
import { ReactComponent as Circle } from './circle.svg';
import styled, { keyframes } from 'styled-components';
import { render } from '@testing-library/react';
import { cardArray } from '../Pages/cardArray.js';

let rotate;
export const ProgressBar = styled.circle`
  background-color: ${(props) => props.bgcolor};
  animation: ${(props) => {
      return (rotate = keyframes`
  100% { stroke-dashoffset: ${props.progressvalue};}
`);
    }}
    ${(props) => props.animationlength} linear forwards;
`;

const Termometro = () => {
  const [counter, setCounter] = useState(0);
  let xCounter = 0;

  let finisheds = 0;
  cardArray.map((item, index) => {
    if (item.finished) {
      finisheds++;
    }
  });

  const percent = Math.round((finisheds * 100) / 8);
  const strokeSize = Math.round(4.5 * (100 - percent));

  useEffect(() => {
    const interval = setInterval(() => {
      if (xCounter < percent) {
        xCounter++;
        setCounter(xCounter);
      }
    }, 30);

    return () => {
      clearInterval(interval);
    };
  }, [strokeSize]);

  return (
    <div className='main-termometer-content'>
      <div className='skill'>
        <div className='outer'>
          <div className='inner'>
            <div className='progress-number'>{counter}%</div>
          </div>
        </div>

        <svg
          className='circle'
          xmlns='http://www.w3.org/2000/svg'
          version='1.1'
          width='160px'
          height='160px'
        >
          <defs>
            <linearGradient id='GradientColor'>
              <stop offset='0%' stopColor='#ffffff' />
              <stop offset='100%' stopColor='#d3a7fa' />
            </linearGradient>
          </defs>
          <ProgressBar
            cx='80'
            cy='80'
            r='70'
            strokeLinecap='round'
            bgcolor={'red'}
            animationlength={`${0.03 * percent}s`}
            progressvalue={strokeSize}
          ></ProgressBar>
        </svg>
      </div>
    </div>
  );
};

export default Termometro;
