export const PerguntasAditivoDeContratoClt = [
  {
    id: 0,
    codigo: 'ADCLT0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta: 'Nome do funcionário:',
  },
  {
    id: 1,
    codigo: 'ADCLT1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta: 'CPF do funcionário:',
  },

  {
    id: 2,
    codigo: 'ADCLT2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta: 'Nome da empresa:',
  },
  {
    id: 3,
    codigo: 'ADCLT3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta: 'CNPJ da empresa:',
  },
  {
    id: 4,
    codigo: 'ADCLT4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta: 'Cidade onde o contrato será assinado:',
  },

  {
    id: 5,
    codigo: 'ADCLT5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta: 'Nome completo',
  },

  {
    id: 6,
    codigo: 'ADCLT6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta: 'Data de nascimento',
  },
  {
    id: 7,
    codigo: 'ADCLT7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta: 'Número e imagem da Carteira de Identidade (RG)',
  },
  {
    id: 8,
    codigo: 'ADCLT8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta: 'Número e imagem do Cadastro de Pessoas Físicas (CPF)',
  },
  {
    id: 9,
    codigo: 'ADCLT9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta: 'Número e imagem do Programa de Integração Social (PIS)',
  },
  {
    id: 10,
    codigo: 'ADCLT10',
    name: 'pergunta10',
    value: 'pergunta10',
    pergunta: 'Carteira de trabalho física ou digital',
  },
  {
    id: 11,
    codigo: 'ADCLT11',
    name: 'pergunta11',
    value: 'pergunta11',
    pergunta: 'Fotografia 3x4  ',
  },

  {
    id: 12,
    codigo: 'ADCLT12',
    name: 'pergunta12',
    value: 'pergunta12',
    pergunta: 'Imagem da Certidão de Casamento ou Declaração de União Estável',
  },

  {
    id: 13,
    codigo: 'ADCLT13',
    name: 'pergunta13',
    value: 'pergunta13',
    pergunta: 'Endereço completo',
  },

  {
    id: 14,
    codigo: 'ADCLT14',
    name: 'pergunta14',
    value: 'pergunta14',
    pergunta: 'Números de telefone, WhatsApp ou endereços de e-mail',
  },
  {
    id: 15,
    codigo: 'ADCLT15',
    name: 'pergunta15',
    value: 'pergunta15',
    pergunta: 'Banco, agência e número de contas bancárias',
  },
  {
    id: 16,
    codigo: 'ADCLT16',
    name: 'pergunta16',
    value: 'pergunta16',
    pergunta:
      'Comunicação, verbal e escrita, mantida entre o Titular e o Controlador    ',
  },
  {
    id: 17,
    codigo: 'ADCLT17',
    name: 'pergunta17',
    value: 'pergunta17',
    pergunta:
      'Exames e atestados médicos, especialmente admissionais, periódicos, incluídos de retorno por afastamento superior a 30 dias em caso de doença, acidente ou parto, de mudança de função, demissionais e ainda aqueles que atestem doença ou acidente    ',
  },
  {
    id: 18,
    codigo: 'ADCLT18',
    name: 'pergunta18',
    value: 'pergunta18',
    pergunta: 'Sua empresa utiliza câmera de segurança    ',
  },
];
