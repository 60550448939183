import React from "react";
import styled from "styled-components";
import Img from "../../imgs/comofunciona.png";
import icon1 from "../../imgs/shopping-cart.png";
import icon2 from "../../imgs/exam.png";
import icon3 from "../../imgs/confirmation (1).png";
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 180px;
  scroll-margin-top: 150px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* width: 60%; */
  padding: 0 10%;
  align-items: center;

  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
  }
`;

const MainImg = styled.img`
  margin: 0 auto;
  width: 100%;
  max-width: 495px;
  min-width: 275px;

  @media screen and (max-width: 768px) {
    width: 10%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SectionTitle = styled.h2`
  font-weight: 300;
  color: #be00ff;
  font-size: 20px;
  text-align: center;
  margin: 0;
`;
const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  margin: 18px 0;
`;

const IconsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
`;

const Img1 = styled.img`
  animation: popImg 0.7s forwards;

  @keyframes popImg {
    0% {
      visibility: visible;

      display: block;
    }
    80% {
      scale: 1.2;
    }
    100% {
      scale: 1;
      visibility: visible;
    }
  }
`;
const Img2 = styled.img`
  animation: popImg 0.7s forwards;

  @keyframes popImg {
    0% {
      visibility: visible;
      display: block;
    }
    80% {
      scale: 1.2;
    }
    100% {
      scale: 1;
      visibility: visible;
    }
  }
`;
const Img3 = styled.img`
  animation: popImg 0.7s forwards;

  @keyframes popImg {
    0% {
      visibility: visible;

      display: block;
    }
    80% {
      scale: 1.2;
    }
    100% {
      scale: 1;
      visibility: visible;
    }
  }
`;

const Description = styled.p`
  color: #5b0390;
  /* width: 80%; */
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    width: 80px;

    @media screen and (max-width: 768px) {
      width: 60px;
    }
  }
`;

const IconTitle = styled.h4`
  font-weight: 500;
  color: #5b0390;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 0.75em;
  }
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
`;

const Ball = styled.div`
  background-color: #5b0390;
  min-width: 15px;
  min-height: 15px;
  visibility: hidden;
  border-radius: 50%;
  animation: pop 0.7s forwards;

  @keyframes pop {
    0% {
      visibility: visible;

      display: block;
      scale: 0;
    }
    80% {
      scale: 1.2;
    }
    100% {
      scale: 1;
      visibility: visible;
    }
  }
`;
const Line = styled.div`
  background-color: #5b0390;
  /* width: 80%; */
  height: 4px;
  animation: lineprogress 1.5s forwards;
  visibility: hidden;
  rotate: -180deg;
  @keyframes lineprogress {
    0% {
      width: 0%;
      visibility: visible;
    }
    80% {
      width: 80%;
    }
    100% {
      width: 100%;
      visibility: visible;
    }
  }
`;

const ComoFunciona = (props) => {
  return (
    <Container ref={props.reference}>
      <GridContainer>
        <MainImg src={Img} />
        <ContentContainer>
          <SectionTitle>
            <em>COMO FUNCIONA?</em>
          </SectionTitle>
          <Title>Nunca foi tão fácil se adequar a LGPD. </Title>
          <IconsContainer>
            <Icon>
              <Img1 src={icon1}></Img1>
              <IconTitle>
                <em>Adquira</em>
              </IconTitle>
            </Icon>
            <LineContainer>
              <Ball></Ball>
              <Line></Line>
              <Ball style={{ animationDelay: "1.5s" }}></Ball>
            </LineContainer>
            <Icon>
              <Img2 style={{ animationDelay: "2.4s" }} src={icon2}></Img2>
              <IconTitle>
                <em>Preencha o formulário</em>
              </IconTitle>
            </Icon>
            <LineContainer>
              <Ball style={{ animationDelay: "3s" }}></Ball>
              <Line style={{ animationDelay: "3.5s" }}></Line>
              <Ball style={{ animationDelay: "5s" }}></Ball>
            </LineContainer>
            <Icon>
              <Img3 style={{ animationDelay: "6s" }} src={icon3}></Img3>
              <IconTitle>
                <em>Adequado!</em>
              </IconTitle>
            </Icon>
          </IconsContainer>
          <Description>
            <em>
              Todas as partes do nosso sistema foram criadas para ajudar a sua
              empresa a se adequar facilmente. Basta responder alguns
              formulários e o nosso programa prepara os documentos necessários
              para voce se adequar a LGPD.
            </em>
          </Description>
          <Description>
            <em>
              Durante todo o processo, um medidor mostra o quanto você progrediu
              em direção aos 100% de adequação. Quando alcançar a adequação, o
              <strong> LGPD●LOCK</strong> ira te lembrar de manter os documentos
              atualizados regularmente. Não se preocupe! Estamos aqui para te
              ajudar com isso!
            </em>
          </Description>
        </ContentContainer>
      </GridContainer>
    </Container>
  );
};

export default ComoFunciona;
