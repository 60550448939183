import React, { useState } from "react";
import Navbar from "../../Componentes/Navbar/Navbar";
import styled from "styled-components";
import ListaSuspensa from "../../Componentes/ListaSuspensa";
import { db } from "../../Firebase/firebase";
import { getAuth } from "firebase/auth";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router";
import { perguntasMatrizDeRiscos as perguntas } from "../../Dados/PerguntasMatrizDeRisco";

const Container = styled.div`
  /* display: flex;
flex-direction: column;
align-items: center; */
`;

const Linha = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 20px 20px 100px #d8d7d7ab, -20px -20px 100px #ffffff;
  margin: 2% 0;
  padding: 10px;
  padding-left: 20px;
`;

const Perguntas = styled.div`
  /* border: 2px solid red; */
`;

const Alternativas = styled.div`
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
  max-width: 550px;
  min-width: 550px;
`;

const CaixaTitulo = styled.div`
  margin: 0 20px;
`;

const Subtitulo = styled.p`
  font-size: 10px;
`;

const neumorphismContainer = {
  borderRadius: "18px",
  background: "#ffffff",
  margin: "2% 18%",
  padding: "1%",
  boxShadow: "20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff",
};

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;

const MatrizDeRisco = () => {
  const navigate = useNavigate();
  const [pergunta0, setPergunta0] = useState(0);
  const [pergunta1, setPergunta1] = useState(0);
  const [pergunta2, setPergunta2] = useState(0);
  const [pergunta3, setPergunta3] = useState(0);
  const [pergunta4, setPergunta4] = useState(0);
  const [pergunta5, setPergunta5] = useState(0);
  const [pergunta6, setPergunta6] = useState(0);
  const [pergunta7, setPergunta7] = useState(0);
  const [pergunta8, setPergunta8] = useState(0);
  const [pergunta9, setPergunta9] = useState(0);
  const [pergunta10, setPergunta10] = useState(0);
  const [pergunta11, setPergunta11] = useState(0);

  const docRef = collection(db, "usuarios");

  const cadastrar = async (e) => {
    e.preventDefault();
    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: pergunta0,
          [perguntas[1].codigo]: pergunta1,
          [perguntas[2].codigo]: pergunta2,
          [perguntas[3].codigo]: pergunta3,
          [perguntas[4].codigo]: pergunta4,
          [perguntas[5].codigo]: pergunta5,
          [perguntas[6].codigo]: pergunta6,
          [perguntas[7].codigo]: pergunta7,
          [perguntas[8].codigo]: pergunta8,
          [perguntas[9].codigo]: pergunta9,
          [perguntas[10].codigo]: pergunta10,
          [perguntas[11].codigo]: pergunta11,
          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      // navigate('/documento', { state: getAuth().currentUser.uid });
      navigate("/documento-matriz-risco");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <Title>Matriz de Risco</Title>
      <form onSubmit={cadastrar}>
        <div style={neumorphismContainer}>
          <SectionTitle>Classificação de risco</SectionTitle>
          <Container>
            <Linha>
              <Perguntas>
                <p>Riscos</p>
              </Perguntas>
              <Alternativas>
                <CaixaTitulo>
                  <SectionSubTitle>Consequências negativas</SectionSubTitle>
                  <Subtitulo>
                    De acordo com o tipos de dados gerenciados
                  </Subtitulo>
                </CaixaTitulo>
                <CaixaTitulo>
                  <SectionSubTitle>Chances de acontecer </SectionSubTitle>
                  <Subtitulo> Baseado em quantas vezes já aconteceu </Subtitulo>
                </CaixaTitulo>
              </Alternativas>
            </Linha>
            <Linha>
              <Perguntas>
                <p>Vazamento de dados da empresa</p>
              </Perguntas>
              <Alternativas>
                <ListaSuspensa onChange={(e) => setPergunta0(e.target.value)} />
                <ListaSuspensa onChange={(e) => setPergunta1(e.target.value)} />
              </Alternativas>
            </Linha>
            <Linha>
              <Perguntas>
                <p>Vazamento de dados dos clientes</p>
              </Perguntas>
              <Alternativas>
                <ListaSuspensa onChange={(e) => setPergunta2(e.target.value)} />
                <ListaSuspensa onChange={(e) => setPergunta3(e.target.value)} />
              </Alternativas>
            </Linha>
            <Linha>
              <Perguntas>
                <p>Vazamento de dados dos funcionários</p>
              </Perguntas>
              <Alternativas>
                <ListaSuspensa onChange={(e) => setPergunta4(e.target.value)} />
                <ListaSuspensa onChange={(e) => setPergunta5(e.target.value)} />
              </Alternativas>
            </Linha>
            <Linha>
              <Perguntas>
                <span>Extravio de equipamento eletrônico</span>
                <Subtitulo> (Em caso de home office)</Subtitulo>
              </Perguntas>
              <Alternativas>
                <ListaSuspensa onChange={(e) => setPergunta6(e.target.value)} />
                <ListaSuspensa onChange={(e) => setPergunta7(e.target.value)} />
              </Alternativas>
            </Linha>
            <Linha>
              <Perguntas>
                <span>Invasão de equipamento</span>
                <Subtitulo> (Por vírus ou malware)</Subtitulo>
              </Perguntas>
              <Alternativas>
                <ListaSuspensa onChange={(e) => setPergunta8(e.target.value)} />
                <ListaSuspensa onChange={(e) => setPergunta9(e.target.value)} />
              </Alternativas>
            </Linha>
            <Linha>
              <Perguntas>
                <p>Perda de documento por ausência de backup</p>
              </Perguntas>
              <Alternativas>
                <ListaSuspensa
                  onChange={(e) => setPergunta10(e.target.value)}
                />
                <ListaSuspensa
                  onChange={(e) => setPergunta11(e.target.value)}
                />
              </Alternativas>
            </Linha>
          </Container>
          <p>
            Ao clicar em calcular os riscos o sistema gera os risco colocando-os
            em ordem de prioridade e apresenta as ações para mitigar o risco
          </p>
        </div>
        <ButtonContainer>
          <ButtonPattern>Enviar</ButtonPattern>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default MatrizDeRisco;
