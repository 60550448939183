import React, { useState } from 'react';
import Navbar from '../../Componentes/Navbar/Navbar';
import styled from 'styled-components';
import CampoCheckBox from '../../Componentes/CampoCheckBox';
import { perguntasPoliticaDeSegurancaDaInformacao as perguntas } from '../../Dados/PerguntasPoliticaDeSegurancaDaInformacao';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../Firebase/firebase';
import { useNavigate } from 'react-router-dom';
import CampoTexto from '../../Componentes/CampoTexto';

const neumorphismContainer = {
  borderRadius: '18px',
  background: '#ffffff',
  margin: '2% 18%',
  padding: '1%',
  boxShadow: '20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff',
};

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;

const locale = 'pt-br';
const option = {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'long' || 'short' || 'numeric',

  day: 'numeric',
};

const PoliticaDeSeguranca = () => {
  const navigate = useNavigate();
  const docRef = collection(db, 'usuarios');
  const [pergunta7, setPergunta7] = useState();
  const [pergunta8, setPergunta8] = useState();
  const [pergunta9, setPergunta9] = useState();
  const [arrayDeRespostas, setArrayDeRespostas] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const adicionaResposta = (posicao) => {
    console.log(arrayDeRespostas);
    const updatedCheckedState = arrayDeRespostas.map((item, index) =>
      index === posicao ? !item : item
    );

    setArrayDeRespostas(updatedCheckedState);
  };

  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: arrayDeRespostas[0] ? 'Sim' : 'Não',
          [perguntas[1].codigo]: arrayDeRespostas[1] ? 'Sim' : 'Não',
          [perguntas[2].codigo]: arrayDeRespostas[2] ? 'Sim' : 'Não',
          [perguntas[3].codigo]: arrayDeRespostas[3] ? 'Sim' : 'Não',
          [perguntas[4].codigo]: arrayDeRespostas[4] ? 'Sim' : 'Não',
          [perguntas[5].codigo]: arrayDeRespostas[5] ? 'Sim' : 'Não',
          [perguntas[6].codigo]: arrayDeRespostas[6] ? 'Sim' : 'Não',
          [perguntas[7].codigo]: pergunta7,
          [perguntas[8].codigo]: pergunta8,
          [perguntas[9].codigo]: pergunta9,

          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      navigate('/documento-politica-seguranca', {
        state: getAuth().currentUser.uid,
      });
      // navigate('/documento');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <Title>Política de Segurança da Informação</Title>

      <form onSubmit={cadastrar}>
        <div style={neumorphismContainer}>
          <SectionTitle>Informação</SectionTitle>
          <p>
            Política de Segurança da Informação - é para uso interno - todo
            mundo dentro da sua empresa precisa entender o que está nesta
            Política.
          </p>
          {perguntas.map((item, index) => {
            if (index >= 0 && index < 7) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={() => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}
          <CampoTexto
            required={true}
            label={perguntas[7].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta7(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[8].pergunta}
            // placeholder={    }
            onChange={(e) => setPergunta8(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[9].pergunta}
            type={'date'}
            // placeholder={    }
            onChange={(e) =>
              setPergunta9(
                new Date(e.target.value).toLocaleDateString(locale, option)
              )
            }
          />
          Ao preencher, gerar um documento que irá utilizar o sim ou não para o
          preenchimendo da política de seguranca.
        </div>
        <ButtonContainer>
          <ButtonPattern>Enviar</ButtonPattern>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default PoliticaDeSeguranca;
