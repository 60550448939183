export const perguntasPoliticaDeSegurancaDaInformacao = [
  {
    id: 0,
    codigo: 'PSI0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta: 'Seus funcionários acessam os dados da empresa de forma remota.',
  },
  {
    id: 1,
    codigo: 'PSI1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta:
      'Fora seus funcionários/colaboradores, terceiros têm acesso remoto aos dados da empresa.',
  },
  {
    id: 2,
    codigo: 'PSI2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta:
      'Seus funcionários usam equipamentos eletrônicos pessoais para trabalhar',
  },
  {
    id: 3,
    codigo: 'PSI3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta:
      'Sua empresa utiliza ferramenta de proteção contra códigos maliciosos.',
  },
  {
    id: 4,
    codigo: 'PSI4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta:
      'Sua empresa fornece serviço de e-mail corporativo para os funcionários/colaboradores.',
  },
  {
    id: 5,
    codigo: 'PSI5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta:
      'Sua empresa fornece celular corporativo para os funcionários/colaboradores.',
  },
  {
    id: 6,
    codigo: 'PSI6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta:
      'Sua empresa fornece acesso à Internet aos seus funcionários/colaboradores.',
  },
  {
    id: 7,
    codigo: 'PSI7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta:
      'Nome da pessoa que aprovará a Política de Segurança da Informação:',
  },
  {
    id: 8,
    codigo: 'PSI8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta:
      'Cargo da pessoa que aprovará a Política de Segurança da Informação:',
  },
  {
    id: 9,
    codigo: 'PSI9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta: 'Data em que a Política foi ou será aprovada:',
  },
];
