export const perguntasRelatorioDeImpacto = [
  //Introducao
  //O escopo deste RIPD abrange todas as atividades da empresa que envolvem o tratamento de dados pessoais, incluindo:
  {
    id: 0,
    codigo: 'RI0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta: 'Coleta',
  },
  {
    id: 1,
    codigo: 'RI1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta: 'Armazenamento',
  },
  {
    id: 2,
    codigo: 'RI2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta: 'Processamento',
  },
  {
    id: 3,
    codigo: 'RI3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta: 'Uso e compartilhamento de dados',
  },
  //----------------------------------------------------------------------------
  //Identificação de dados pessoais
  //Foram identificados os seguintes tipos de dados pessoais tratados pela empresa:
  {
    id: 4,
    codigo: 'RI4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta: 'Nome completo',
  },
  {
    id: 5,
    codigo: 'RI5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta: 'Endereço',
  },
  {
    id: 6,
    codigo: 'RI6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta: 'Número de telefone',
  },
  {
    id: 7,
    codigo: 'RI7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta: 'E-mail',
  },
  {
    id: 8,
    codigo: 'RI8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta: 'Documento de identidade',
  },
  //----------------------------------------------------------------------------------------
  //Medidas de Mitigação de Riscos
  //A empresa adotou as seguintes medidas de mitigação de riscos:
  {
    id: 9,
    codigo: 'RI9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta:
      'Implantação de um programa de segurança da informação, com medidas de segurança física e lógica',
  },
  {
    id: 10,
    codigo: 'RI10',
    name: 'pergunta10',
    value: 'pergunta10',
    pergunta:
      'Treinamento dos colaboradores sobre a LGPD e boas práticas de segurança da informação',
  },
  {
    id: 11,
    codigo: 'RI11',
    name: 'pergunta11',
    value: 'pergunta11',
    pergunta:
      'Adoção de políticas de retenção de dados para garantir a eliminação de dados pessoais após o fim do período de armazenamento necessário',
  },
  {
    id: 12,
    codigo: 'RI12',
    name: 'pergunta12',
    value: 'pergunta12',
    pergunta:
      'Adoção de procedimentos de auditoria interna para identificar e corrigir eventuais falhas na segurança da informação',
  },
];
