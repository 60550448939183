import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import logo from "../imgs/idv images/logo-branco-info.png";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginInvalido, setLoginInvalido] = useState(false);

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/painel");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setLoginInvalido(true);
        removeAviso();
      });
  };

  const removeAviso = () => {
    setTimeout(() => {
      setLoginInvalido(false);
    }, 3000);
  };

  return (
    <>
      <div className="login-container">
        <div className="main-login-component">
          <div className="login-section">
            <p className="sign-in">Sign in</p>
            <form className="main-login-form">
              <label htmlFor="email-address"></label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password"></label>
              <input
                id="password"
                name="password"
                type="password"
                required
                placeholder="Senha"
                onChange={(e) => setPassword(e.target.value)}
              />

              <button className="login-button" onClick={onLogin}>
                Entrar
              </button>
            </form>
            <a href="">Esqueceu sua senha ?</a>
            {loginInvalido && <p>Login Inválido!</p>}
          </div>
          <div className="contract-section">
            <div className="cantract-section-main-content">
              <p className="salute">Olá, RH!</p>
              <p className="salute-info">
                Contrate agora para acessar todas as funcionalidades do gerador!
              </p>
              <div className="contract-buttons">
                <button className="contract-button">Contrate</button>
                <button className="test-button">Testar</button>
              </div>
            </div>
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
