import React from "react";
import { perguntasTabelaDeDescarte as perguntas } from "../Dados/PerguntasTabelaDeDescarte";
import styled from "styled-components";

const Container = styled.div`
  padding: 0 150px;
  font-family: "Montserrat", "sans-serif";
  color: #150442;
`;
const TabelaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 3px solid #150442;
  border-radius: 25px;
`;

const Celula = styled.div`
  font-family: "Montserrat", "sans-serif";
  border: 1px solid #be00ff;
  border-top: ${(props) => (props.top ? props.top : "1px")} solid #be00ff;
  border-right: ${(props) => (props.right ? props.right : "1px")} solid #be00ff;
  border-bottom: ${(props) => (props.bottom ? props.bottom : "1px")} solid
    #be00ff;
  border-left: ${(props) => (props.left ? props.left : "1px")} solid #be00ff;

  padding: 2%;
  font-weight: ${(props) => (props.strong ? 600 : "auto")};
  text-align: ${(props) => (props.center ? "center" : "auto")};

  strong {
    color: #be00ff;
  }

  li {
    font-size: 15px;
  }
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-top: 100px;
`;

const Text = styled.p`
  font-size: 30px;
`;

const Li = styled.li`
  font-size: 30px;
`;

const ResultadoTabelaDeDescarte = (props) => {
  console.log(props.dados);

  return (
    <Container style={{}}>
      <Title>Tabela de Descarte</Title>

      <TabelaGrid>
        {/* Linha 1 ----------------------------------------------------- */}
        <Celula strong center left={"0px"} top={"0px"}>
          Descrição dos dados pessoais coletados
        </Celula>
        <Celula strong center>
          Quanto tempo a empresa precisa ficar com estes dados?
        </Celula>
        <Celula strong center>
          Embasamento legal para que a empresa fique este tempo com os dados
          pessoais
        </Celula>
        <Celula strong center right="0px" top="0px">
          Como estes dados serão descartados?
        </Celula>

        {/* Linha 2 ----------------------------------------------------- */}
        <Celula>
          <strong>Dados de Identificação:</strong>
          <ul>
            {props.dados.TD26 === "Sim" && <Li>{perguntas[26].pergunta}</Li>}
            {props.dados.TD27 === "Sim" && <Li>{perguntas[27].pergunta}</Li>}
            {props.dados.TD28 === "Sim" && <Li>{perguntas[28].pergunta}</Li>}
            {props.dados.TD29 === "Sim" && <Li>{perguntas[29].pergunta}</Li>}
            {props.dados.TD30 === "Sim" && <Li>{perguntas[30].pergunta}</Li>}
            {props.dados.TD31 === "Sim" && <Li>{perguntas[31].pergunta}</Li>}
            {props.dados.TD32 === "Sim" && <Li>{perguntas[32].pergunta}</Li>}
            {props.dados.TD33 === "Sim" && <Li>{perguntas[33].pergunta}</Li>}
          </ul>
        </Celula>
        <Celula>{props.dados.TD0}</Celula>
        <Celula>{props.dados.TD1}</Celula>
        <Celula>
          <p>Dados físicos: Devem ser destruídos</p>
          <p>
            Dados digitais: Devem ser excluídos. Assim como todas suas cópias
          </p>
        </Celula>
        {/* Linha 2 ----------------------------------------------------- */}
        <Celula>
          <strong>Dados financeiros:</strong>
          <ul>
            {props.dados.TD2 === "Sim" && <Li>{perguntas[2].pergunta}</Li>}
            {props.dados.TD3 === "Sim" && <Li>{perguntas[3].pergunta}</Li>}
            {props.dados.TD4 === "Sim" && <Li>{perguntas[4].pergunta}</Li>}
            {props.dados.TD5 === "Sim" && <Li>{perguntas[5].pergunta}</Li>}
            {props.dados.TD6 === "Sim" && <Li>{perguntas[6].pergunta}</Li>}
          </ul>
        </Celula>
        <Celula>{props.dados.TD7}</Celula>
        <Celula>{props.dados.TD8}</Celula>
        <Celula>
          <p>Dados físicos: Devem ser destruídos</p>
          <p>
            Dados digitais: Devem ser excluídos. Assim como todas suas cópias
          </p>
        </Celula>

        {/* Linha 3 ----------------------------------------------------- */}
        <Celula>
          <strong>Hábitos pessoais:</strong>
          <ul>
            {props.dados.TD9 === "Sim" && <Li>{perguntas[9].pergunta}</Li>}
            {props.dados.TD10 === "Sim" && <Li>{perguntas[10].pergunta}</Li>}
            {props.dados.TD11 === "Sim" && <Li>{perguntas[11].pergunta}</Li>}
          </ul>
        </Celula>
        <Celula>{props.dados.TD12}</Celula>
        <Celula>{props.dados.TD13}</Celula>
        <Celula>
          <p>Dados físicos: Devem ser destruídos</p>
          <p>
            Dados digitais: Devem ser excluídos. Assim como todas suas cópias
          </p>
        </Celula>

        {/* Linha 4 ----------------------------------------------------- */}
        <Celula left={"0px"} bottom={"0px"}>
          <strong>Dados sensíveis:</strong>
          <ul>
            {props.dados.TD14 === "Sim" && <Li>{perguntas[14].pergunta}</Li>}
            {props.dados.TD15 === "Sim" && <Li>{perguntas[15].pergunta}</Li>}
            {props.dados.TD16 === "Sim" && <Li>{perguntas[16].pergunta}</Li>}
            {props.dados.TD17 === "Sim" && <Li>{perguntas[17].pergunta}</Li>}
            {props.dados.TD18 === "Sim" && <Li>{perguntas[18].pergunta}</Li>}
            {props.dados.TD19 === "Sim" && <Li>{perguntas[19].pergunta}</Li>}
            {props.dados.TD20 === "Sim" && <Li>{perguntas[20].pergunta}</Li>}
            {props.dados.TD21 === "Sim" && <Li>{perguntas[21].pergunta}</Li>}
            {props.dados.TD22 === "Sim" && <Li>{perguntas[22].pergunta}</Li>}
            {props.dados.TD23 === "Sim" && <Li>{perguntas[23].pergunta}</Li>}
          </ul>
        </Celula>
        <Celula>{props.dados.TD24}</Celula>
        <Celula>{props.dados.TD25}</Celula>
        <Celula right="0px" bottom="0px">
          <p>Dados físicos: Devem ser destruídos</p>
          <p>
            Dados digitais: Devem ser excluídos. Assim como todas suas cópias
          </p>
        </Celula>
      </TabelaGrid>
    </Container>
  );
};

export default ResultadoTabelaDeDescarte;
