import React from "react";
import notFound from "../imgs/404.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
`;

const Img404 = styled.img`
  width: 30%;
`;

const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  font-family: "Montserrat", "sans-serif";
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Img404 src={notFound} alt="Não encontrado" />
      <ButtonPattern2 onClick={() => navigate("/")}>
        Voltar para home !
      </ButtonPattern2>
    </Container>
  );
};

export default NotFound;
