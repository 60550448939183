import React, { useState } from 'react';
import { db } from '../../Firebase/firebase';
import { getAuth } from 'firebase/auth';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import CampoTexto from '../../Componentes/CampoTexto';
import CampoCheckBox from '../../Componentes/CampoCheckBox';
import { perguntasMapeamentoDeDados as perguntas } from '../../Dados/PerguntasMapeamentoDeDados';
import Navbar from '../../Componentes/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const neumorphismContainer = {
  borderRadius: '18px',
  background: '#ffffff',
  margin: '2% 18%',
  padding: '1%',
  boxShadow: '20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff',
};

const NeumorphismContainer = styled.div`
  border-radius: 18px;
  background: #ffffff;
  margin: 2% 18%;
  padding: 1%;
  box-shadow: 20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;
const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;
const SectionTitle = styled.h2`
  color: #5b0390;
`;
const SectionSubTitle = styled.h3`
  color: #be00ff;
`;

const MapeamentoDeDados = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(getAuth()?.currentUser?.email);
  const [nome, setNome] = useState();
  const [dataDePreenchimento, setdataDePreenchimento] = useState();
  const [pergunta2, setPergunta2] = useState();
  const [pergunta3, setPergunta3] = useState();
  const [pergunta4, setPergunta4] = useState();
  const [pergunta58, setPergunta58] = useState('Não');
  const [pergunta59, setPergunta59] = useState('Não');
  const [pergunta60, setPergunta60] = useState('Não');
  const [pergunta61, setPergunta61] = useState('Não');
  const [arrayDeRespostas, setArrayDeRespostas] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const docRef = collection(db, 'usuarios');
  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: nome,
          [perguntas[1].codigo]: dataDePreenchimento,
          [perguntas[2].codigo]: pergunta2,
          [perguntas[3].codigo]: pergunta3,
          [perguntas[4].codigo]: pergunta4,
          [perguntas[5].codigo]: arrayDeRespostas[5] ? 'Sim' : 'Não',
          [perguntas[6].codigo]: arrayDeRespostas[6] ? 'Sim' : 'Não',
          [perguntas[7].codigo]: arrayDeRespostas[7] ? 'Sim' : 'Não',
          [perguntas[8].codigo]: arrayDeRespostas[8] ? 'Sim' : 'Não',
          [perguntas[9].codigo]: arrayDeRespostas[9] ? 'Sim' : 'Não',
          [perguntas[10].codigo]: arrayDeRespostas[10] ? 'Sim' : 'Não',
          [perguntas[11].codigo]: arrayDeRespostas[11] ? 'Sim' : 'Não',
          [perguntas[12].codigo]: arrayDeRespostas[12] ? 'Sim' : 'Não',
          [perguntas[13].codigo]: arrayDeRespostas[13] ? 'Sim' : 'Não',
          [perguntas[14].codigo]: arrayDeRespostas[14] ? 'Sim' : 'Não',
          [perguntas[15].codigo]: arrayDeRespostas[15] ? 'Sim' : 'Não',
          [perguntas[16].codigo]: arrayDeRespostas[16] ? 'Sim' : 'Não',
          [perguntas[17].codigo]: arrayDeRespostas[17] ? 'Sim' : 'Não',
          [perguntas[18].codigo]: arrayDeRespostas[18] ? 'Sim' : 'Não',
          [perguntas[19].codigo]: arrayDeRespostas[19] ? 'Sim' : 'Não',
          [perguntas[20].codigo]: arrayDeRespostas[20] ? 'Sim' : 'Não',
          [perguntas[21].codigo]: arrayDeRespostas[21] ? 'Sim' : 'Não',
          [perguntas[22].codigo]: arrayDeRespostas[22] ? 'Sim' : 'Não',
          [perguntas[23].codigo]: arrayDeRespostas[23] ? 'Sim' : 'Não',
          [perguntas[24].codigo]: arrayDeRespostas[24] ? 'Sim' : 'Não',
          [perguntas[25].codigo]: arrayDeRespostas[25] ? 'Sim' : 'Não',
          [perguntas[26].codigo]: arrayDeRespostas[26] ? 'Sim' : 'Não',
          [perguntas[27].codigo]: arrayDeRespostas[27] ? 'Sim' : 'Não',
          [perguntas[28].codigo]: arrayDeRespostas[28] ? 'Sim' : 'Não',
          [perguntas[29].codigo]: arrayDeRespostas[29] ? 'Sim' : 'Não',
          [perguntas[30].codigo]: arrayDeRespostas[30] ? 'Sim' : 'Não',
          [perguntas[31].codigo]: arrayDeRespostas[31] ? 'Sim' : 'Não',
          [perguntas[32].codigo]: arrayDeRespostas[32] ? 'Sim' : 'Não',
          [perguntas[33].codigo]: arrayDeRespostas[33] ? 'Sim' : 'Não',
          [perguntas[34].codigo]: arrayDeRespostas[34] ? 'Sim' : 'Não',
          [perguntas[35].codigo]: arrayDeRespostas[35] ? 'Sim' : 'Não',
          [perguntas[36].codigo]: arrayDeRespostas[36] ? 'Sim' : 'Não',
          [perguntas[37].codigo]: arrayDeRespostas[37] ? 'Sim' : 'Não',
          [perguntas[38].codigo]: arrayDeRespostas[38] ? 'Sim' : 'Não',
          [perguntas[39].codigo]: arrayDeRespostas[39] ? 'Sim' : 'Não',
          [perguntas[40].codigo]: arrayDeRespostas[40] ? 'Sim' : 'Não',
          [perguntas[41].codigo]: arrayDeRespostas[41] ? 'Sim' : 'Não',
          [perguntas[42].codigo]: arrayDeRespostas[42] ? 'Sim' : 'Não',
          [perguntas[43].codigo]: arrayDeRespostas[43] ? 'Sim' : 'Não',
          [perguntas[44].codigo]: arrayDeRespostas[44] ? 'Sim' : 'Não',
          [perguntas[45].codigo]: arrayDeRespostas[45] ? 'Sim' : 'Não',
          [perguntas[46].codigo]: arrayDeRespostas[46] ? 'Sim' : 'Não',
          [perguntas[47].codigo]: arrayDeRespostas[47] ? 'Sim' : 'Não',
          [perguntas[48].codigo]: arrayDeRespostas[48] ? 'Sim' : 'Não',
          [perguntas[49].codigo]: arrayDeRespostas[49] ? 'Sim' : 'Não',
          [perguntas[50].codigo]: arrayDeRespostas[50] ? 'Sim' : 'Não',
          [perguntas[51].codigo]: arrayDeRespostas[51] ? 'Sim' : 'Não',
          [perguntas[52].codigo]: arrayDeRespostas[52] ? 'Sim' : 'Não',
          [perguntas[53].codigo]: arrayDeRespostas[53] ? 'Sim' : 'Não',
          [perguntas[54].codigo]: arrayDeRespostas[54] ? 'Sim' : 'Não',
          [perguntas[55].codigo]: arrayDeRespostas[55] ? 'Sim' : 'Não',
          [perguntas[56].codigo]: arrayDeRespostas[56] ? 'Sim' : 'Não',
          [perguntas[57].codigo]: arrayDeRespostas[57] ? 'Sim' : 'Não',
          [perguntas[58].codigo]: pergunta58,
          [perguntas[59].codigo]: pergunta59,
          [perguntas[60].codigo]: pergunta60,
          [perguntas[61].codigo]: pergunta61,
          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      // navigate('/documento', { state: getAuth().currentUser.uid });
      navigate('/documento-mapeamento-dados');
    } catch (error) {
      console.log(error);
    }
  };

  const adicionaResposta = (posicao) => {
    const updatedCheckedState = arrayDeRespostas.map((item, index) =>
      index === posicao ? !item : item
    );

    setArrayDeRespostas(updatedCheckedState);
  };

  return (
    <>
      <Navbar />
      <div>
        <Title>Mapeamento De Dados</Title>
        <form onSubmit={cadastrar}>
          <div style={neumorphismContainer}>
            <SectionTitle>Ciclo de Dados</SectionTitle>

            <CampoTexto
              required={true}
              label={perguntas[0].pergunta}
              placeholder={'Preencha seu nome completo'}
              onChange={(e) => setNome(e.target.value)}
            />

            <CampoTexto
              type={'date'}
              required={true}
              label={perguntas[1].pergunta}
              onChange={(e) => setdataDePreenchimento(e.target.value)}
            />

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <p>{perguntas[2].pergunta}</p>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao1'
                  label='Sim'
                  control={<Radio />}
                  onChange={() => setPergunta2('Sim')}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao2'
                  label='Não'
                  control={<Radio />}
                  onChange={() => setPergunta2('Não')}
                ></FormControlLabel>
              </div>
            </RadioGroup>

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <p>{perguntas[3].pergunta}</p>
              <div>
                <FormControlLabel
                  name='checkbox2'
                  type='radio'
                  value='opcao1'
                  label='Sim'
                  control={<Radio />}
                  onChange={() => setPergunta3('Sim')}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox2'
                  type='radio'
                  value='opcao2'
                  label='Não'
                  control={<Radio />}
                  onChange={() => setPergunta3('Não')}
                ></FormControlLabel>
              </div>
            </RadioGroup>

            <CampoTexto
              required={true}
              label={perguntas[4].pergunta}
              placeholder={
                'Adicione o nome das empresas separados por vírgula.'
              }
              onChange={(e) => setPergunta4(e.target.value)}
            />
          </div>
          <div style={neumorphismContainer}>
            <SectionTitle>Agentes de Tratamento</SectionTitle>
            <p>
              Classifique se a sua empresa é uma operadora ou controladora de
              dados
            </p>
            <SectionTitle>Minha empresa com relação ao cliente:</SectionTitle>
            {perguntas.map((item, index) => {
              if (index > 4 && index < 12) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={(e) => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}

            <SectionTitle>
              Minha empresa com relação ao prestador de serviço:
            </SectionTitle>
            {perguntas.map((item, index) => {
              if (index > 11 && index < 19) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={(e) => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}

            <SectionTitle>
              Minha empresa com relação ao funcionário:
            </SectionTitle>
            {perguntas.map((item, index) => {
              if (index > 18 && index < 26) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={() => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}

            <SectionTitle>
              Minha empresa com relação a outra empresa que é minha fornecedora:
            </SectionTitle>
            {perguntas.map((item, index) => {
              if (index > 25 && index < 33) {
                return (
                  <CampoCheckBox
                    key={index}
                    label={item.pergunta}
                    id={item.id}
                    onChange={() => adicionaResposta(index)}
                  />
                );
              }
              return null;
            })}
          </div>
          <div style={neumorphismContainer}>
            <div>
              <SectionTitle>Definição de Dados</SectionTitle>
              <p>Selecione os dados que são coletados pela sua empresa:</p>
              <SectionSubTitle>Dados de identificação pessoal:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 32 && index < 41) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}

              <SectionSubTitle>Dados financeiros:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 40 && index < 45) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}

              <SectionSubTitle>Hábitos pessoais:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 44 && index < 48) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}

              <SectionSubTitle>Dados sensíveis:</SectionSubTitle>
              {perguntas.map((item, index) => {
                if (index > 47 && index < 58) {
                  return (
                    <CampoCheckBox
                      key={index}
                      label={item.pergunta}
                      id={item.id}
                      onChange={() => adicionaResposta(index)}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div style={neumorphismContainer}>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
            >
              <SectionSubTitle>Base Legal</SectionSubTitle>
              <p>
                Vamos encontrar a base legal da coleta de dados na sua empresa,
                responda a pergunta abaixo:
              </p>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao1'
                  label='Porque existe uma lei específica na minha área de atuação que exige que eu faça esta coleta dos dados pessoais dos clientes.'
                  control={<Radio />}
                  onChange={() => setPergunta58('Sim')}
                ></FormControlLabel>

                <div>
                  <FormControlLabel
                    name='checkbox1'
                    type='radio'
                    value='opcao2'
                    label='Porque eu vou oferecer produtos depois para ele. Ou seja, vou realizar marketing.'
                    control={<Radio />}
                    onChange={() => setPergunta59('Sim')}
                  ></FormControlLabel>
                </div>
              </div>

              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao3'
                  label='Vocês vão celebrar um contrato entre vocês.'
                  control={<Radio />}
                  onChange={() => setPergunta60('Sim')}
                ></FormControlLabel>
              </div>
              <div>
                <FormControlLabel
                  name='checkbox1'
                  type='radio'
                  value='opcao4'
                  label='Sou uma instituição financeira credora'
                  control={<Radio />}
                  onChange={() => setPergunta61('Sim')}
                ></FormControlLabel>
              </div>
            </RadioGroup>
          </div>

          <ButtonContainer>
            <ButtonPattern>Enviar</ButtonPattern>
            <ButtonPattern2
              onClick={() => navigate('/documento-mapeamento-dados')}
            >
              Visualizar Documento
            </ButtonPattern2>
          </ButtonContainer>
        </form>
      </div>

      {/* <div ref={targetRef}>Hello</div> */}
    </>
  );
};

export default MapeamentoDeDados;
