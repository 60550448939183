import React from 'react';
import { perguntasMapeamentoDeDados as perguntas } from '../Dados/PerguntasMapeamentoDeDados';
import styled from 'styled-components';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import sourceArial from '../Fonts/arial.ttf';
import sourceArialBold from '../Fonts/arialbd.ttf';

Font.register({
  family: 'Arial',
  fonts: [
    {
      src: sourceArial,
    },
    {
      src: sourceArial,
      fontWeight: 'bold',
    },
  ],
});

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 50px;
  text-align: center;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-top: 100px;
`;

const Texto = styled.p`
  font-size: 30px;
`;

const Li = styled.li`
  font-size: 30px;
`;

const styles = StyleSheet.create({
  page: {
    width: '100%',
    fontFamily: 'Arial',
    marginTop:10,
   
    
  },
  titulo: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '40px',
    textAlign: 'center',
  },
  section: {
    fontFamily: 'Arial',
    fontSize: 12,
    marginBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: 'justify',
  },
  p: {
    marginTop: 5,
    marginBottom: 5,
    textIndent: 30,
  },
  topico: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontWeight: '800',
    fontSize: 14,
  },
  lista: {
    fontWeight: '800',
  },
  recuo: {
    marginLeft: 30,
    fontWeight: '800',
  },
  recuoItens: {
    marginLeft: 50,
  },
});

const ResultadoPoliticaDePrivacidade = (props) => {
  Font.register({
    family: 'Arial',
    fonts: [
      {
        src: sourceArial,
      },
      {
        src: sourceArialBold,
        fontWeight: 'bold',
      },
    ],
  });

  const MyDocument = () => (
    <Document style={{ width: '100%' }}>
      <Page size='A4' style={styles.page}>
        <View wrap={false}>
          <Text style={styles.titulo}>Política de Privacidade</Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.p}>
            Esta Política de Privacidade foi elaborada para informar como nossa
            empresa realiza o tratamento dos Dados Pessoais que chegam até nós.
            O presente documento se aplica a indivíduos (pessoas naturais) que
            interagem com nossos serviços/produtos como consumidores (“Você”).
            Esta Política explica todo o ciclo do seu dado pessoal, desde a
            coleta até o descarte. Aqui, você vai encontrar informações como:
            quais seus dados pessoais coletamos, onde coletamos estes dados, com
            quem compartilhamos, qual a finalidade da coleta destes dados,
            dentre outras informações.
          </Text>
          <Text style={styles.p}>
            A descrição abrange nossas atividades de coleta de dados tanto
            online e offline, abrangendo os Dados Pessoais que Nós coletamos por
            meio de nossos vários canais, incluindo – mas não limitado a – Sites
            na web, Aplicativos, Redes Sociais de terceiros, Serviço de
            Atendimento ao Consumidor, Pontos de Venda, Pesquisas e Eventos. Por
            favor, note que Nós poderemos agregar Dados Pessoais combinados de
            diferentes fontes (por exemplo, nosso site e eventos offline). Em
            alguns casos específicos, se Você decidir por não nos fornecer os
            seus Dados Pessoais apontados como necessários (Nós indicaremos
            quando esse for o caso, por exemplo, colocando explicitamente essa
            informação em nossos formulários de registro), Nós talvez não
            possamos fornecer a Você nossos produtos e/ou serviços.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          {(props.dados.PPR0 === 'Sim' ||
            props.dados.PPR1 === 'Sim' ||
            props.dados.PPR2 === 'Sim' ||
            props.dados.PPR3 === 'Sim' ||
            props.dados.PPR4 === 'Sim' ||
            props.dados.PPR5 === 'Sim' ||
            props.dados.PPR6 === 'Sim' ||
            props.dados.PPR7 === 'Sim' ||
            props.dados.PPR8 === 'Sim') && (
            <>
              <Text style={styles.topico}>
                Nossa empresa coleta seus Dados Pessoais através das seguintes
                fontes:
              </Text>

              {(props.dados.PPR0 === 'Sim' || props.dados.PPR1 === 'Sim') && (
                <Text>
                  <Text style={styles.lista}>Nosso Site(s): </Text>
                  Nosso site realiza coleta de dados pessoais. Isso inclui tanto
                  site que operamos diretamente através dos nossos domínios e
                  endereços IPs, quantos sites ou páginas de terceiros
                  prestadores de serviços colocar as Redes Sociais utilizadas.
                </Text>
              )}

              {(props.dados.PPR2 === 'Sim' || props.dados.PPR3 === 'Sim') && (
                <Text>
                  <Text style={styles.lista}>
                    Correio Eletrônico e sistemas de troca de mensagens
                    instantâneas:{` `}
                  </Text>
                  Utilizamos serviços para manter comunicações eletrônicas entre
                  Você e nossa empresa, incluindo aqueles disponibilizados
                  diretamente por Nós, ou serviços de terceiros como WhatsApp,
                  Telegram, SMS (serviço de mensagens curtas) e similares.
                </Text>
              )}

              {props.dados.PPR4 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>Aplicativos móveis: </Text>
                  Aqui estão nossos aplicativos móveis fornecidos diretamente
                  pela nossa empresa ou através de serviços de terceiros como
                  Google ou Apple.
                </Text>
              )}
              {props.dados.PPR5 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Atendimento ao consumidor e central de vendas:{` `}
                  </Text>
                  Realizamos comunicações realizadas com Você através das nossas
                  centrais de atendimento e vendas.
                </Text>
              )}

              {props.dados.PPR6 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Anúncios, propagandas e formulários online:{` `}
                  </Text>
                  Promovemos interações com nossos clientes através de:
                  anúncios, propagandas e formulários online.
                </Text>
              )}
              {props.dados.PPR7 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>Registros offline: </Text>
                  Distribuímos, ocasionalmente, listas de presença e de
                  cadastro, durante eventos e outras interações.
                </Text>
              )}
              {props.dados.PPR8 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Dados recebidos de terceiros:{` `}
                  </Text>
                  Obtemos dados pessoais através das redes sociais (listar
                  quais), sites de terceiros parceiros (listar quais) e fontes
                  públicas.
                </Text>
              )}
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {(props.dados.PPR9 === 'Sim' ||
            props.dados.PPR10 === 'Sim' ||
            props.dados.PPR88 === 'Sim' ||
            props.dados.PPR11 === 'Sim' ||
            props.dados.PPR12 === 'Sim' ||
            props.dados.PPR13 === 'Sim' ||
            props.dados.PPR14 === 'Sim' ||
            props.dados.PPR15 === 'Sim' ||
            props.dados.PPR16 === 'Sim' ||
            props.dados.PPR17 === 'Sim' ||
            props.dados.PPR18 === 'Sim' ||
            props.dados.PPR19 === 'Sim' ||
            props.dados.PPR20 === 'Sim' ||
            props.dados.PPR21 === 'Sim' ||
            props.dados.PPR22 === 'Sim' ||
            props.dados.PPR23 === 'Sim') && (
            <>
              <Text style={styles.topico}>
                Durante contato com nossa empresa, utilizando a origem dos dados
                que explicitamos acima, podemos coletar vários tipos de dados
                pessoais a seu respeito. A seguir vamos explicar quais:
              </Text>
              {props.dados.PPR9 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>Informações de Contato: </Text>
                  Inclui qualquer tipo de informação que possa facilitar nosso
                  contato com Você, incluindo seu endereço físico, números de
                  telefone, e-mails e perfis em redes sociais.
                </Text>
              )}
              {props.dados.PPR88 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Informações de login para nossa área interna:{` `}
                  </Text>
                  Coletamos as informações necessárias para identificar e
                  autenticar nossos usuários, sendo elas: seu nome de registro
                  (login), senha em formato irrecuperável (criptografado) e
                  perguntas de segurança.
                </Text>
              )}

              {props.dados.PPR10 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Informações demográficas e seus interesses:{` `}
                  </Text>
                  Qualquer informação que possa descrever seus dados
                  demográficos, hábitos ou suas características de
                  comportamento, incluindo itens como seu aniversário, data de
                  nascimento, idade ou faixa etária, gênero, localização
                  geográfica, produtos favoritos, hobbies e passatempos, demais
                  interesses e informações familiares ou sobre seu estilo de
                  vida.
                </Text>
              )}
              {props.dados.PPR11 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Informações técnicas sobre seus equipamentos computacionais
                    ou dispositivos móveis:{` `}
                  </Text>
                  Detalhes sobre o seu computador ou outro dispositivo portátil
                  que foi utilizado para acessar um de nossos sites, serviços ou
                  aplicativos, incluindo o registro do endereço IP utilizado
                  para conectar seu computador ou dispositivo à internet,
                  incluindo sua localização geográfica, o tipo e a versão de
                  sistema operacional e o tipo e a versão do navegador da web.
                  Se Você acessar um site ou aplicativo da nossa empresa usando
                  um dispositivo móvel, como um celular inteligente ou tablet,
                  as informações coletadas também incluirão, sempre que
                  permitido, o ID de dispositivo exclusivo de seu telefone, a
                  localização geográfica e outros dados similares do dispositivo
                  móvel.
                </Text>
              )}
              {props.dados.PPR12 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Informações sobre como Você utiliza nossos sites e serviços:
                    {` `}
                  </Text>
                  Durante sua interação com nossos sites e serviços, utilizamos
                  tecnologias de coleta automática de dados para capturar
                  informações sobre as suas ações. Isso pode incluir detalhes
                  como em quais links Você clica, quais páginas ou conteúdos
                  Você visualiza e por quanto tempo, e outras informações e
                  estatísticas semelhantes sobre suas interações, como tempos de
                  resposta a conteúdo, erros de download e duração das visitas a
                  determinadas páginas. Essas informações são capturadas por
                  meio de tecnologias automatizadas, como Cookies (Cookies de
                  navegador, Cookies Flash e similares) e web beacons, e também
                  via rastreamento de terceiros. Você possui total liberdade
                  para se opor à utilização de tais tecnologias ou permitir que
                  utilizemos só os essenciais, através da seleção feita pela
                  barra de cookies.
                </Text>
              )}
              {props.dados.PPR13 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Pesquisas de mercado e feedback de consumidores: {` `}
                  </Text>
                  São informações compartilhadas voluntariamente falando sobre
                  sua experiência de uso de nossos produtos e serviços.
                </Text>
              )}
              {props.dados.PPR14 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Conteúdo gerado por consumidores:{' '}
                  </Text>
                  Aqui estão os conteúdos criados e compartilhados pelos
                  usuários através das Redes Sociais, aplicativos e demais
                  serviços online e off-line. Esses dados incluem: textos,
                  comentários, artigos, fotos, vídeos, histórias pessoais ou
                  outros conteúdos e mídias semelhantes. Sempre que permitido,
                  Nós coletamos e publicamos conteúdos gerados por consumidores
                  em conexão com uma variedade de atividades, incluindo
                  divulgação de produtos e serviços, concursos, premiações e
                  outras promoções, recursos de comunidade do site, engajamento
                  de consumidores e redes sociais de terceiros.
                </Text>
              )}
              {props.dados.PPR15 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Informações financeiras e de pagamento:{' '}
                  </Text>
                  Quaisquer dados que nossa empresa precisa para atender a um
                  pedido ou que Você usa para fazer uma compra, como os dados de
                  seu cartão de débito ou crédito (nome do titular do cartão,
                  número do cartão, data de validade e etc.) ou outras formas de
                  pagamento utilizadas. Os dados são manuseados em conformidade
                  com as leis, normas e os padrões de segurança aplicáveis.
                </Text>
              )}
              {props.dados.PPR16 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Contatos com nosso Serviço de Atendimento ao Consumidor e
                    Central de Vendas:{' '}
                  </Text>
                  Suas interações com nossos Serviço de Atendimento ao
                  Consumidor e Central de Vendas podem ser gravadas ou ouvidas,
                  de acordo com as leis aplicáveis, para nossas necessidades
                  operacionais. Detalhes de informações financeiras e de
                  pagamentos não são gravados. Quando exigido por lei, Você será
                  informado sobre tal gravação ainda no início de sua chamada.
                </Text>
              )}
              {(props.dados.PPR17 === 'Sim' ||
                props.dados.PPR18 === 'Sim' ||
                props.dados.PPR19 === 'Sim' ||
                props.dados.PPR20 === 'Sim' ||
                props.dados.PPR21 === 'Sim' ||
                props.dados.PPR22 === 'Sim' ||
                props.dados.PPR23 === 'Sim') && (
                <Text>
                  <Text style={styles.lista}>Dados Pessoais Sensíveis: </Text>
                  Nossa empresa não lida normalmente com Dados Pessoais
                  considerados sensíveis de acordo com a legislação vigente,
                  dessa forma não temos a intenção de coletar ou processor dados
                  pessoais sensíveis no curso normal de suas interações com
                  nossos produtos ou serviços. Quando houver legitimo interesse
                  ou for necessário processar seus dados pessoais sensíveis, por
                  qualquer motivo, nós obteremos seu prévio, expresso e formal
                  consentimento para qualquer processamento que for voluntário
                  (por exemplo, para finalidades de marketing).
                </Text>
              )}
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.topico}>
            Sobre Dados Pessoais de Crianças e Adolescentes
          </Text>
          <Text style={styles.p}>
            Nossa empresa não solicita, coleta, processa, armazena ou
            compartilha, conscientemente, dados pessoais de crianças e
            adolescentes menores de idade. Se descobrirmos a ocorrência de
            qualquer tipo de manuseio dos referidos dados, forma
            não-intencional, removeremos os dados pessoais daquela criança ou
            adolescente de nossos registros rapidamente. Porém, nossa empresa
            pode coletar dados pessoais de crianças e adolescentes diretamente
            dos seus pais ou responsáveis legais, com consentimento explícito e
            de acordo com as regras da legislação vigente.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.topico}>
            Sobre o uso de Cookies, arquivos de registos (logs) e similares
          </Text>
          <Text style={styles.p}>
            Os cookies são pequenos arquivos de texto que podem ser colocados no
            seu computador ou dispositivo portátil por sites ou serviços, que
            você utiliza na web. Sua função? Garantir que o funcionamento dos
            sites e demais serviços online sejam satisfatórios. Os cookies
            possuem diversas funções, desde a citada até algumas para fins de
            entender melhor como nossos visitantes utilizam os nossos sites e
            serviços online. Dentre os existentes utilizamos os seguintes:{' '}
          </Text>

          {props.dados.PPR25 === 'Sim' && (
            <Text>
              <Text style={styles.lista}>Cookies de sessão: </Text>
              São Cookies de uso temporário, que são excluídos no momento em que
              o usuário fecha o seu navegador. Sendo assim, quando reiniciado o
              navegador e o visitante voltar ao site, não haverá nenhuma
              informação sua armazenada.
            </Text>
          )}

          {props.dados.PPR26 === 'Sim' && (
            <Text>
              <Text style={styles.lista}>Cookies persistentes: </Text>
              São Cookies que permanecem no seu navegador até Você deletá-los
              manualmente ou até o seu navegador deletá-los de acordo com o
              período de duração estabelecido pelo cookie. Esses Cookies
              reconhecerão seu retorno como visitante a um site ou serviço da
              nossa empresa.
            </Text>
          )}

          {props.dados.PPR27 === 'Sim' && (
            <Text>
              <Text style={styles.lista}>Cookies necessários: </Text>
              São Cookies estritamente necessários para a operação do nosso
              site. Eles permitem que o usuário navegue pelo nosso site e use
              nossos recursos.
            </Text>
          )}

          {props.dados.PPR28 === 'Sim' && (
            <Text>
              <Text style={styles.lista}>Cookies em propagandas: </Text>
              Colocamos Cookies em propagandas e anúncios que são exibidos em
              sites e serviços compatíveis de terceiros. Através destes cookies
              obtemos informações a partir do momento em que você interage com o
              anúncio. Neste caso, estamos colocando um cookie “de terceiro”.
              Podemos utilizar estes cookies para endereçar outras propagandas
              que acreditamos ser relevantes ou de seu interesse.
            </Text>
          )}

          {props.dados.PPR29 === 'Sim' && (
            <Text>
              <Text style={styles.lista}>
                Cookies que compartilham suas informações para terceiros:{' '}
              </Text>
              São Cookies colocados no nosso site por nossas companhias
              parceiras, como serviços de anúncios online. Eles podem usar os
              dados coletados por esses Cookies para lhe enviar anonimamente
              propagandas direcionadas de outros sites, com base em sua visita a
              sites ou serviços compatíveis com os nossos.
            </Text>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.topico}>
            Para que utilizamos seus dados pessoais (a finalidade)
          </Text>
          <Text style={styles.p}>
            Os itens a seguir descrevem as finalidades para as quais coletamos
            seus Dados Pessoais, e os diferentes tipos de Dados Pessoais que
            coletamos para cada finalidade. A utilização dos dados dependerá de
            quais dos nossos serviços ou produtos você utilizará.
          </Text>

          {props.dados.PPR5 === 'Sim' && (
            <>
              <Text>
                <Text style={styles.lista}>Serviços ao consumidor: </Text>
                Seus Dados Pessoais são utilizados para finalidade de prestar
                serviços ao consumidor, incluindo responder a suas dúvidas,
                questionamentos e sugestões. Usualmente, isso requer certas
                informações pessoais de contato e informações sobre o motivo de
                seu questionamento, dúvida ou sugestão, por exemplo, qual foi o
                seu pedido, se existe um problema técnico, questão ou reclamação
                sobre produto, questionamento em geral.
              </Text>

              <Text style={styles.recuo}>
                Motivo para uso dos seus dados pessoais nessa situação:
              </Text>

              <Text style={styles.recuoItens}>
                - Cumprir obrigações contratuais;
              </Text>
              <Text style={styles.recuoItens}>
                - Cumprir obrigações legais;
              </Text>
              <Text style={styles.recuoItens}>
                - Nossos interesses legítimos.
              </Text>

              <Text style={styles.recuo}>
                - Nossos interesses legítimos nessa situação:
              </Text>

              <Text style={styles.recuoItens}>
                - Melhorar continuamente nossos produtos e serviços;
              </Text>
              <Text style={styles.recuoItens}>
                - Melhorar continuamente a efetividade do nosso atendimento ao
                consumidor.
              </Text>
            </>
          )}

          {props.dados.PPR0 === 'Sim' && (
            <>
              <Text>
                <Text style={styles.lista}>
                  Redes sociais e sites de terceiros:{' '}
                </Text>
                Usamos seus Dados Pessoais quando Você interage com funções de
                redes sociais e sites de terceiros, como “curtir”, para fornecer
                anúncios e interagir com Você em redes sociais de terceiros. A
                forma como essas interações funcionam, os dados de perfil
                obtemos sobre Você, e como cancelá-los (“opt-out”) podem ser
                entendidas revisando as políticas de privacidade diretamente nas
                respectivas redes sociais e sites de terceiros.
              </Text>

              <Text style={styles.recuo}>
                Motivo para uso dos seus dados pessoais nessa situação:
              </Text>

              <Text style={styles.recuoItens}>
                - Nossos interesses legítimos.
              </Text>
              <Text style={styles.recuoItens}>
                - Obtivemos o seu consentimento (quando necessário).
              </Text>

              <Text style={styles.recuo}>
                - Nossos interesses legítimos nessa situação:
              </Text>

              <Text style={styles.recuoItens}>
                - Entender quais de nossos produtos e serviços podem interessar
                a Você e fornecer informações sobre eles;
              </Text>
              <Text style={styles.recuoItens}>
                - Definir consumidores para novos produtos ou serviços.
              </Text>
            </>
          )}

          {props.dados.PPR15 === 'Sim' && (
            <>
              <Text>
                <Text style={styles.lista}>Atendimento aos seus pedidos: </Text>
                Usamos seus Dados Pessoais para processar e enviar seus pedidos,
                além de informá-lo sobre a situação dos seus pedidos, corrigir
                endereços e conduzir verificação de identidade e outras
                atividades para verificação de fraudes. Isso envolve o uso de
                certos Dados Pessoais e, em certas situações, informações de
                pagamento.
              </Text>

              <Text style={styles.recuo}>
                Motivo para uso dos seus dados pessoais nessa situação:
              </Text>

              <Text style={styles.recuoItens}>
                - Cumprir obrigações contratuais;
              </Text>
              <Text style={styles.recuoItens}>- Obrigações legais;</Text>
              <Text style={styles.recuoItens}>
                - Nossos interesses legítimos.
              </Text>
              <Text style={styles.recuoItens}>
                - Obtivemos o seu consentimento (quando necessário).
              </Text>

              <Text style={styles.recuo}>
                - Nossos interesses legítimos nessa situação:
              </Text>

              <Text style={styles.recuoItens}>
                - Melhorar e desenvolver novos produtos e serviços;
              </Text>
              <Text style={styles.recuoItens}>
                - Ser mais eficientes no atendimento aos seus pedidos;
              </Text>
              <Text style={styles.recuoItens}>
                - Proteger nossos sistemas, redes e colaboradores;
              </Text>
              <Text style={styles.recuoItens}>
                - Cumprir integralmente obrigações legais.
              </Text>
            </>
          )}
          <>
            <Text>
              <Text style={styles.lista}>
                Outras finalidades e situações em geral:{' '}
              </Text>
              De acordo com a legislação vigente, utilizamos seus Dados Pessoais
              para outras finalidades gerais de negócio, como fazer manutenção
              em sua conta, conduzir pesquisas internas ou de mercado e medir a
              efetividade de nossas campanhas publicitárias. Nós também usamos
              seus Dados Pessoais para gerenciamento e operação de nossas
              comunicações, TI e sistemas de segurança e proteção de dados.
            </Text>

            <Text style={styles.recuo}>
              Motivo para uso dos seus dados pessoais nessa situação:
            </Text>

            <Text style={styles.recuoItens}>
              - Cumprir obrigações contratuais;
            </Text>
            <Text style={styles.recuoItens}>- Obrigações legais;</Text>
            <Text style={styles.recuoItens}>
              - Nossos interesses legítimos.
            </Text>
            <Text style={styles.recuoItens}>
              - Obtivemos o seu consentimento (quando necessário).
            </Text>

            <Text style={styles.recuo}>
              - Nossos interesses legítimos nessa situação:
            </Text>

            <Text style={styles.recuoItens}>
              - Melhorar e desenvolver novos produtos e serviços;
            </Text>
            <Text style={styles.recuoItens}>
              - Ser mais eficientes no atendimento aos seus pedidos;
            </Text>
            <Text style={styles.recuoItens}>
              - Proteger nossos sistemas, redes e colaboradores;
            </Text>
            <Text style={styles.recuoItens}>
              - Cumprir integralmente obrigações legais.
            </Text>
          </>
        </View>

        <View wrap={false} style={styles.section}>
          {(props.dados.PPR30 === 'Sim' ||
            props.dados.PPR31 === 'Sim' ||
            props.dados.PPR32 === 'Sim') && (
            <>
              <Text style={styles.topico}>
                Sobre a divulgação de seus Dados Pessoais
              </Text>
              <Text style={styles.p}>
                Nós podemos compartilhar seus Dados Pessoais com os seguintes
                tipos de organizações de terceiros:
              </Text>

              {props.dados.PPR30 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>Provedores de serviços: </Text>
                  Incluem companhias externas que são utilizadas para auxiliar a
                  operar nosso negócio. Provedores de serviços e seus
                  colaboradores selecionados, só estão autorizados a acessar
                  seus Dados Pessoais em nosso nome para as tarefas específicas,
                  que forem requisitadas a eles com base em nossas instruções
                  diretas. Nossos provedores de serviços são obrigados
                  contratualmente e a manter seus Dados Pessoais confidenciais e
                  seguros, e em casos de violação respondem solidariamente
                  conforme a legislação vigente.
                </Text>
              )}

              {(props.dados.PPR31 === 'Sim' || props.dados.PPR32 === 'Sim') && (
                <Text>
                  <Text style={styles.lista}>
                    Agências de análise de crédito, cobrança de dívidas e
                    serviços financeiros:{` `}
                  </Text>
                  Conforme permitido pela lei aplicável, agências de análise de
                  crédito e/ou de cobrança de dívidas são empresas externas que
                  nos auxiliam a verificar a situação do seu crédito ou para
                  coletar pagamentos vencidos.
                </Text>
              )}

              {props.dados.PPR33 === 'Sim' && (
                <Text>
                  <Text style={styles.lista}>
                    Retenção e término do tratamento de seus Dados Pessoais:
                    {` `}
                  </Text>
                  De acordo com a legislação vigente, utilizamos seus Dados
                  Pessoais por quanto tempo for necessário para satisfazer as
                  finalidades para as quais seus Dados Pessoais foram coletados,
                  conforme descrito nesta política, ou para cumprir com os
                  requerimentos legais aplicáveis. Os Dados usados para fornecer
                  uma experiência personalizada a Você serão mantidos
                  exclusivamente pelo tempo permitido, de acordo com a
                  legislação vigente. Quando no término do tratamento de seus
                  Dados Pessoais, estes serão eliminados no âmbito e nos limites
                  técnicos das atividades, autorizada a conservação nas
                  situações previstas na legislação vigente.
                </Text>
              )}
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          <>
            <Text style={styles.topico}>
              Divulgação, armazenamento ou transferência de seus Dados Pessoais
            </Text>
            <Text style={styles.p}>
              Adotamos medidas adequadas para garantir que seus Dados Pessoais
              sejam mantidos de forma confidencial e segura. Entretanto, que
              estas proteções não se aplicam a informações que Você tenha
              escolhido compartilhar em áreas públicas, como redes sociais de
              terceiros.
            </Text>
            <Text>
              <Text style={styles.lista}>
                Pessoas que podem acessar seus Dados Pessoais:
                {` `}
              </Text>
              Seus Dados Pessoais serão processados por nossos colaboradores ou
              agentes autorizados, desde que estes precisem ter acesso a tais
              informações, dependendo dos propósitos específicos para os quais
              seus Dados Pessoais tenham sido coletados.
            </Text>

            {props.dados.PPR34 === 'Sim' && (
              <Text>
                <Text style={styles.lista}>
                  Medidas tomadas em ambientes operacionais:
                  {` `}
                </Text>
                Armazenamos seus Dados Pessoais em ambientes operacionais que
                usam medidas de segurança razoáveis, tanto técnicas quanto
                administrativas, para prevenir qualquer tipo de acesso não
                autorizado. Seguimos protocolos razoáveis para proteger Dados
                Pessoais.
              </Text>
            )}

            {props.dados.PPR88 === 'Sim' && (
              <Text>
                <Text style={styles.lista}>
                  Medidas de segurança:
                  {` `}
                </Text>
                É importante que Você também tenha um papel em manter seus Dados
                Pessoais seguros. Quando criar uma conta online, por favor,
                assegure-se de escolher uma senha que seja forte para evitar que
                partes não autorizadas a adivinhem. Recomendamos que Você nunca
                revele ou compartilhe sua senha com outras pessoas. Você é o
                único responsável por manter esta senha confidencial e por
                qualquer ação realizada através de sua conta no nosso site.
              </Text>
            )}

            {props.dados.PPR35 === 'Sim' && (
              <Text>
                <Text style={styles.lista}>
                  Transferência de seus Dados Pessoais:
                  {` `}
                </Text>
                Dada a natureza do nosso negócio, é possível que tenhamos que
                transferir seus Dados Pessoais armazenados para terceiros, de
                acordo com as finalidades estabelecidas nesta Política de
                Privacidade. Por este motivo, poderemos transferir seus Dados
                Pessoais para outros países, desde que estes possuam leis e
                regulamentações compatíveis com as vigentes no Brasil.
              </Text>
            )}
          </>
        </View>

        <View wrap={false} style={styles.section}>
          <>
            <Text style={styles.topico}>
              Sobre seus direitos como titular dos Dados Pessoais
            </Text>
            <Text style={styles.p}>
              Você tem direito de confirmar a existência, acessar, revisar,
              modificar e/ou requisitar uma cópia eletrônica da informação dos
              seus Dados Pessoais que são tratados pela nossa empresa.
            </Text>
            <Text style={styles.p}>
              Você também tem direito de requisitar detalhes sobre a origem de
              seus Dados Pessoais ou o compartilhamento destes dados com
              terceiros.
            </Text>
            <Text style={styles.p}>
              A qualquer momento, Você também poderá limitar o uso e divulgação,
              ou revogar o consentimento a qualquer uma de nossas atividades de
              processamento de seus Dados Pessoais, excetuando-se em situações
              previstas na legislação vigente.
            </Text>
            <Text style={styles.p}>
              Estes direitos podem ser exercidos através dos canais de
              comunicação detalhados nesta política, sendo necessário à
              validação da sua identidade através do fornecimento de uma cópia
              de seu RG ou meios equivalentes de identificação, em conformidade
              com a legislação vigente.
            </Text>
            <Text style={styles.p}>
              Sempre que um pedido for submetido sem o fornecimento das provas
              necessárias a comprovação da legitimidade do titular dos dados, o
              pedido será automaticamente rejeitado. Ressaltamos que qualquer
              informação de identificação fornecida somente será processada de
              acordo com, e na medida permitida pelas leis vigentes.
            </Text>
            <Text style={styles.p}>
              Ressaltamos que em determinados casos, não podermos excluir seus
              Dados Pessoais sem também excluir sua conta de usuário.
              Adicionalmente, algumas situações requerem a retenção de seus
              Dados Pessoais depois que Você pedir sua exclusão, para satisfazer
              obrigações legais ou contratuais. Fazemos o máximo possível para
              poder atender todas as questões que Você possa ter sobre a forma a
              qual processamos seus Dados Pessoais.ada de acordo com, e na
              medida permitida pelas leis vigentes.
            </Text>
          </>
        </View>

        <View wrap={false} style={styles.section}>
          <>
            <Text style={styles.topico}>
              Com relação aos seus direitos, você pode:
            </Text>
            <Text style={styles.p}>
              Fazemos o máximo para dar a Você liberdade de escolha sobre os
              Dados Pessoais que Você nos fornece. Os seguintes mecanismos dão a
              Você o controle sobre o tratamento de seus Dados Pessoais:
            </Text>

            <Text>
              <Text style={styles.lista}>
                Cookies/Tecnologias Similares:
                {` `}
              </Text>
              Você pode gerenciar o seu consentimento usando:
            </Text>
            <Text style={styles.recuoItens}>
              - Nossas soluções de gerenciamento de consentimento;
            </Text>
            <Text style={styles.recuoItens}>
              - As configurações do seu navegador para recusar alguns ou todos
              os Cookies e tecnologias similares, ou para alertá-lo quando estão
              sendo usados.
            </Text>

            <Text>
              <Text style={styles.lista}>
                Publicidade, marketing e promoções:
                {` `}
              </Text>
              Você pode consentir para que seus Dados Pessoais sejam usados para
              promover nossos produtos ou serviços por meio de caixas de
              verificação localizadas nos formulários de registro ou respondendo
              questões apresentadas pelos nossos representantes. É importante
              lembrar que, mesmo com o seu cancelamento de subscrição às nossas
              comunicações de marketing, Você continuará a receber comunicações
              administrativos, como pedidos, confirmações de transação,
              notificações sobre suas atividades de conta em nossos sites e
              serviços compatíveis, e outros anúncios importantes não
              relacionados a marketing.
            </Text>

            <Text>
              <Text style={styles.lista}>
                Personalização (offline e online):
                {` `}
              </Text>
              Sempre que permitido por lei, se Você quiser que seus Dados
              Pessoais sejam usados para fornecer-lhe uma experiência
              personalizada ou publicidade e conteúdo dirigidos, Você pode
              indicar isso por meio das caixas de checagem relevantes
              localizadas no formulário de registro ou respondendo a perguntas
              apresentadas pelos nosso representantes. A partir do momento em
              que não quiser mais a personalização, poderá optar por sair a
              qualquer tempo, fazendo login nos nossos sites, aplicativos e
              serviços compatíveis, e selecionando as preferências de usuário no
              perfil da sua conta ou ligando diretamente para nosso serviço de
              atendimento ao consumidor.
            </Text>
          </>
        </View>

        <View wrap={false} style={styles.section}>
          <>
            <Text style={styles.topico}>
              Alterações em nossa Política de Privacidade
            </Text>
            <Text style={styles.p}>
              Sempre que decidirmos mudar a forma que tratamos seus Dados
              Pessoais, esta Política será atualizada. Nos reservamos o direito
              de fazer alterações às nossas práticas e a esta Política a
              qualquer tempo, desde que mantida a conformidade com a legislação
              vigente. Tendo qualquer dúvida pode entrar em contato com a gente.
            </Text>
          </>
        </View>

        <View wrap={false} style={styles.section}>
          <>
            <Text style={styles.topico}>
              Como entrar em contato para falar sobre a nossa Política de
              Privacidade?
            </Text>
            <Text style={styles.p}>Você pode entrar em contato para:</Text>

            <Text style={styles.recuoItens}>
              - Fazer perguntas ou comentários sobre esta Política e nossas
              práticas de privacidade e proteção de Dados Pessoais;
            </Text>
            <Text style={styles.recuoItens}>- Fazer uma reclamação;</Text>
            <Text style={styles.recuoItens}>
              - Confirmação da existência de tratamento de seus Dados Pessoais;
            </Text>
            <Text style={styles.recuoItens}>
              - Obter informações sobre como acessar seus Dados Pessoais;
            </Text>
            <Text style={styles.recuoItens}>
              - Realizar a correção de dados pessoais incompletos, inexatos ou
              desatualizados;
            </Text>
            <Text style={styles.recuoItens}>
              - Obter informações sobre a anonimização, bloqueio ou eliminação
              de dados desnecessários, excessivos ou tratados em desconformidade
              com o disposto na legislação vigente;
            </Text>
            <Text style={styles.recuoItens}>
              - Obter informações sobre a portabilidade dos seus dados pessoais
              a outro fornecedor de serviço ou produto, mediante requisição
              expressa, em conformidade com a legislação vigente;
            </Text>
            <Text style={styles.recuoItens}>
              - Solicitar a eliminação dos dados pessoais tratados com o seu
              consentimento, excetuando-se as hipóteses previstas na legislação
              vigente;
            </Text>
            <Text style={styles.recuoItens}>
              - Solicitar detalhes das entidades públicas e privadas com as
              quais realizamos o compartilhamento de seus Dados Pessoais;
            </Text>
            <Text style={styles.recuoItens}>
              - Obter informações sobre a possibilidade de não fornecer
              consentimento e sobre as consequências dessa negativa;
            </Text>
            <Text style={styles.recuoItens}>
              - Realizar a revogação do consentimento para o tratamento dos seus
              Dados Pessoais, excetuando-se as hipóteses previstas na legislação
              vigente;
            </Text>
            <Text style={styles.recuoItens}>
              - Demais direitos do titular dos dados pessoais, conforme
              legislação vigente.
            </Text>

            <Text style={styles.p}>
              Para isso, solicitamos que Você entre em contato conosco usando os
              seguintes canais:
            </Text>
            <Text style={[styles.lista, { textAlign: 'center' }]}>
              Encarregado pelo Tratamento de Dados Pessoais (DPO):{' '}
              {props.dados.PPR36}
            </Text>

            <Text style={[styles.lista, { textAlign: 'center' }]}>
              E-mail:{props.dados.PPR37}
              {`           `} Telefone: {props.dados.PPR38}
            </Text>

            <Text style={styles.p}>
              Quaisquer dúvidas, reclamações ou apontamentos serão oportunamente
              tratados, investigados e respondidos, levando em consideração as
              disposições legais vigentes.
            </Text>
          </>
        </View>
      </Page>
    </Document>
  );

  return (
    <Container>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <MyDocument />
      </PDFViewer>
    </Container>
  );
};

export default ResultadoPoliticaDePrivacidade;
