export const MenuItems = [
  {
    title: "Mapeamento de dados",
    path: "/mapeamento-de-dados",
    cName: "dropdown-link",
  },
  {
    title: "Matriz de risco",
    path: "/matriz-de-risco",
    cName: "dropdown-link",
  },
  {
    title: "Tabela de descarte",
    path: "/tabela-de-descarte",
    cName: "dropdown-link",
  },
  {
    title: "Controles técnicos",
    path: "/controles-técnicos",
    cName: "dropdown-link",
  },
  {
    title: "Incidente de segurança",
    path: "/incidente-de-segurança",
    cName: "dropdown-link",
  },
];
