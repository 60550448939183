import React, { useState } from 'react';
import Navbar from '../../Componentes/Navbar/Navbar';
import styled from 'styled-components';
import CampoCheckBox from '../../Componentes/CampoCheckBox';
import { PerguntasPoliticaDePrivacidade as perguntas } from '../../Dados/PerguntasPoliticaDePrivacidade';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../Firebase/firebase';
import { useNavigate } from 'react-router-dom';
import CampoTexto from '../../Componentes/CampoTexto';

const Title = styled.h1`
  color: #5b0390;
  text-align: center;
  margin: 2% auto;
  border-bottom: 7px solid #be00ff;
  padding-bottom: 10px;
  width: fit-content;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const ButtonPattern = styled.button`
  width: 200px;
  height: 50px;
  background-color: #ffffff;
  color: #5b0390;
  border-radius: 10px;
  /* border: none; */
`;

const PoliticaDePrivacidade = () => {
  const navigate = useNavigate();
  const docRef = collection(db, 'usuarios');
  const [pergunta39, setPergunta39] = useState();
  const [pergunta37, setPergunta37] = useState();
  const [pergunta38, setPergunta38] = useState();
  const [arrayDeRespostas, setArrayDeRespostas] = useState(
    new Array(perguntas.length).fill(false) //cria um array com o tamanho igual ao número de objetos de perguntas
  );

  const adicionaResposta = (posicao) => {
    console.log(arrayDeRespostas);
    const updatedCheckedState = arrayDeRespostas.map((item, index) =>
      index === posicao ? !item : item
    );

    setArrayDeRespostas(updatedCheckedState);
  };

  const cadastrar = async (e) => {
    e.preventDefault();

    try {
      setDoc(
        doc(docRef, getAuth().currentUser.uid),
        {
          id: getAuth().currentUser.uid,
          email: getAuth().currentUser.email,
          [perguntas[0].codigo]: arrayDeRespostas[0] ? 'Sim' : 'Não',
          [perguntas[1].codigo]: arrayDeRespostas[1] ? 'Sim' : 'Não',
          [perguntas[2].codigo]: arrayDeRespostas[2] ? 'Sim' : 'Não',
          [perguntas[3].codigo]: arrayDeRespostas[3] ? 'Sim' : 'Não',
          [perguntas[4].codigo]: arrayDeRespostas[4] ? 'Sim' : 'Não',
          [perguntas[5].codigo]: arrayDeRespostas[5] ? 'Sim' : 'Não',
          [perguntas[6].codigo]: arrayDeRespostas[6] ? 'Sim' : 'Não',
          [perguntas[7].codigo]: arrayDeRespostas[7] ? 'Sim' : 'Não',
          [perguntas[8].codigo]: arrayDeRespostas[8] ? 'Sim' : 'Não',
          [perguntas[9].codigo]: arrayDeRespostas[9] ? 'Sim' : 'Não',
          [perguntas[10].codigo]: arrayDeRespostas[10] ? 'Sim' : 'Não',
          [perguntas[11].codigo]: arrayDeRespostas[11] ? 'Sim' : 'Não',
          [perguntas[12].codigo]: arrayDeRespostas[12] ? 'Sim' : 'Não',
          [perguntas[13].codigo]: arrayDeRespostas[13] ? 'Sim' : 'Não',
          [perguntas[14].codigo]: arrayDeRespostas[14] ? 'Sim' : 'Não',
          [perguntas[15].codigo]: arrayDeRespostas[15] ? 'Sim' : 'Não',
          [perguntas[16].codigo]: arrayDeRespostas[16] ? 'Sim' : 'Não',
          [perguntas[17].codigo]: arrayDeRespostas[17] ? 'Sim' : 'Não',
          [perguntas[18].codigo]: arrayDeRespostas[18] ? 'Sim' : 'Não',
          [perguntas[19].codigo]: arrayDeRespostas[19] ? 'Sim' : 'Não',
          [perguntas[20].codigo]: arrayDeRespostas[20] ? 'Sim' : 'Não',
          [perguntas[21].codigo]: arrayDeRespostas[21] ? 'Sim' : 'Não',
          [perguntas[22].codigo]: arrayDeRespostas[22] ? 'Sim' : 'Não',
          [perguntas[23].codigo]: arrayDeRespostas[23] ? 'Sim' : 'Não',
          [perguntas[24].codigo]: arrayDeRespostas[24] ? 'Sim' : 'Não',
          [perguntas[25].codigo]: arrayDeRespostas[25] ? 'Sim' : 'Não',
          [perguntas[26].codigo]: arrayDeRespostas[26] ? 'Sim' : 'Não',
          [perguntas[27].codigo]: arrayDeRespostas[27] ? 'Sim' : 'Não',
          [perguntas[28].codigo]: arrayDeRespostas[28] ? 'Sim' : 'Não',
          [perguntas[29].codigo]: arrayDeRespostas[29] ? 'Sim' : 'Não',
          [perguntas[30].codigo]: arrayDeRespostas[30] ? 'Sim' : 'Não',
          [perguntas[31].codigo]: arrayDeRespostas[31] ? 'Sim' : 'Não',
          [perguntas[32].codigo]: arrayDeRespostas[32] ? 'Sim' : 'Não',
          [perguntas[33].codigo]: arrayDeRespostas[33] ? 'Sim' : 'Não',
          [perguntas[34].codigo]: arrayDeRespostas[34] ? 'Sim' : 'Não',
          [perguntas[35].codigo]: arrayDeRespostas[35] ? 'Sim' : 'Não',
          [perguntas[36].codigo]: arrayDeRespostas[36] ? 'Sim' : 'Não',
          [perguntas[37].codigo]: pergunta37,
          [perguntas[38].codigo]: pergunta38,
          [perguntas[39].codigo]: pergunta39,

          criadoEm: serverTimestamp(),
        },
        { merge: true }
      );
      navigate('/documento-politica-privacidade', {
        state: getAuth().currentUser.uid,
      });
      // navigate('/documento');
      console.log('Cadastrar');
    } catch (error) {
      console.log(error);
    }
  };

  const neumorphismContainer = {
    borderRadius: '18px',
    background: '#ffffff',
    margin: '2% 18%',
    padding: '1%',
    boxShadow: '20px 20px 100px #c7c7c7, -20px -20px 100px #ffffff',
  };

  return (
    <div>
      <Navbar />
      <Title>Política de Privacidade</Title>

      <form onSubmit={cadastrar}>
        <div style={neumorphismContainer}>
          {perguntas.map((item, index) => {
            if (index >= 0 && index < 37) {
              return (
                <CampoCheckBox
                  key={index}
                  label={item.pergunta}
                  id={item.id}
                  onChange={(e) => adicionaResposta(index)}
                />
              );
            }
            return null;
          })}
          <CampoTexto
            required={true}
            label={perguntas[37].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta37(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[38].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta38(e.target.value)}
          />
          <CampoTexto
            required={true}
            label={perguntas[39].pergunta}
            // placeholder={   }
            onChange={(e) => setPergunta39(e.target.value)}
          />
        </div>
        <ButtonContainer>
          <ButtonPattern>Enviar</ButtonPattern>
        </ButtonContainer>
      </form>
    </div>
  );
};

export default PoliticaDePrivacidade;
