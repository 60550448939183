export const perguntas = [
  {
    id: 0,
    name: 'opcao1',
    value: 'opcao1',
    pergunta: 'Clique aqui para ativar o Paragrafo 1',
  },
  {
    id: 1,
    name: 'opcao2',
    value: 'opcao2',
    pergunta: 'Clique aqui para ativar o Paragrafo 2',
  },
  {
    id: 2,
    name: 'opcao3',
    value: 'opcao3',
    pergunta: 'Clique aqui para ativar o Paragrafo 3',
  },
  {
    id: 3,
    name: 'opcao4',
    value: 'opcao4',
    pergunta: 'Clique aqui para ativar o Paragrafo 4',
  },
  {
    id: 4,
    name: 'opcao5',
    value: 'opcao5',
    pergunta: 'Clique aqui para ativar o Paragrafo 5',
  },
  {
    id: 5,
    name: 'opcao6',
    value: 'opcao6',
    pergunta: 'Clique aqui para ativar o Paragrafo 6',
  },
];
