export const perguntasMapeamentoDeDados = [
  //Ciclo de dados
  {
    id: 0,
    codigo: 'MPR0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta: 'Nome do responsável:',
  },
  {
    id: 1,
    codigo: 'MPR1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta: 'Data do preenchimento:',
  },
  {
    id: 2,
    codigo: 'MPR2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta:
      'Você coleta dados pessoais como: Nome, CPF, E-mail, Telefone... ?',
  },
  {
    id: 3,
    codigo: 'MPR3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta: 'Os dados ficam armazenados com a sua empresa?',
  },
  {
    id: 4,
    codigo: 'MPR4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta: 'Estes dados são compartilhados com outras empresas?',
  },
  //------------------------------------------------------------------------------------------
  //Agentes de tratamento
  //Classifique se a sua empresa é uma operadora ou controladora de dados

  //Minha empresa com relação ao cliente:
  {
    id: 5,
    codigo: 'MPR5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta:
      'Decidimos coletar ou processar os dados pessoais direto do titular de dados.',
  },
  {
    id: 6,
    codigo: 'MPR6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta: 'Decidimos qual seria o propósito ou resultado do processamento.',
  },
  {
    id: 7,
    codigo: 'MPR7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta: 'Decidimos quais dados pessoais devem ser coletados.',
  },
  {
    id: 8,
    codigo: 'MPR8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta: 'Decidimos sobre quais indivíduos coletar dados pessoais.',
  },
  {
    id: 9,
    codigo: 'MPR9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta:
      'Obtemos um ganho comercial ou outro benefício do processamento, exceto para qualquer pagamento por serviços de outro controlador.',
  },
  {
    id: 10,
    codigo: 'MPR10',
    name: 'pergunta10',
    value: 'pergunta10',
    pergunta:
      'Estamos processando os dados pessoais como resultado de um contrato entre nós e o titular dos dados.',
  },
  {
    id: 11,
    codigo: 'MPR11',
    name: 'pergunta11',
    value: 'pergunta11',
    pergunta:
      'Tomamos decisões sobre os indivíduos em questão como parte ou como resultado do processamento.',
  },

  //--------------------------------------------------------------------------------------------
  //Minha empresa com relação ao prestador de serviço:
  {
    id: 12,
    codigo: 'MPR12',
    name: 'pergunta12',
    value: 'pergunta12',
    pergunta:
      'Decidimos coletar ou processar os dados pessoais direto do titular de dados.',
  },
  {
    id: 13,
    codigo: 'MPR13',
    name: 'pergunta13',
    value: 'pergunta13',
    pergunta: 'Decidimos qual seria o propósito ou resultado do processamento.',
  },
  {
    id: 14,
    codigo: 'MPR14',
    name: 'pergunta14',
    value: 'pergunta14',
    pergunta: 'Decidimos quais dados pessoais devem ser coletados.',
  },
  {
    id: 15,
    codigo: 'MPR15',
    name: 'pergunta15',
    value: 'pergunta15',
    pergunta: 'Decidimos sobre quais indivíduos coletar dados pessoais.',
  },
  {
    id: 16,
    codigo: 'MPR16',
    name: 'pergunta16',
    value: 'pergunta16',
    pergunta:
      'Obtemos um ganho comercial ou outro benefício do processamento, exceto para qualquer pagamento por serviços de outro controlador.',
  },
  {
    id: 17,
    codigo: 'MPR17',
    name: 'pergunta17',
    value: 'pergunta17',
    pergunta:
      'Estamos processando os dados pessoais como resultado de um contrato entre nós e o titular dos dados.',
  },
  {
    id: 18,
    codigo: 'MPR18',
    name: 'pergunta18',
    value: 'pergunta18',
    pergunta:
      'Tomamos decisões sobre os indivíduos em questão como parte ou como resultado do processamento.',
  },

  //-----------------------------------------------------------------------------------------------
  //Minha empresa com relação ao funcionário:
  {
    id: 19,
    codigo: 'MPR19',
    name: 'pergunta19',
    value: 'pergunta19',
    pergunta:
      'Decidimos coletar ou processar os dados pessoais direto do titular de dados.',
  },
  {
    id: 20,
    codigo: 'MPR20',
    name: 'pergunta20',
    value: 'pergunta20',
    pergunta: 'Decidimos qual seria o propósito ou resultado do processamento.',
  },
  {
    id: 21,
    codigo: 'MPR21',
    name: 'pergunta21',
    value: 'pergunta21',
    pergunta: 'Decidimos quais dados pessoais devem ser coletados.',
  },
  {
    id: 22,
    codigo: 'MPR22',
    name: 'pergunta22',
    value: 'pergunta22',
    pergunta: 'Decidimos sobre quais indivíduos coletar dados pessoais.',
  },
  {
    id: 23,
    codigo: 'MPR23',
    name: 'pergunta23',
    value: 'pergunta23',
    pergunta:
      'Obtemos um ganho comercial ou outro benefício do processamento, exceto para qualquer pagamento por serviços de outro controlador.',
  },
  {
    id: 24,
    codigo: 'MPR24',
    name: 'pergunta24',
    value: 'pergunta24',
    pergunta:
      'Estamos processando os dados pessoais como resultado de um contrato entre nós e o titular dos dados.',
  },
  {
    id: 25,
    codigo: 'MPR25',
    name: 'pergunta25',
    value: 'pergunta25',
    pergunta:
      'Tomamos decisões sobre os indivíduos em questão como parte ou como resultado do processamento.',
  },

  //------------------------------------------------------------------------------------------
  //Minha empresa com relação a outra empresa que é minha fornecedora:
  {
    id: 26,
    codigo: 'MPR26',
    name: 'pergunta26',
    value: 'pergunta26',
    pergunta:
      'Decidimos coletar ou processar os dados pessoais direto do titular de dados.',
  },
  {
    id: 27,
    codigo: 'MPR27',
    name: 'pergunta27',
    value: 'pergunta27',
    pergunta: 'Decidimos qual seria o propósito ou resultado do processamento.',
  },
  {
    id: 28,
    codigo: 'MPR28',
    name: 'pergunta28',
    value: 'pergunta28',
    pergunta: 'Decidimos quais dados pessoais devem ser coletados.',
  },
  {
    id: 29,
    codigo: 'MPR29',
    name: 'pergunta29',
    value: 'pergunta29',
    pergunta: 'Decidimos sobre quais indivíduos coletar dados pessoais.',
  },
  {
    id: 30,
    codigo: 'MPR30',
    name: 'pergunta30',
    value: 'pergunta30',
    pergunta:
      'Obtemos um ganho comercial ou outro benefício do processamento, exceto para qualquer pagamento por serviços de outro controlador.',
  },
  {
    id: 31,
    codigo: 'MPR31',
    name: 'pergunta31',
    value: 'pergunta31',
    pergunta:
      'Estamos processando os dados pessoais como resultado de um contrato entre nós e o titular dos dados.',
  },
  {
    id: 32,
    codigo: 'MPR32',
    name: 'pergunta32',
    value: 'pergunta32',
    pergunta:
      'Tomamos decisões sobre os indivíduos em questão como parte ou como resultado do processamento.',
  },
  //-----------------------------------------------------------------------------------------------

  //Definição de dados
  //Selecione os dados que são coletados pela sua empresa:

  // Dados de identificação pessoal:

  {
    id: 33,
    codigo: 'MPR33',
    name: 'pergunta33',
    value: 'pergunta33',
    pergunta: 'Nome',
  },

  {
    id: 34,
    codigo: 'MPR34',
    name: 'pergunta34',
    value: 'pergunta34',
    pergunta: 'Telefone',
  },
  {
    id: 35,
    codigo: 'MPR35',
    name: 'pergunta35',
    value: 'pergunta35',
    pergunta: 'E-mail',
  },
  {
    id: 36,
    codigo: 'MPR36',
    name: 'pergunta36',
    value: 'pergunta36',
    pergunta: 'Endereço',
  },
  {
    id: 37,
    codigo: 'MPR37',
    name: 'pergunta37',
    value: 'pergunta37',
    pergunta: 'RG',
  },
  {
    id: 38,
    codigo: 'MPR38',
    name: 'pergunta38',
    value: 'pergunta38',
    pergunta: 'CPF',
  },
  {
    id: 39,
    codigo: 'MPR39',
    name: 'pergunta39',
    value: 'pergunta39',
    pergunta: 'Estado civil',
  },
  {
    id: 40,
    codigo: 'MPR40',
    name: 'pergunta40',
    value: 'pergunta40',
    pergunta: 'Profissão',
  },

  //Dados Financeiros ------------------------
  {
    id: 41,
    codigo: 'MPR41',
    name: 'pergunta41',
    value: 'pergunta41',
    pergunta: 'Só coletamos os dados necessários para realizar o pagamento',
  },

  {
    id: 42,
    codigo: 'MPR40',
    name: 'pergunta40',
    value: 'pergunta40',
    pergunta: 'Coletamos informações referentes a dívidas e despesas',
  },
  {
    id: 43,
    codigo: 'MPR43',
    name: 'pergunta43',
    value: 'pergunta43',
    pergunta:
      'Coletamos informações sobre hipoteca, empréstimos e rows de crédito',
  },
  {
    id: 44,
    codigo: 'MPR44',
    name: 'pergunta44',
    value: 'pergunta44',
    pergunta: 'Coletamos informações sobre transações financeiras',
  },

  //Habitos Pessoais ---------------------------------------------------------------
  {
    id: 45,
    codigo: 'MPR45',
    name: 'pergunta45',
    value: 'pergunta45',
    pergunta: 'Coletamos informações sobre os hábitos dos titulares dos dados',
  },
  {
    id: 46,
    codigo: 'MPR46',
    name: 'pergunta46',
    value: 'pergunta46',
    pergunta: 'Coletamos informações sobre viagens e deslocamentos',
  },
  {
    id: 47,
    codigo: 'MPR47',
    name: 'pergunta47',
    value: 'pergunta47',
    pergunta: 'Coletamos informações sobre o estilo de vida',
  },

  //------------------------------------------------------------------------------------
  //Dados Sensíveis
  {
    id: 48,
    codigo: 'MPR48',
    name: 'pergunta48',
    value: 'pergunta48',
    pergunta: 'Dados que revelam origem racial ou étnica',
  },
  {
    id: 49,
    codigo: 'MPR49',
    name: 'pergunta49',
    value: 'pergunta49',
    pergunta: 'Dados que revelam convicção religiosa',
  },
  {
    id: 50,
    codigo: 'MPR50',
    name: 'pergunta50',
    value: 'pergunta50',
    pergunta: 'Dados que revelam opinião política',
  },
  {
    id: 51,
    codigo: 'MPR51',
    name: 'pergunta51',
    value: 'pergunta51',
    pergunta: 'Dados que revelam filiação a sindicato',
  },
  {
    id: 52,
    codigo: 'MPR52',
    name: 'pergunta52',
    value: 'pergunta52',
    pergunta: 'Dados que revelam filiação a organização de caráter religioso',
  },
  {
    id: 53,
    codigo: 'MPR53',
    name: 'pergunta53',
    value: 'pergunta53',
    pergunta: 'Dados que revelam filiação ou crença filosófica',
  },
  {
    id: 54,
    codigo: 'MPR54',
    name: 'pergunta54',
    value: 'pergunta54',
    pergunta: 'Dados que revelam filiação ou preferências política',
  },
  {
    id: 55,
    codigo: 'MPR55',
    name: 'pergunta55',
    value: 'pergunta55',
    pergunta: 'Dados referentes à saúde ou à vida sexual',
  },
  {
    id: 56,
    codigo: 'MPR56',
    name: 'pergunta56',
    value: 'pergunta56',
    pergunta: 'Dados genéticos',
  },
  {
    id: 57,
    codigo: 'MPR57',
    name: 'pergunta57',
    value: 'pergunta57',
    pergunta: 'Dados biométricos',
  },
  //--------------------------------------------------------------------------
  //Base Legal
  //Vamos encontrar a base legal da coleta de dados na sua empresa, responda a pergunta abaixo:
  {
    id: 58,
    codigo: 'MPR58',
    name: 'pergunta58',
    value: 'pergunta58',
    pergunta:
      'Porque existe uma lei específica na minha área de atuação que exige que eu faça esta coleta dos dados pessoais dos clientes.',
  },
  {
    id: 59,
    codigo: 'MPR59',
    name: 'pergunta59',
    value: 'pergunta59',
    pergunta:
      'Porque eu vou oferecer produtos depois para ele. Ou seja, vou realizar marketing.',
  },
  {
    id: 60,
    codigo: 'MPR60',
    name: 'pergunta60',
    value: 'pergunta60',
    pergunta: 'Vocês vão celebrar um contrato entre vocês.',
  },
  {
    id: 61,
    codigo: 'MPR61',
    name: 'pergunta61',
    value: 'pergunta61',
    pergunta: 'Sou uma instituição financeira credora.',
  },
];
