import { useEffect, useRef, useState } from "react";
import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import generatePDF from "react-to-pdf";
import { Margin } from "react-to-pdf";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import Navbar from "../../Componentes/Navbar/Navbar";
import ResultadoAditivoControlador from "../../Componentes/ResultadoAditivoControlador";

const Container = styled.div`
  margin: 20px auto;
  /* max-width: 800px; */
  background-color: var(--branco);
  /* height: 100vh; */
`;

const ButtonPattern2 = styled.button`
  width: 200px;
  height: 50px;
  background-color: #5b0390;
  color: #ffffff;
  border-radius: 10px;
  border: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2% auto;
  align-items: center;
  width: 30%;
`;

const DocumentoAditivoControlador = () => {
  const { state } = useLocation();
  const [usuario, setUsuario] = useState(getAuth()?.currentUser?.uid);
  const [dados, setDados] = useState(null);
  const targetRef = useRef();
  // let userId = getAuth()?.currentUser?.uid;

  onAuthStateChanged(getAuth(), (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      setUsuario(user.uid);
    } else {
      // User is signed out
      // ...
    }
  });

  const getDados = async () => {
    try {
      const docRef = doc(db, "usuarios", usuario);
      const docSnap = await getDoc(docRef);
      setDados(docSnap.data());
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data().MPR5);
        setDados(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDados();
  }, [usuario]);

  return (
    <div>
      <Navbar />
      <div ref={targetRef}>
        <Container>
          {dados && <ResultadoAditivoControlador dados={dados} />}
        </Container>
      </div>
    </div>
  );
};

export default DocumentoAditivoControlador;
