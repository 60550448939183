import React from 'react';
import { perguntasMapeamentoDeDados as perguntas } from '../Dados/PerguntasMapeamentoDeDados';
import styled from 'styled-components';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import sourceArial from '../Fonts/arial.ttf';
import sourceArialBold from '../Fonts/arialbd.ttf';

const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

const Title = styled.h1`
  font-size: 50px;
  text-align: center;
`;

const SubTitle = styled.h2`
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-top: 100px;
`;

const Texto = styled.p`
  font-size: 30px;
`;

const Li = styled.li`
  font-size: 30px;
`;
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Arial',
    width: '100%',
  },
  titulo: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: '24px',
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    // fontWeight: '900',
  },
  section: {
    fontFamily: 'Arial',
    fontSize: 12,
    marginBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: 'justify',
  },
  p: {
    marginTop: 5,
    marginBottom: 5,
    textIndent: 30,
  },
  topico: {
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontWeight: '800',
    fontSize: 14,
  },
  lista: {
    fontWeight: '800',
  },
  recuo: {
    marginLeft: 30,
    fontWeight: '800',
  },
  recuoItens: {
    marginLeft: 50,
  },
});
const ResultadoRelatorioDeImpacto = (props) => {
  Font.register({
    family: 'Arial',
    fonts: [
      {
        src: sourceArial,
      },
      {
        src: sourceArialBold,
        fontWeight: 'bold',
      },
    ],
  });

  const MyDocument = () => (
    <Document style={{ width: '100%' }}>
      <Page size='A4' style={styles.page}>
        <View wrap={false}>
          <Text style={styles.titulo}>
            Relatório de Impacto à Proteção de Dados (RIPD)
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          <Text style={styles.topico}>Introdução</Text>
          <Text style={styles.p}>
            Este Relatório de Impacto à Proteção de Dados (RIPD) é elaborado com
            base na Lei Geral de Proteção de Dados (LGPD), com o objetivo de
            identificar e avaliar os riscos para a privacidade dos titulares de
            dados pessoais no contexto das atividades da empresa.
          </Text>
        </View>

        <View wrap={false} style={styles.section}>
          {(props.dados.RI0 === 'Sim' ||
            props.dados.RI1 === 'Sim' ||
            props.dados.RI2 === 'Sim' ||
            props.dados.RI3 === 'Sim') && (
            <>
              <Text style={styles.topico}>Escopo</Text>
              <Text style={styles.p}>
                O escopo deste RIPD abrange todas as atividades da empresa que
                envolvem o tratamento de dados pessoais, incluindo:
              </Text>
              {props.dados.RI0 === 'Sim' && (
                <Text style={styles.recuoItens}> • Coleta</Text>
              )}
              {props.dados.RI1 === 'Sim' && (
                <Text style={styles.recuoItens}> • Armazenamento</Text>
              )}
              {props.dados.RI2 === 'Sim' && (
                <Text style={styles.recuoItens}> • Processamento</Text>
              )}
              {props.dados.RI3 === 'Sim' && (
                <Text style={styles.recuoItens}>
                  • Uso e compartilhamento de dados
                </Text>
              )}
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {(props.dados.RI4 === 'Sim' ||
            props.dados.RI5 === 'Sim' ||
            props.dados.RI6 === 'Sim' ||
            props.dados.RI7 === 'Sim' ||
            props.dados.RI8 === 'Sim') && (
            <>
              <Text style={styles.topico}>Identificação de Dados Pessoais</Text>
              <Text style={styles.p}>
                Foram identificados os seguintes tipos de dados pessoais
                tratados pela empresa:
              </Text>

              {props.dados.RI4 === 'Sim' && (
                <Text style={styles.recuoItens}> • Nome completo</Text>
              )}
              {props.dados.RI5 === 'Sim' && (
                <Text style={styles.recuoItens}> • Endereço</Text>
              )}
              {props.dados.RI6 === 'Sim' && (
                <Text style={styles.recuoItens}> • Número de telefone</Text>
              )}
              {props.dados.RI7 === 'Sim' && (
                <Text style={styles.recuoItens}> • E-mail</Text>
              )}
              {props.dados.RI8 === 'Sim' && (
                <Text style={styles.recuoItens}>• Documento de identidade</Text>
              )}
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          {(props.dados.RI9 === 'Sim' ||
            props.dados.RI10 === 'Sim' ||
            props.dados.RI11 === 'Sim' ||
            props.dados.RI12 === 'Sim') && (
            <>
              <Text style={styles.topico}>Medidas de Mitigação de Riscos</Text>
              <Text style={styles.p}>
                A empresa adotou as seguintes medidas de mitigação de riscos:
              </Text>

              {props.dados.RI9 === 'Sim' && (
                <Text style={styles.recuoItens}>
                  • Implantação de um programa de segurança da informação, com
                  medidas de segurança física e lógica
                </Text>
              )}
              {props.dados.RI10 === 'Sim' && (
                <Text style={styles.recuoItens}>
                  • Treinamento dos colaboradores sobre a LGPD e boas práticas
                  de segurança da informação
                </Text>
              )}
              {props.dados.RI11 === 'Sim' && (
                <Text style={styles.recuoItens}>
                  • Adoção de políticas de retenção de dados para garantir a
                  eliminação de dados pessoais após o fim do período de
                  armazenamento necessário
                </Text>
              )}
              {props.dados.RI12 === 'Sim' && (
                <Text style={styles.recuoItens}>
                  • Adoção de procedimentos de auditoria interna para
                  identificar e corrigir eventuais falhas na segurança da
                  informação
                </Text>
              )}
            </>
          )}
        </View>

        <View wrap={false} style={styles.section}>
          <>
            <Text style={styles.topico}>Conclusão</Text>
            <Text style={styles.p}>
              Este RIPD identificou os riscos para a privacidade dos titulares
              de dados pessoais tratados pela empresa e as medidas de mitigação
              adotadas para minimizar esses riscos. A empresa está comprometida
              em proteger os dados pessoais dos titulares e em cumprir com as
              exigências da LGPD.
            </Text>
          </>
        </View>
      </Page>
    </Document>
  );

  return (
    <Container>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <MyDocument />
      </PDFViewer>
    </Container>
  );
};

export default ResultadoRelatorioDeImpacto;
