export const perguntasTabelaDeDescarte = [
  {
    id: 0,
    codigo: 'TD0',
    name: 'pergunta0',
    value: 'pergunta0',
    pergunta: 'Quanto tempo a empresa precisa ficar com estes dados?',
  },
  {
    id: 1,
    codigo: 'TD1',
    name: 'pergunta1',
    value: 'pergunta1',
    pergunta:
      'Informe o embasamento legal para que a empresa fique este tempo com os dados:',
  },
  {
    id: 2,
    codigo: 'TD2',
    name: 'pergunta2',
    value: 'pergunta2',
    pergunta: 'Dados necessários para pagamento',
  },
  {
    id: 3,
    codigo: 'TD3',
    name: 'pergunta3',
    value: 'pergunta3',
    pergunta: 'Informações referentes a dívidas e despesas',
  },

  {
    id: 4,
    codigo: 'TD4',
    name: 'pergunta4',
    value: 'pergunta4',
    pergunta: 'Informações sobre hipoteca',
  },
  {
    id: 5,
    codigo: 'TD5',
    name: 'pergunta5',
    value: 'pergunta5',
    pergunta: 'Empréstimos e linha de crédito',
  },
  {
    id: 6,
    codigo: 'TD6',
    name: 'pergunta6',
    value: 'pergunta6',
    pergunta: 'Informações sobre transações financeiras',
  },
  {
    id: 7,
    codigo: 'TD7',
    name: 'pergunta7',
    value: 'pergunta7',
    pergunta: 'Quanto tempo a empresa precisa ficar com estes dados?',
  },
  {
    id: 8,
    codigo: 'TD8',
    name: 'pergunta8',
    value: 'pergunta8',
    pergunta:
      'Informe o embasamento legal para que a empresa fique este tempo com os dados:',
  },
  {
    id: 9,
    codigo: 'TD9',
    name: 'pergunta9',
    value: 'pergunta9',
    pergunta: 'Informações sobre os hábitos dos titulares dos dados',
  },
  {
    id: 10,
    codigo: 'TD10',
    name: 'pergunta10',
    value: 'pergunta10',
    pergunta: 'Informações sobre viagens e deslocamentos',
  },
  {
    id: 11,
    codigo: 'TD11',
    name: 'pergunta11',
    value: 'pergunta11',
    pergunta: 'Informações sobre o estilo de vida',
  },
  {
    id: 12,
    codigo: 'TD12',
    name: 'pergunta12',
    value: 'pergunta12',
    pergunta: 'Quanto tempo a empresa precisa ficar com estes dados?',
  },
  {
    id: 13,
    codigo: 'TD13',
    name: 'pergunta13',
    value: 'pergunta13',
    pergunta:
      'Informe o embasamento legal para que a empresa fique este tempo com os dados:',
  },
  {
    id: 14,
    codigo: 'TD14',
    name: 'pergunta14',
    value: 'pergunta14',
    pergunta: 'Dados que revelam origem racial ou étnica',
  },
  {
    id: 15,
    codigo: 'TD15',
    name: 'pergunta15',
    value: 'pergunta15',
    pergunta: ' Dados que revelam convicção religiosa',
  },
  {
    id: 16,
    codigo: 'TD16',
    name: 'pergunta16',
    value: 'pergunta16',
    pergunta: 'Dados que revelam opinião política',
  },
  {
    id: 17,
    codigo: 'TD17',
    name: 'pergunta17',
    value: 'pergunta17',
    pergunta: ' Dados que revelam filiação a sindicato',
  },
  {
    id: 18,
    codigo: 'TD18',
    name: 'pergunta18',
    value: 'pergunta18',
    pergunta: 'Dados que revelam filiação a organização de caráter religioso',
  },
  {
    id: 19,
    codigo: 'TD19',
    name: 'pergunta19',
    value: 'pergunta19',
    pergunta: '  Dados que revelam filiação ou crença filosófica',
  },
  {
    id: 20,
    codigo: 'TD20',
    name: 'pergunta20',
    value: 'pergunta20',
    pergunta: 'Dados que revelam filiação ou preferências política',
  },
  {
    id: 21,
    codigo: 'TD21',
    name: 'pergunta21',
    value: 'pergunta21',
    pergunta: ' Dados referentes à saúde ou à vida sexual',
  },
  {
    id: 22,
    codigo: 'TD22',
    name: 'pergunta22',
    value: 'pergunta22',
    pergunta: 'Dados genéticos',
  },
  {
    id: 23,
    codigo: 'TD23',
    name: 'pergunta23',
    value: 'pergunta23',
    pergunta: ' Dados biométricos',
  },
  {
    id: 24,
    codigo: 'TD24',
    name: 'pergunta24',
    value: 'pergunta24',
    pergunta: 'Quanto tempo a empresa precisa ficar com estes dados?',
  },
  {
    id: 25,
    codigo: 'TD25',
    name: 'pergunta25',
    value: 'pergunta25',
    pergunta:
      ' Informe o embasamento legal para que a empresa fique este tempo com os dados:',
  },

  {
    id: 26,
    codigo: 'TD26',
    name: 'pergunta26',
    value: 'pergunta26',
    pergunta: 'Nome',
  },
  {
    id: 27,
    codigo: 'TD27',
    name: 'pergunta27',
    value: 'pergunta27',
    pergunta: 'Telefone',
  },
  {
    id: 28,
    codigo: 'TD28',
    name: 'pergunta28',
    value: 'pergunta28',
    pergunta: 'E-mail',
  },
  {
    id: 29,
    codigo: 'TD29',
    name: 'pergunta29',
    value: 'pergunta29',
    pergunta: 'Endereço',
  },
  {
    id: 30,
    codigo: 'TD30',
    name: 'pergunta30',
    value: 'pergunta30',
    pergunta: 'RG',
  },
  {
    id: 31,
    codigo: 'TD31',
    name: 'pergunta31',
    value: 'pergunta31',
    pergunta: 'CPF',
  },
  {
    id: 32,
    codigo: 'TD32',
    name: 'pergunta32',
    value: 'pergunta32',
    pergunta: 'Estado Civil',
  },
  {
    id: 33,
    codigo: 'TD33',
    name: 'pergunta33',
    value: 'pergunta33',
    pergunta: 'Profissão',
  },
];
